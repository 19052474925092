import { getIn } from 'formik'
import { mapValues } from 'lodash'
import keyBy from 'lodash/keyBy'
import { useEffect, useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { deps } from 'react-rocketjump'
import { useOrganization, useOrganizations } from '../hooks/useOrganizations'

export function OrganizationTypeahead({ value, name = '', id = 'org-typeahead', className, onChange, ...props }) {
  const [{ orgs, loading }, { run }] = useOrganizations.controlled()
  const [{ org }] = useOrganization(deps.maybe(value))
  const captionsRef = useRef({})

  let options = orgs?.map((org) => org.id) ?? []
  captionsRef.current = { ...captionsRef.current, ...mapValues(keyBy(orgs, 'id'), 'businessName') }

  if (org && !options.includes(org.id)) {
    options = [org.id].concat(options)
    captionsRef.current[org.id] = org.businessName
  }

  const selected = value && org ? [value] : []

  useEffect(() => run(), [run])

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run.withMeta({ debounced: true }).run({ search })
      }}
      isLoading={loading}
      options={options ?? []}
      labelKey={(opt) => {
        return captionsRef.current[opt]
      }}
      minLength={0}
      onSearch={(search) => run({ search })}
      id={id}
      {...props}
    />
  )
}

export function OrganizationTypeaheadField({ field, label, form, required = false, ...props }) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <OrganizationTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => form.setFieldValue(field.name, value ?? '')}
        value={field.value}
        id={`option-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  )
}
