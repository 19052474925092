import { Modal } from 'react-bootstrap'
import { ArrowLeft, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

export default function ModalConfirmDelete({
  isOpen,
  title,
  text,
  toggle,
  onExited,
  onDelete,
}) {
  const { t } = useTranslation()
  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          icon={<ArrowLeft />}
          onClick={toggle}
          variant="secondary"
          type="button"
        >
          {t('undo')}
        </Button>
        <Button
          onClick={onDelete}
          icon={<Trash size={18} />}
          variant="danger"
          type="submit"
        >
          {t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
