import { Field, Formik } from 'formik'
import { sortBy } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Alert, Form, Modal, Table } from 'react-bootstrap'
import { ArrowLeft, Check, Pencil, Sliders, X } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { InputField, YesNoField } from '../../components/Form'
import { useProductLevelAttributes } from '../../hooks/useProductLevelAttributes'

export function Attributes({ license, updateAttribute }) {
  const { t } = useTranslation()

  const productLevelFilter = useMemo(
    () => ({
      product_level: license.service_level,
    }),
    [license.service_level]
  )

  const [{ attributes, loading: attributesLoading, error }] =
    useProductLevelAttributes(productLevelFilter)

  const [editModalState, editModalActions] = useModalTrigger()

  if (error) {
    return <Alert color="danger">Impossibile mostrare gli attributi</Alert>
  }

  return (
    <>
      <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
        <h3 className="m-0 d-flex align-items-center">
          <Sliders className="mr-2" /> {t('dlic_license_attributes')}
        </h3>
      </div>
      <div className="mt-4 px-3">
        <Table
          loading={attributesLoading}
          responsive
          hover
          className="table-layout-fixed"
        >
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('attribute')}</th>
              <th style={{ width: '70%' }}>{t('value')}</th>
              <th style={{ width: '10%' }}></th>
            </tr>
          </thead>
          <tbody>
            {attributes &&
              sortBy(attributes, 'name').map((attr) => {
                let value = license.attributes[attr.code]
                if (value === true) {
                  value = (
                    <span>
                      <Check className="mr-1" />
                      {t('yes')}
                    </span>
                  )
                }
                if (value === false) {
                  value = (
                    <span>
                      <X className="mr-1" />
                      {t('no')}
                    </span>
                  )
                }
                return (
                  <tr key={attr.id}>
                    <td>{attr.name}</td>
                    <td>{value ?? <i>{t('dlic_license_attr_no_value')}</i>}</td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        icon={<Pencil />}
                        onClick={() => {
                          editModalActions.open({
                            attrName: attr.name,
                            code: attr.code,
                            value: license.attributes[attr.code],
                            valueType: attr.data_type,
                          })
                        }}
                      >
                        {t('edit_value')}
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {editModalState.value && (
          <EditValueModal
            isOpen={editModalState.isOpen}
            onCancel={editModalActions.close}
            onSubmit={(values) => {
              return updateAttribute(
                editModalState.value.code,
                values.value
              ).then((result) => {
                editModalActions.close()
                return result
              })
            }}
            onClosed={editModalActions.onClosed}
            attrName={editModalState.value.attrName}
            value={editModalState.value.value}
            valueType={editModalState.value.valueType}
          />
        )}
      </div>
    </>
  )
}

function EditValueModal({
  isOpen,
  onCancel,
  onSubmit,
  onClosed,
  valueType,
  value,
  attrName,
}) {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{ name: attrName, value: value }}
      onSubmit={(values, actions) => onSubmit(values)}
    >
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('manage_attribute')}</Modal.Header>
          <Modal.Body>
            <Form>
              <Field
                name="name"
                disabled
                component={InputField}
                label={t('attribute')}
              />
              {valueType === 'bool' && (
                <Field name="value" component={YesNoField} label={t('value')} />
              )}
              {valueType === 'number' && (
                <Field
                  name="value"
                  component={InputField}
                  type="number"
                  label={t('value')}
                  hint={t('numeric_value_field_hint')}
                />
              )}
              {valueType === 'string' && (
                <Field
                  name="value"
                  component={InputField}
                  type="text"
                  label={t('value')}
                  hint={t('string_value_field_hint')}
                />
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
