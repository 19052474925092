import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { Bag, Cpu, PlusCircle, Toggles, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import { useConfigurationAvailableOptions } from '../../hooks/useConfigurationAvailableOptions'
import { ManageOptionModal } from './ManageOptionModal'
import { PickConfigurationModal } from './PickConfigurationModal'

function Configuration({
  configurationLicense: confLicense,
  onManage,
  onToggle,
  onRemove,
}) {
  const { t } = useTranslation()

  const optionsFilter = useMemo(
    () => ({
      configuration: confLicense.configuration,
    }),
    [confLicense.configuration]
  )

  const [{ options }] = useConfigurationAvailableOptions(optionsFilter)

  return (
    <>
      <tr>
        <th>{confLicense.configuration_name}</th>
        <td></td>
        <td></td>
        <td className="text-right">
          <Button
            size="sm"
            variant="outline-danger"
            icon={<Trash />}
            onClick={() => {
              onRemove()
            }}
          >
            {t('delete')}
          </Button>
        </td>
      </tr>
      {options &&
        sortBy(options, 'option_name').map((opt) => {
          console.log(confLicense, opt)
          const activation = confLicense.options.find(
            (licenseOption) => licenseOption.option === opt.option
          )
          let license_type = ''
          if (activation) {
            if (activation.license_type === 'perpetual') {
              license_type = t('dlic_license_perpetual')
            }
            if (activation.license_type === 'expire') {
              license_type = dayjs(activation.expire_date).format('DD/MM/YYYY')
            }
          }
          return (
            <tr key={opt.id}>
              <td className="pl-4 font-weight-light">{opt.option_name}</td>
              <td>{!!activation ? t('active') : t('not_active')}</td>
              <td>{license_type}</td>
              <td className="text-right">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="mr-2"
                  icon={<Toggles />}
                  onClick={() => {
                    onToggle(opt, activation)
                  }}
                />
                <Button
                  size="sm"
                  variant="outline-primary"
                  icon={<Bag />}
                  onClick={() => onManage(opt, activation)}
                >
                  {t('manage')}
                </Button>
              </td>
            </tr>
          )
        })}
    </>
  )
}

export function Configurations({
  license,
  grantOption,
  revokeOption,
  editOption,
  grant,
  revoke,
}) {
  const { t } = useTranslation()

  const [manageModalState, manageModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()
  const [addModalState, addModalActions] = useModalTrigger()

  return (
    <>
      <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
        <h3 className="m-0 d-flex align-items-center">
          <Cpu className="mr-2" /> {t('dlic_license_configurations')}
        </h3>

        <Button
          size="sm"
          variant="outline-primary"
          icon={<PlusCircle />}
          style={{ marginRight: '1.75rem' }}
          onClick={() => addModalActions.open('dummy')}
        >
          {t('add')}
        </Button>
      </div>
      <div className="mt-4 px-3">
        <Table responsive hover className="table-layout-fixed">
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('name')}</th>
              <th style={{ width: '20%' }}>{t('status')}</th>
              <th style={{ width: '45%' }}>{t('dlic_license_expire_date')}</th>
              <th style={{ width: '15%' }}></th>
            </tr>
          </thead>
          <tbody>
            {license.configurations &&
              sortBy(license.configurations, 'configuration_name').map(
                (confLicense) => {
                  return (
                    <Configuration
                      key={confLicense.id}
                      configurationLicense={confLicense}
                      onToggle={(opt, activation) => {
                        if (activation) {
                          revokeOption(activation.id)
                        } else {
                          grantOption(confLicense.id, {
                            option: opt.option,
                            attributes: {},
                            license_type: 'perpetual',
                            expire_date: null,
                          })
                        }
                      }}
                      onManage={(opt, activation) => {
                        manageModalActions.open({
                          configurationLicense: confLicense.id,
                          configurationName: confLicense.configuration_name,
                          optionName: opt.option_name,
                          isActive: !!activation,
                          licenseType: activation?.license_type ?? 'perpetual',
                          expireDate: activation?.expire_date ?? '',
                          activationId: activation?.id,
                          optionId: opt.option,
                        })
                      }}
                      onRemove={() => {
                        deleteModalActions.open(confLicense)
                      }}
                    />
                  )
                }
              )}
          </tbody>
        </Table>
        {manageModalState.value && (
          <ManageOptionModal
            isOpen={manageModalState.isOpen}
            onCancel={manageModalActions.close}
            onClosed={manageModalActions.onClosed}
            data={manageModalState.value}
            onSubmit={(values) => {
              const prevValues = manageModalState.value
              const currValues = values
              let result = Promise.resolve()
              if (prevValues.isActive && !currValues.isActive) {
                result = revokeOption.asPromise(prevValues.activationId)
              }
              if (!prevValues.isActive && currValues.isActive) {
                result = grantOption.asPromise(license.id, {
                  configuration_license: prevValues.configurationLicense,
                  option: prevValues.optionId,
                  attributes: {},
                  license_type: currValues.licenseType,
                  expire_date: currValues.expireDate || null,
                })
              }
              if (prevValues.isActive && currValues.isActive) {
                result = editOption.asPromise(prevValues.activationId, {
                  license_type: currValues.licenseType,
                  expire_date: currValues.expireDate || null,
                })
              }
              return result.then((arg) => {
                manageModalActions.close()
                return arg
              })
            }}
          />
        )}
        {deleteModalState.value && (
          <ModalConfirmDelete
            isOpen={deleteModalState.isOpen}
            toggle={deleteModalActions.toggle}
            title={t('revoke_license_conf_title', {
              name: deleteModalState.value.configuration_name,
            })}
            text={t('revoke_license_conf_message', {
              name: deleteModalState.value.configuration_name,
            })}
            onExited={deleteModalActions.onClosed}
            onDelete={() => {
              revoke(deleteModalState.value.id)
              deleteModalActions.close()
            }}
          />
        )}
        {addModalState.value && (
          <PickConfigurationModal
            isOpen={addModalState.isOpen}
            onCancel={addModalActions.toggle}
            onClosed={addModalActions.onClosed}
            onSubmit={(configuration) => {
              return grant
                .onSuccess(() => {
                  addModalActions.close()
                })
                .asPromise(license.id, { configuration })
            }}
          />
        )}
      </div>
    </>
  )
}
