import { rj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const HwKeyExtraRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/license/hw-key-extra/${id}`)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => {
        return api2.auth(token).post(`/license/hw-key-extra/`, data)
      },
      updater: 'updateData',
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api2.auth(token).patch(`/license/hw-key-extra/${id}`, data)
      },
      updater: 'updateData',
    }),
    createC2V: rj.mutation.single({
      effect: (token) => (hardwareKey, file) => {
        const fd = new FormData()
        fd.append('hardware_key', hardwareKey)
        fd.append('last_c2v', file)
        return api2.auth(token).post(`/license/hw-key-extra/`, fd)
      },
      updater: 'updateData',
    }),
    updateC2V: rj.mutation.single({
      effect: (token) => (id, file) => {
        const fd = new FormData()
        fd.append('last_c2v', file)
        return api2.auth(token).patch(`/license/hw-key-extra/${id}`, fd)
      },
      updater: 'updateData',
    }),
  },
})

export function useHwKeyExtra(keyId) {
  return useRunRj(HwKeyExtraRj, [keyId])
}

const HwKeyDLICRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/license/dlic/`, { key: id })
  },
  mutations: {
    getContent: rj.mutation.single({
      effect: (token) => (id) => {
        return api2.auth(token).get(`/license/dlic/${id}/content`)
      },
      updater: (state, result) => state,
    }),
    getV2C: rj.mutation.single({
      effect: (token) => (id) => {
        return api2
          .auth(token)
          .request({ responseType: 'blob' })
          .get(`/license/dlic/${id}/v2c`)
      },
      updater: (state, result) => state,
    }),
    getErrorLog: rj.mutation.single({
      effect: (token) => (id) => {
        return api2
          .auth(token)
          .request({ responseType: 'blob' })
          .get(`/license/dlic/${id}/error_log`)
      },
      updater: (state, result) => state,
    }),
  },
})

export function useHwKeyDlics(keyId) {
  return useRunRj(HwKeyDLICRj, [keyId])
}
