import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { BoxSeam, Cart, ExclamationTriangleFill, Git, Pencil, PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { ProductLevelModal } from '../../components/ProductLevelModal'
import { ProductModal } from '../../components/ProductModal'
import { useProductLevels } from '../../hooks/useProductLevels'
import { useProduct } from '../../hooks/useProducts'
import { useProductVersions } from '../../hooks/useProductVersion'
import { ProductVersionModal } from '../../components/ProductVersionModal'

export function ProductDetail() {
  const { id } = useParams()
  const { t } = useTranslation()
  const history = useHistory()

  const levelsFilter = useMemo(
    () => ({
      product: id,
    }),
    [id]
  )
  const versionFilter = useMemo(
    () => ({
      product: id,
    }),
    [id]
  )

  const [{ product, error }, { update, destroy }] = useProduct(id)
  const [{ levels, loading: loadingLevels, error: levelsError }, { create: createLevel, duplicate: duplicateLevel }] =
    useProductLevels(levelsFilter)
  const [{ versions, error: versionsError }, { create: createVersion, run: reloadVersions }] =
    useProductVersions(versionFilter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [createLevelModalState, createLevelModalActions] = useModalTrigger()
  const [createVersionModalState, createVersionModalActions] = useModalTrigger()

  if (error || levelsError || versionsError) {
    return <PageError error={error || levelsError} />
  }

  if (product === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <Cart className="mr-2" size={31} /> {product.name}
          </h2>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(product)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_product')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => delModalActions.open(product)}
              icon={<Trash size={15} />}
            >
              {t('delete_product')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={product.id} />
            <Detail.Row label={t('code')} value={product.code} />
            <Detail.Row label={t('description')} value={product.description} />
          </Detail>
        </div>
        {/* PRODUCT LEVELS */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <BoxSeam className="mr-2" /> {t('product_levels')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createLevelModalActions.open(product)}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_product_level')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table loading={loadingLevels} responsive hover style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>{t('name')}</th>
                <th style={{ width: '20%' }}>{t('code')}</th>
                <th style={{ width: '5%' }}>{t('deprecated')}</th>
                <th style={{ width: '55%' }} />
              </tr>
            </thead>
            <tbody>
              {levels &&
                levels.map((level) => (
                  <tr key={level.id}>
                    <td>
                      <Link className="font-weight-bold" to={`/catalog/${product.id}/level/${level.id}`}>
                        {level.name}
                      </Link>
                    </td>
                    <td>{level.code}</td>
                    <td className="text-center">{level.deprecated && <ExclamationTriangleFill />}</td>
                    <td class="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => duplicateLevel(level.id)}
                        icon={<PlusCircleDotted size={15} />}
                        className="mr-2"
                      >
                        {t('duplicate_product_level')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {/* END PRODUCT LEVELS */}
        {/* PRODUCT VERSIONS */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Git className="mr-2" /> {t('product_versions')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createVersionModalActions.open(product)}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_product_version')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table loading={loadingLevels} responsive hover style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>{t('product_version_label')}</th>
                <th style={{ width: '20%' }}>{t('product_version_nr')}</th>
                <th style={{ width: '60%' }} />
              </tr>
            </thead>
            <tbody>
              {versions?.map((version) => (
                <tr key={version.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/catalog/${product.id}/versions/${version.id}`}>
                      {version.label}
                    </Link>
                  </td>
                  <td>{version.version_nr}</td>
                  <td class="text-right"></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* END PRODUCT VERSIONS */}
      </div>
      {editModalState.value && (
        <ProductModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          product={product}
          onClosed={editModalActions.onClosed}
          onSave={(result) => {
            return update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(product.id, result)
          }}
        />
      )}
      {createLevelModalState.value && (
        <ProductLevelModal
          isOpen={createLevelModalState.isOpen}
          toggle={createLevelModalActions.toggle}
          level={{ product: product.id }}
          onClosed={createLevelModalActions.onClosed}
          onSave={(result) => {
            return createLevel
              .onSuccess(() => {
                createLevelModalActions.close()
              })
              .asPromise({ ...result, product: product.id })
          }}
        />
      )}
      {createVersionModalState.value && (
        <ProductVersionModal
          isOpen={createVersionModalState.isOpen}
          toggle={createVersionModalActions.toggle}
          onClosed={createVersionModalActions.onClosed}
          onSave={(result) => {
            return createVersion
              .onSuccess(() => {
                createVersionModalActions.close()
                reloadVersions(versionFilter)
              })
              .asPromise({ ...result, product: product.id })
          }}
        />
      )}
      {delModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            destroy(product.id)
            delModalActions.close()
            history.replace('/catalog')
          }}
          isOpen={delModalState.isOpen}
          toggle={delModalActions.toggle}
          onExited={delModalActions.onClosed}
          title={t('delete_product_title', {
            name: product.name,
          })}
          text={t('delete_product_text', {
            name: product.name,
          })}
        />
      )}
    </Layout>
  )
}
