import { useCallback, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, FiletypeExe, PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

export function ReleaseFileUploadModal({ onSave, isOpen, toggle, onClosed }) {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const inputRef = useRef()

  const handleFilesSelected = useCallback((e) => {
    const selectedFiles = e.target.files
    const addendumFiles = []
    for (const file of selectedFiles) {
      addendumFiles.push({ name: file.name, file: file, url: URL.createObjectURL(file) })
    }
    setFiles((fx) => [...fx, ...addendumFiles])
    e.target.value = ''
  }, [])

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FiletypeExe className="mr-2" />
          {t(`create_release_file`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {files.length > 0 && (
          <div className="mb-3">
            {files.map((file, i) => (
              <div
                key={i}
                className="d-flex flex-row justify-content-between align-items-center mb-1 p-1 border-bottom"
              >
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
                <span
                  className="pointer"
                  onClick={() => {
                    setFiles((fx) => fx.filter((_, j) => j !== i))
                  }}
                >
                  <Trash />
                </span>
              </div>
            ))}
          </div>
        )}
        <div className="text-center">
          <Button
            icon={<PlusCircleDotted size={18} />}
            variant="primary"
            type="button"
            onClick={() => {
              inputRef.current.click()
            }}
          >
            {t('add_file')}
          </Button>
        </div>
        <input type="file" ref={inputRef} onChange={handleFilesSelected} style={{ display: 'none' }} multiple />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" type="button">
          {t('undo')}
        </Button>
        <Button
          icon={<PlusCircleDotted size={18} />}
          variant="success"
          disabled={files.length === 0}
          type="button"
          onClick={() => {
            onSave(files)
          }}
        >
          {t('create')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
