import { omit } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Check, Envelope, Gear, Pencil, PlusCircleDotted, Trash, X } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useRunRj } from 'react-rocketjump'
import { Link, useHistory } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { UsersListState } from '../../state/users'
import UserModal from './UserModal'

export default function AppsList() {
  const { t } = useTranslation()
  const { user: loggedUser } = useAuthUser()
  const history = useHistory()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const [{ users, loading, pagination, error }, { deleteUser, createUser, updateUser, resendInvitation }] = useRunRj(
    UsersListState,
    [debQueryParams],
    false
  )

  const [resendingEmails, setResendingEmails] = useState({})

  const [rmModal, rmModalActions] = useModalTrigger()
  const [addModal, addModalActions] = useModalTrigger()
  const [editModal, editModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <div class="d-flex flex-row white-space-nowrap align-items-center">
            <Search
              value={queryParams.search ?? ''}
              onChangeValue={(search) =>
                setDebQueryParams({
                  search,
                  page: 1,
                })
              }
              onClear={() =>
                setQueryParams({
                  search: '',
                  page: 1,
                })
              }
            />
            <div class="px-3"></div>
            <span class="mr-2">Primo accesso:</span>
            <Button
              variant={!queryParams.did_sign_up ? 'primary' : 'outline-primary'}
              size="sm"
              onClick={() => setQueryParams({ did_sign_up: undefined })}
            >
              Tutti
            </Button>
            <Button
              variant={queryParams.did_sign_up === 'true' ? 'primary' : 'outline-primary'}
              size="sm"
              onClick={() => setQueryParams({ did_sign_up: 'true' })}
            >
              Completato
            </Button>
            <Button
              variant={queryParams.did_sign_up === 'false' ? 'primary' : 'outline-primary'}
              size="sm"
              onClick={() => setQueryParams({ did_sign_up: 'false' })}
            >
              Non completato
            </Button>
          </div>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => addModalActions.open()}
            icon={<PlusCircleDotted size={15} />}
          >
            {t('create_user')}
          </Button>
        </div>
        <Table loading={loading} responsive hover>
          <thead>
            <tr>
              <th>{t('user_email')}</th>
              <th>{t('user_name')}</th>
              <th className="text-center">{t('user_is_admin')}</th>
              <th className="text-center">{t('user_did_sign_up')}</th>
              <th className="text-right pr-5">
                <Gear />
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => {
                const resendingEmail = resendingEmails[user.id]
                let resendEmailIcon = <Envelope size={15} />,
                  resendEmailText = t('resend_email')
                if (resendingEmail === 'pending') {
                  resendEmailIcon = <Spinner size="sm" animation="border" />
                  resendEmailText = t('resend_email_pending')
                }
                if (resendingEmail === 'success') {
                  resendEmailIcon = <Check size={20} />
                  resendEmailText = t('resend_email_success')
                }
                return (
                  <tr key={user.id}>
                    <td>
                      <Link className="font-weight-bold" to={`/users/${user.id}`}>
                        {user.email}
                      </Link>
                    </td>
                    <td>{user.full_name}</td>
                    <td className="text-center">
                      {user.is_admin ? (
                        <Check size={25} className="text-success" />
                      ) : (
                        <X size={25} className="text-danger" />
                      )}
                    </td>
                    <td className="text-center">
                      {user.did_sign_up ? (
                        <Check size={25} className="text-success" />
                      ) : (
                        <X size={25} className="text-danger" />
                      )}
                    </td>
                    <td className="text-right">
                      <Button
                        onClick={() => {
                          setResendingEmails((o) => ({ ...o, [user.id]: 'pending' }))
                          resendInvitation
                            .onSuccess(() => {
                              setResendingEmails((o) => ({ ...o, [user.id]: 'success' }))
                              setTimeout(() => {
                                setResendingEmails((o) => omit(o, user.id))
                              }, 5000)
                            })
                            .run(user)
                        }}
                        icon={resendEmailIcon}
                        variant={'outline-primary'}
                        className="mr-2"
                        size="sm"
                        disabled={user.did_sign_up || resendingEmail}
                        style={{ opacity: user.did_sign_up ? 0 : 1 }}
                      >
                        {resendEmailText}
                      </Button>
                      <Button
                        onClick={() => editModalActions.open(user)}
                        icon={<Pencil />}
                        variant={'outline-primary'}
                        className="mr-2"
                        size="sm"
                      >
                        {t('edit_user')}
                      </Button>
                      <Button
                        disabled={user.id === loggedUser.id}
                        onClick={() => rmModalActions.open(user)}
                        icon={<Trash />}
                        variant={'outline-danger'}
                        size="sm"
                      >
                        {t('delete_user')}
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        <Paginator
          numPages={pagination.numPages}
          currentPage={+debQueryParams.page || 1}
          goToPage={(page) => setQueryParams({ page })}
        />
      </div>
      <ModalConfirmDelete
        onDelete={(app) => {
          deleteUser(rmModal.value)
          rmModalActions.close()
        }}
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        onExited={rmModalActions.onClosed}
        title={rmModal.value ? t('delete_user_title', { email: rmModal.value.email }) : null}
        text={rmModal.value ? t('delete_user_text', { email: rmModal.value.email }) : null}
      />
      <UserModal
        onSave={(app) =>
          createUser
            .onSuccess((newApp) => {
              history.push(`/users/${newApp.id}`)
            })
            .asPromise(app)
        }
        isOpen={addModal.isOpen}
        toggle={addModalActions.toggle}
        onExited={addModalActions.onClosed}
      />
      <UserModal
        onSave={(user) => updateUser.onSuccess(editModalActions.close).asPromise(user)}
        user={editModal.value}
        isOpen={editModal.isOpen}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
      />
    </Layout>
  )
}
