import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { Pencil, PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import { OptionModal } from '../../components/OptionModal'
import PageError from '../../components/PageError'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { useConfigurationsOptions } from '../../hooks/useConfigurationsOptions'

export function ConfigurationOptionList() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [
    { options, loading, error },
    { run: loadContent, create, update, destroy },
  ] = useConfigurationsOptions(debQueryParams)

  const [createModalState, createModalActions] = useModalTrigger()
  const [editModalState, editModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
              })
            }
          />
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createModalActions.open('dummy')}
            icon={<PlusCircleDotted size={15} />}
          >
            {t('create_option')}
          </Button>
        </div>
        <Table
          loading={loading}
          responsive
          hover
          className="table-layout-fixed"
        >
          <thead>
            <tr>
              <th style={{ width: '25%' }}>{t('option')}</th>
              <th style={{ width: '25%' }}>{t('code')}</th>
              <th style={{ width: '35%' }}>{t('description')}</th>
              <th style={{ width: '15%' }} />
            </tr>
          </thead>
          <tbody>
            {options &&
              options.map((opt) => (
                <tr key={opt.id}>
                  <td>{opt.name}</td>
                  <td>{opt.code}</td>
                  <td>{opt.description}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => editModalActions.open(opt)}
                      icon={<Pencil size={15} />}
                      className="mr-2"
                    >
                      {t('edit')}
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => deleteModalActions.open(opt)}
                      icon={<Trash size={15} />}
                    >
                      {t('delete')}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {createModalState.value && (
        <OptionModal
          isOpen={createModalState.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClosed}
          onSave={(data) => {
            return create
              .onSuccess(() => {
                loadContent(debQueryParams)
                createModalActions.close()
              })
              .asPromise(data)
          }}
          action="create"
        />
      )}
      {editModalState.value && (
        <OptionModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          onClosed={editModalActions.onClosed}
          option={editModalState.value}
          onSave={(data) => {
            const opt = editModalState.value.id
            return update
              .onSuccess(() => {
                loadContent(debQueryParams)
                editModalActions.close()
              })
              .asPromise(opt, data)
          }}
          action="edit"
        />
      )}
      {deleteModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            destroy(deleteModalState.value.id)
            deleteModalActions.close()
          }}
          isOpen={deleteModalState.isOpen}
          toggle={deleteModalActions.toggle}
          onExited={deleteModalActions.onClosed}
          title={t('delete_option_title', {
            name: deleteModalState.value.name,
          })}
          text={t('delete_option_text', {
            name: deleteModalState.value.name,
          })}
        />
      )}
    </Layout>
  )
}
