import dayjs from 'dayjs'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import React, { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { ExclamationTriangle, PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import { useAssistanceContracts } from '../../hooks/useAssistanceContract'
import { CreateAssistanceContractModal } from './CreateAssistanceContractModal'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

export function AssistanceContracts({ org }) {
  const history = useHistory()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const { t } = useTranslation()

  const filters = useMemo(
    () => ({
      ...debQueryParams,
      customer: org.id,
    }),
    [debQueryParams, org.id]
  )

  const [{ contracts, loading, error, pagination }, { create, remove, run: reload }] = useAssistanceContracts(filters)

  const [createModalState, createModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()

  if (error) {
    return <div>Si è verificato un errore</div>
  }

  return (
    <div className="container-fluid mt-4">
      <div className="mb-4 row justify-content-between">
        <div className="col-md-3">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
                page: 1,
                expired: queryParams.expired ?? '',
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
                page: 1,
                expired: '',
              })
            }
          />
        </div>
        <div className="col-md-3 text-right">
          <Button
            size="sm"
            variant="outline-primary"
            icon={<PlusCircleDotted size={18} />}
            onClick={() => {
              createModalActions.open('dummy')
            }}
          >
            {t('create_assistance_contract')}
          </Button>
        </div>
      </div>
      <Table loading={loading} responsive hover>
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th>{t('products')}</th>
            <th>{t('from_date')}</th>
            <th>{t('to_date')}</th>
            <th>{t('status')}</th>
            <th>{t('converted_from')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contracts &&
            contracts.map((contract) => {
              const fromDate = dayjs(contract.from_date)
              const toDate = dayjs(contract.to_date)
              return (
                <tr key={contract.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/assistance-contract/${contract.id}`}>
                      {contract.id}
                    </Link>
                  </td>
                  <td>
                    {contract.products_data.map((p) => (
                      <div key={p.id}>{p.name}</div>
                    ))}
                    {contract.legacy_products.map((p) => (
                      <div key={p} className="d-flex flex-row align-items-center">
                        {p} <ExclamationTriangle className="ml-1" />
                      </div>
                    ))}
                  </td>
                  <td>{fromDate.format('DD/MM/YYYY')}</td>
                  <td>{toDate.format('DD/MM/YYYY')}</td>
                  <td>{t('assistance_contract_status_' + contract.status)}</td>
                  <td>
                    {contract.convert_from && (
                    <Link to={`/orgs/${org.id}/assistance-contract-legacy`}>
                      {contract.convert_from}
                    </Link>
                    )}
                  </td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => delModalActions.open(contract)}
                      icon={<Trash size={15} />}
                    >
                      {t('delete')}
                    </Button>
                  </td>
                  
                </tr>
              )
            })}
        </tbody>
      </Table>
      <div style={{ width: '100vw', overflow: 'scroll' }}>
        <Paginator
          numPages={pagination.numPages}
          currentPage={+debQueryParams.page || 1}
          goToPage={(page) => setQueryParams({ page })}
        />
      </div>
      {createModalState.value && (
        <CreateAssistanceContractModal
          isOpen={createModalState.isOpen}
          onClosed={createModalActions.onClosed}
          onCancel={createModalActions.close}
          onSubmit={(values) => {
            return create
              .onSuccess((result) => {
                createModalActions.close()
                history.push(`/assistance-contract/${result.id}`)
              })
              .asPromise({
                from_date: values.from_date,
                to_date: values.to_date,
                customer: org.id,
              })
          }}
        />
      )}
      {delModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            remove
              .onSuccess(() => {
                delModalActions.close()
                reload(filters)
              })
              .run(delModalState.value.id)
          }}
          isOpen={delModalState.isOpen}
          toggle={delModalActions.toggle}
          onExited={delModalActions.onClosed}
          title={t('delete_assistance_contract_title', {
            id: delModalState.value.id,
          })}
          text={t('delete_assistance_contract_text', {
            id: delModalState.value.id,
          })}
        />
      )}
    </div>
  )
}
