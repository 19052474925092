import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { InputField } from '../../components/Form'
import { OwnedHwKeyTypeaheadField } from '../../components/OwnedHwKeyTypeahead'
import * as yup from 'yup'

/**
 *
 * @typedef {Object} ManagableKey
 * @property {number} key
 * @property {number} concurrency
 *
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: ManagableKey) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 *    data: ManagableKey,
 *    customerId: number
 * }} props
 * @returns
 */
export function ManageKeyModal({
  isOpen,
  onSubmit,
  onCancel,
  onClosed,
  data,
  customerId,
  availableConcurrency,
}) {
  const { t } = useTranslation()

  const schema = yup.object().shape({
    key: yup.number().required(t('error_required')),
    concurrency: yup
      .number()
      .integer()
      .min(1, t('error_min'))
      .max(availableConcurrency, t('error_max')),
  })

  return (
    <Formik
      initialValues={data}
      onSubmit={(values, actions) => onSubmit(values)}
      validationSchema={schema}
    >
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>
            {data.key ? t('manage_key') : t('add_key')}
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Field
                name="key"
                component={OwnedHwKeyTypeaheadField}
                label={t('key')}
                filters={{ customer: customerId, to_date__isnull: true }}
              />
              <Field
                name="concurrency"
                component={InputField}
                type="number"
                step="1"
                label={t('concurrency')}
                max={availableConcurrency}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
