import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import Button from '../../components/Button'
import { Check, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import { ProductModal } from '../../components/ProductModal'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { useProducts } from '../../hooks/useProducts'

export function ProductsList() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [{ products, loading, error }, { run: loadContent, create }] =
    useProducts(debQueryParams)

  const [createModalState, createModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
              })
            }
          />
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createModalActions.open('dummy')}
            icon={<PlusCircleDotted size={15} />}
          >
            {t('create_product')}
          </Button>
        </div>
        <Table loading={loading} responsive hover>
          <thead>
            <tr>
              <th>{t('product')}</th>
              <th>{t('code')}</th>
              <th>{t('is_external')}</th>
            </tr>
          </thead>
          <tbody>
            {products &&
              products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <Link
                      className="font-weight-bold"
                      to={`/catalog/${product.id}`}
                    >
                      {product.name}
                    </Link>
                  </td>
                  <td>{product.code}</td>
                  <td>{product.is_external ? <Check size={20} /> : null}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {createModalState.value && (
        <ProductModal
          isOpen={createModalState.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClosed}
          onSave={(data) => {
            return create
              .onSuccess(() => {
                loadContent(debQueryParams)
                createModalActions.close()
              })
              .asPromise(data)
          }}
          action="create"
        />
      )}
    </Layout>
  )
}
