import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  BoxSeam,
  CheckCircle,
  PlusCircleDotted,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useProducts } from '../../hooks/useProducts'
import Button from '../Button'
import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
  transformErrorForForm,
} from '../Form'

const INITIAL_VALUES = {
  code: '',
  name: '',
  description: '',
  legacy_mode: false,
  deprecated: false,
}

export function ProductLevelModal({
  onSave,
  isOpen,
  toggle,
  onClosed,
  level = INITIAL_VALUES,
  action = 'edit',
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required(
          t('form_required', {
            field: t('code'),
          })
        ),
        name: Yup.string().required(
          t('form_required', {
            field: t('name'),
          })
        ),
        description: Yup.string().notRequired(),
      }),
    [t]
  )

  const [{ products }] = useProducts()

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={level}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <BoxSeam className="mr-2" />
                  {t(`${action}_product_level`)}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('product')}
                  name="product"
                  component={SelectField}
                  disabled={!!level.product}
                  options={
                    products?.map((product) => ({
                      label: product.name,
                      value: product.id,
                    })) ?? []
                  }
                />
                <Field
                  required
                  label={t('code')}
                  name="code"
                  component={InputField}
                />
                <Field
                  required
                  label={t('name')}
                  name="name"
                  component={InputField}
                />
                <Field
                  label={t('description')}
                  name="description"
                  component={TextAreaField}
                  rows={4}
                />
                <Field
                  label={t('legacy')}
                  name="legacy_mode"
                  component={CheckboxField}
                />
                <Field
                  label={t('deprecated')}
                  name="deprecated"
                  component={CheckboxField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button
                    icon={<PlusCircleDotted size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button
                    icon={<CheckCircle size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
