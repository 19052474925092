import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Table } from 'react-bootstrap'
import {
  Bag,
  Check,
  ExclamationTriangle,
  Key,
  PlusCircleDotted,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import { ManageKeyModal } from './ManageKeyModal'

export function Keys({ license, assignToKey, editAssignation, removeFromKey }) {
  const { t } = useTranslation()

  const [manageModalState, manageModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  const usedSlots = license.assignations.reduce(
    (acc, curr) => acc + curr.concurrency,
    0
  )
  const totalSlots = license.quantity
  const availSlots = totalSlots - usedSlots

  return (
    <>
      <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
        <h3 className="m-0 d-flex align-items-center">
          <Key className="mr-2" /> {t('dlic_license_keys')}
        </h3>
        <Button
          size="sm"
          variant="outline-primary"
          disabled={availSlots < 1}
          icon={<PlusCircleDotted />}
          className="mr-2"
          onClick={() => {
            manageModalActions.open({
              concurrency: 1,
              avail_concurrency: availSlots,
            })
          }}
        >
          {t('add')}
        </Button>
      </div>
      <div className="mt-4 px-3">
        <Table responsive hover className="table-layout-fixed">
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('key')}</th>
              <th style={{ width: '20%' }}>{t('status')}</th>
              <th style={{ width: '25%' }}>{t('concurrency')}</th>
              <th style={{ width: '15%' }}></th>
            </tr>
          </thead>
          <tbody>
            {license.assignations.map((assignation) => {
              return (
                <tr key={assignation.id}>
                  <th>
                    <Link to={`/hw-keys/${assignation.hardware_key}`}>
                      {assignation.hardware_key}
                    </Link>
                  </th>
                  <td>
                    {assignation.valid && <Check size={22} />}
                    {!assignation.valid && (
                      <>
                        <ExclamationTriangle size={22} />
                        <span>Persa</span>
                      </>
                    )}
                  </td>
                  <td>{assignation.concurrency}</td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      icon={<Bag />}
                      className="mr-2"
                      onClick={() => {
                        manageModalActions.open({
                          id: assignation.id,
                          key: assignation.hardware_key_owner,
                          concurrency: assignation.concurrency,
                          avail_concurrency:
                            availSlots + assignation.concurrency,
                        })
                      }}
                    >
                      {t('manage')}
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      icon={<Trash />}
                      onClick={() => {
                        deleteModalActions.open({
                          id: assignation.id,
                          hardware_key: assignation.hardware_key,
                        })
                      }}
                    >
                      {t('delete')}
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {manageModalState.value && (
          <ManageKeyModal
            isOpen={manageModalState.isOpen}
            onCancel={manageModalActions.close}
            onClosed={manageModalActions.onClosed}
            data={manageModalState.value}
            onSubmit={(values) => {
              if (manageModalState.value.id) {
                return editAssignation
                  .onSuccess(() => {
                    manageModalActions.close()
                  })
                  .asPromise(manageModalState.value.id, {
                    hardware_key_owner: values.key,
                    concurrency: values.concurrency,
                  })
              } else {
                return assignToKey
                  .onSuccess(() => {
                    manageModalActions.close()
                  })
                  .asPromise(license.id, {
                    hardware_key_owner: values.key,
                    concurrency: values.concurrency,
                  })
              }
            }}
            customerId={license.customer}
            availableConcurrency={manageModalState.value.avail_concurrency ?? 1}
          />
        )}

        {deleteModalState.value && (
          <ModalConfirmDelete
            isOpen={deleteModalState.isOpen}
            toggle={deleteModalActions.toggle}
            title={t('unassign_license_key_title', {
              hardware_key: deleteModalState.value.hardware_key,
            })}
            text={t('unassign_license_key_message', {
              hardware_key: deleteModalState.value.hardware_key,
            })}
            onExited={deleteModalActions.onClosed}
            onDelete={() => {
              removeFromKey(deleteModalState.value.id)
              deleteModalActions.close()
            }}
          />
        )}
      </div>
    </>
  )
}
