
import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const LicensesListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .computed({
    licenses: 'getList',
    loading: 'isLoading',
    pagination: 'getPagination',
  })
  .effect({
    name: 'LicensesList',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      api.auth(token).get('/admin/license', params),
  })
