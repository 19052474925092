import dayjs from 'dayjs'
import React from 'react'
import { Table } from 'react-bootstrap'
import { BoxSeam } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { useLicense, useLicenseHistory } from '../../hooks/useLicenses'

export function LicenseHistory() {
  const { id } = useParams()
  const { t } = useTranslation()

  const [{ license, error: licenseError }] = useLicense(id)
  const [{ history, error: historyError }] = useLicenseHistory(id)

  const error = licenseError || historyError

  if (error) {
    return <PageError error={error} />
  }

  if (history === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <BoxSeam className="mr-2" size={31} /> {t('license')} {license?.id}
          </h2>
        </div>

        <Table striped size="sm">
          <thead>
            <tr>
              <th>Data ora</th>
              <th>Autore</th>
              <th>Modifica</th>
            </tr>
          </thead>
          <tbody>
            {history.map((record) => (
              <tr key={record.id}>
                <td className="w-25">
                  {dayjs(record.created_at).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td className="w-25">{record.name}</td>
                <td className="w-50">{record.message}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  )
}
