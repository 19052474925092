import { rj } from 'react-rocketjump'
import api from '../api'

export const RecoverState = rj().effect({
  name: 'Recover',
  effect: (payload) =>
    api.mapResponse(() => true).post('/account/recover-password', payload),
})

export const ResetState = rj().effect({
  name: 'Reset',
  effect: (payload) => api.post('/account/reset-password', payload),
})

export const ChangePasswordState = rj().effect({
  name: 'ChangePassword',
  effectCaller: 'configured',
  effect: (t) => (payload) =>
    api.auth(t).post('/account/change-password', payload),
})
