import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { DateField, InputField, SelectField, TextAreaField } from '../../components/Form'
import { OrganizationTypeaheadField } from '../../components/OrganizationTypeahead'
import { ProductLevelTypeaheadField } from '../../components/ProductLevelTypeahead'
import { ProductTypeaheadField } from '../../components/ProductTypeahead'

/**
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: any) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 *    license: any
 * }} props
 * @returns
 */
export function EditInfoModal({ isOpen, onSubmit, onCancel, onClosed, license }) {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={license}
      onSubmit={(values, actions) => {
        const payload = { ...values }
        if (!payload.can_update_until) {
          payload.can_update_until = null
        }
        if (!payload.expire_date) {
          payload.expire_date = null
        }
        return onSubmit(payload)
      }}
    >
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('manage_license')}</Modal.Header>
          <Modal.Body>
            <Form>
              <Field name="product" component={ProductTypeaheadField} label={t('product')} />
              {!!formik.values.product && (
                <Field
                  name="service_level"
                  component={ProductLevelTypeaheadField}
                  productId={formik.values.product}
                  label={t('product_level')}
                />
              )}
              {!formik.values.product && (
                <Field
                  name="service_level_x"
                  component={ProductLevelTypeaheadField}
                  productId={formik.values.product}
                  label={t('product_level')}
                  disabled
                />
              )}
              <Field name="min_version" component={InputField} label={t('dlic_license_version_min')} />
              <Field name="max_version" component={InputField} label={t('dlic_license_version_max')} disabled />
              <Field
                name="quantity"
                component={InputField}
                type="number"
                min="0"
                step="1"
                label={t('dlic_license_quantity')}
              />
              <Field
                name="license_type"
                component={SelectField}
                options={[
                  { value: 'perpetual', label: t('dlic_license_perpetual') },
                  { value: 'expire', label: t('dlic_license_expiring') },
                ]}
                label={t('dlic_license_type')}
              />
              {formik.values.license_type === 'expire' && (
                <Field name="expire_date" component={DateField} label={t('dlic_license_expire_date')} />
              )}
              <Field name="oem" component={OrganizationTypeaheadField} label={t('dlic_license_oem')} />
              <Field name="activation_date" component={DateField} label={t('dlic_license_activation_date')} />
              <Field name="notes" component={TextAreaField} label={t('dlic_license_notes')} />
              <Field
                name="can_update_until"
                component={DateField}
                label={t('licence_update_contract_expiration_date')}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
