import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm } from '../../components/Form'
import Button from '../../components/Button'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  old_password: '',
  new_password: '',
  repeat_password: '',
}

export default function ChangePasswordForm({ onSave, backBtn }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        old_password: Yup.string().required(
          t('form_required', {
            field: t('old_password'),
          })
        ),
        new_password: Yup.string().required(
          t('form_required', {
            field: t('new_password'),
          })
        ),
        repeat_password: Yup.string()
          .oneOf([Yup.ref('new_password'), null], t('passowrd_match_error'))
          .required(
            t('form_required', {
              field: t('repeat_new_password'),
            })
          ),
      }),
    [t]
  )

  return (
    <Formik
      validationSchema={schema}
      initialValues={INITIAL_VALUES}
      onSubmit={(values, actions) =>
        onSave(values).then(
          () => {
            actions.setStatus({ changed: true })
          },
          (err) => {
            actions.setStatus({ changed: false })
            actions.setErrors(transformErrorForForm(err))
          }
        )
      }
    >
      {({ handleSubmit, isSubmitting, status }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Field
            required
            label={t('old_password')}
            name="old_password"
            component={InputField}
            type="password"
          />
          <Field
            required
            label={t('new_password')}
            name="new_password"
            component={InputField}
            type="password"
          />
          <Field
            label={t('repeat_new_password')}
            name="repeat_password"
            component={InputField}
            type="password"
          />
          <div className="d-flex">
            {backBtn}
            <Button variant="success" disabled={isSubmitting} type="submit">
              {t('change_password')}
            </Button>
          </div>
          {status?.changed && (
            <Alert className="mt-2" variant="success">
              {t('password_changed')}
            </Alert>
          )}
        </form>
      )}
    </Formik>
  )
}
