import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  FileEarmarkArrowUp,
  FileEarmarkLock2,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../../components/Button'
import { FileField, transformErrorForForm } from '../../components/Form'

const INITIAL_VALUES = {
  emails: null,
}

export default function ImportLicensesModal({
  onSave,
  isOpen,
  toggle,
  onExited,
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        emails: Yup.mixed().required(
          t('form_required', {
            field: 'Emails CSV',
          })
        ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FileEarmarkLock2 className="mr-2" />
                  {t('import_licenses')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  accept="text/csv"
                  label={'Emails CSV'}
                  name="emails"
                  component={FileField}
                />
                {errors.detail && (
                  <Alert variant="danger" className="mt-2">
                    {errors.detail}
                  </Alert>
                )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  Undo
                </Button>
                <Button
                  icon={<FileEarmarkArrowUp size={18} />}
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Import
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
