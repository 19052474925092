import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm } from '../../components/Form'
import { Modal } from 'react-bootstrap'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import {
  ArrowLeft,
  PlusCircleDotted,
  PeopleFill,
  Pencil,
} from 'react-bootstrap-icons'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  name: '',
}

export default function OrgModal({ onSave, org, isOpen, toggle, onExited }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          t('form_required', {
            field: t('organization_name'),
          })
        ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={org ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <PeopleFill className="mr-2" />
                  {org
                    ? t('edit_organization_title', { name: org.name })
                    : t('create_organization')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('organization_name')}
                  name="name"
                  component={InputField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={
                    org ? <Pencil size={18} /> : <PlusCircleDotted size={18} />
                  }
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {org ? t('update') : t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
