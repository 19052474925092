import { rj, useRj } from 'react-rocketjump'
import { api2 } from '../api'

const DlicPreviewRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get(`/license/dlic/preview/`, params)
  },
})

export function useDlicPreview() {
  return useRj(DlicPreviewRj)
}

const DlicComputeRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get(`/license/dlic/compute/`, params)
  },
})

export function useDlicCompute() {
  return useRj(DlicComputeRj)
}
