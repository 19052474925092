import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { OrganizationTypeahead } from '../../components/OrganizationTypeahead'

export function MergeOrgModal({ isOpen, onSubmit, onCancel, onClosed }) {
  const { t } = useTranslation()
  const [selectedOrg, setSelectedOrg] = useState(null)

  return (
    <Modal show={isOpen} onExited={onClosed}>
      <Modal.Header>{t('merge_org')}</Modal.Header>
      <Modal.Body>
        <p>{t('merge_org_modal_help')}</p>
        <OrganizationTypeahead value={selectedOrg} onChange={(val) => setSelectedOrg(val || null)} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button icon={<ArrowLeft />} onClick={onCancel} variant="secondary" type="button">
          {t('undo')}
        </Button>
        <Button icon={<Check />} variant="danger" disabled={!selectedOrg} onClick={() => onSubmit(selectedOrg)}>
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
