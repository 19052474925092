import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { CheckboxField, DateField, TextAreaField } from '../../components/Form'

/**
 * @param {{
 *    contract: { from_date: string, to_date: string, is_active: boolean, inactive_reason: string, notes: string }
 *    isOpen: bool,
 *    onSubmit: (arg: any) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 * }} props
 * @returns
 */
export function EditAssistanceContractModal({ contract, isOpen, onSubmit, onCancel, onClosed }) {
  const { t } = useTranslation()

  return (
    <Formik initialValues={contract} onSubmit={(values, actions) => onSubmit(values)}>
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('edit_assistance_contract')}</Modal.Header>
          <Modal.Body>
            <Form>
              <Field name="from_date" component={DateField} label={t('from_date')} required />
              <Field name="to_date" component={DateField} label={t('to_date')} required />
              <Field name="is_active" component={CheckboxField} label={t('active')}  />
              <Field name="inactive_reason" component={TextAreaField} label={t('suspension_reason')}  />
              <Field name="notes" component={TextAreaField} label={t('notes')}  />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
