import { useTranslation } from 'react-i18next'
import { ConfigureRj } from 'react-rocketjump'
import EazyAuth, { useAuthActions } from 'use-eazy-auth'
import { AuthRoutesProvider } from 'use-eazy-auth/routes'
import api from './api'
import apiUvicorn from './apiUvicorn'
import PageBigSpinner from './components/PageBigSpinner'

const loginCall = (params) => apiUvicorn.post('/sso-complete', params)
const meCall = (token) => api.auth(token).get('/admin/me')

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  const { i18n } = useTranslation()
  return (
    <EazyAuth
      loginCall={loginCall}
      meCall={meCall}
      onAuthenticate={(user) => {
        i18n.changeLanguage(user.language)
      }}
    >
      <AuthRoutesProvider spinner={<PageBigSpinner />}>
        <InnerAuth>{children}</InnerAuth>
      </AuthRoutesProvider>
    </EazyAuth>
  )
}

export default Auth
