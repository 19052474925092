import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm } from '../../components/Form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Button from '../../components/Button'
import {
  ArrowLeft,
  PlusCircleDotted,
  FileEarmarkLock2,
} from 'react-bootstrap-icons'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  email: '',
}

export default function AddLicenseModal({ onSave, isOpen, toggle, onExited }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(
            t('form_required', {
              field: t('user_email'),
            })
          )
          .email(
            t('form_email', {
              field: t('user_email'),
            })
          ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FileEarmarkLock2 className="mr-2" />
                  {t('create_license')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('user_email')}
                  name="email"
                  type="email"
                  component={InputField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={<PlusCircleDotted size={18} />}
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
