import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { FileField, InputField, transformErrorForForm } from '../../components/Form'
import { Modal } from 'react-bootstrap'
import Button from '../../components/Button'
import {
  ArrowLeft,
  PlusCircleDotted,
  Pencil,
  Puzzle,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  name: '',
  version: '',
  product_code: '',
  home_url: '',
}

export default function AppModal({ onSave, app, isOpen, toggle, onExited }) {
  const { t } = useTranslation()

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          t('form_required', {
            field: t('application_name'),
          })
        ),
        version: Yup.string(),
        product_code: Yup.string().required(
          t('form_required', {
            field: t('application_product_code'),
          })
        ),
        home_url: Yup.string().nullable(),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={app ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Puzzle className="mr-2" />
                  {app
                    ? t('edit_app_title', { name: app.name })
                    : t('create_app')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  label={t('application_name')}
                  required
                  name="name"
                  component={InputField}
                />
                <Field
                  label={t('application_version')}
                  name="version"
                  component={InputField}
                />
                <Field
                  label={t('application_product_code')}
                  required
                  name="product_code"
                  component={InputField}
                />
                <Field
                  label={t('application_home_url')}
                  name="home_url"
                  component={InputField}
                />
                <Field
                  label={t('application_background_image')}
                  name="background_image"
                  withImagePreview
                  withRemoveFile
                  component={FileField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={
                    app ? <Pencil size={18} /> : <PlusCircleDotted size={18} />
                  }
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {app ? t('update') : t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
