import { rj, useRunRj } from 'react-rocketjump'
import apiUvicorn from '../apiUvicorn'
import api from '../api'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { PAGE_SIZE } from '../consts'



export const LicensesUvicornState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .computed({
    licensesUvi: 'getList',
    loading: 'isLoading',
    pagination: 'getPagination',
  })
  .effect({
    name: 'LicensesUvicornState',
    effectCaller: 'configured',
    effect: (token) => (params) =>
    api.auth(token).get(`/legacy/license`, params),
  })

  export function useLicensesUvi(params) {
    return useRunRj(LicensesUvicornState, [params], false)
  }

export const LicenseUviDetailState = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => api.auth(token).get(`/legacy/license/${id}`),
  mutations: {
    convertIntoLicense: rj.mutation.single({
      effect: (token) => (id, level_and_product) => api.auth(token).post('/legacy/license/'+id+'/convert-into-license/', level_and_product),
      updater: (state) => state,
    }),
    renewLicense: rj.mutation.single({
      effect: (token) => (id, expiration_date) => api.auth(token).post('/legacy/license/'+id+'/renew-license/', expiration_date),
      updater: (state) => state,
    }),
  },
  computed: {
    license: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})


export function useLicenseUviDetail(id) {
  return useRunRj(LicenseUviDetailState, [id], true)
}
