import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const LicencePoolListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    removeLicensePool: {
      effect: (token) => (poolData) =>
        api
          .auth(token)
          .mapResponse(() => poolData)
          .delete(`/admin/license-pool/${poolData.id}`),
      updater: 'deleteItem',
    },
    updateLicensePool: {
      effect: (token) => (poolData) =>
        api.auth(token).put(`/admin/license-pool/${poolData.id}`, poolData),
      updater: 'updateItem',
    },
    addLicensePool: {
      effect: (token) => (poolData) =>
        api.auth(token).post('/admin/license-pool', poolData),
      updater: (s) => s,
    },
  })
  .computed({
    pools: 'getList',
    loading: 'isLoading',
    pagination: 'getPagination',
  })
  .effect({
    name: 'LicensePoolList',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      api.auth(token).get('/admin/license-pool', params),
  })

export const LicencePoolDetailState = rj()
  .mutations({
    updateLicensePool: {
      effect: (token) => (poolData) =>
        api.auth(token).put(`/admin/license-pool/${poolData.id}`, poolData),
      updater: 'updateData',
    },
    removeLicensePool: {
      effect: (token) => (poolData) =>
        api
          .auth(token)
          .mapResponse(() => poolData)
          .delete(`/admin/license-pool/${poolData.id}`),
      updater: (s) => s,
    },
  })
  .computed({
    pool: 'getData',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'LicensePoolDetail',
    effectCaller: 'configured',
    effect: (token) => (id) => api.auth(token).get(`/admin/license-pool/${id}`),
  })

export const LicenceListByPoolState = rj()
  .plugins(rjPlainList())
  .mutations({
    importLicenses: {
      effect: (token) => (poolId, importPayload) => {
        const data = new FormData()
        data.append('emails', importPayload.emails)
        return api
          .auth(token)
          .post(`/admin/license-pool/${poolId}/csv/licenses`, data)
      },
      updater: (s) => s,
    },
    addLicense: {
      effect: (token) => (poolId, license) =>
        api.auth(token).post(`/admin/license-pool/${poolId}/licenses`, license),
      updater: (s) => s,
    },
    enableLicense: {
      effect: (token) => (poolId, id) =>
        api
          .auth(token)
          .post(`/admin/license-pool/${poolId}/licenses/${id}/enable/`),
      updater: 'updateItem',
    },
    disableLicense: {
      effect: (token) => (poolId, id) =>
        api
          .auth(token)
          .post(`/admin/license-pool/${poolId}/licenses/${id}/disable/`),
      updater: 'updateItem',
    },
    removeLicense: {
      effect: (token) => (poolId, id) =>
        api
          .auth(token)
          .mapResponse(() => ({ id }))
          .delete(`/admin/license-pool/${poolId}/licenses/${id}`),
      updater: 'deleteItem',
    },
  })
  .computed({
    licenses: 'getList',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'LicenceListByPool',
    effectCaller: 'configured',
    effect: (token) => (id) =>
      api.auth(token).get(`/admin/license-pool/${id}/licenses`),
  })

export const LogsListByPoolState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .computed({
    logs: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'LogsListByPool',
    effectCaller: 'configured',
    effect: (token) => (id, params) =>
      api.auth(token).get(`/admin/license-pool/${id}/log`, params),
  })
