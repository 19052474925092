import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useRunRj } from 'react-rocketjump'
import { Gear, Pencil, PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { AppsListState } from '../../state/apps'
import Layout from '../../components/Layout'
import Search from '../../components/Search'
import { Link, useHistory } from 'react-router-dom'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import AppModal from './AppModal'
import Button from '../../components/Button'
import PageError from '../../components/PageError'
import Table from '../../components/Table'
import Paginator from '../../components/Paginator'
import { useTranslation } from 'react-i18next'

export default function AppsList() {
  const { t } = useTranslation()
  const history = useHistory()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [
    { apps, pagination, loading, error },
    { deleteApp, createApp, updateApp },
  ] = useRunRj(AppsListState, [debQueryParams], false)

  const [rmModal, rmModalActions] = useModalTrigger()
  const [editModal, editModalActions] = useModalTrigger()
  const [addModal, addModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
                page: 1,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
                page: 1,
              })
            }
          />
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => addModalActions.open()}
            icon={<PlusCircleDotted size={15} />}
          >
            {t('create_app')}
          </Button>
        </div>
        <Table loading={loading} responsive>
          <thead>
            <tr>
              <th>{t('application_name')}</th>
              <th>{t('application_version')}</th>
              <th>{t('application_product_code')}</th>
              <th>{t('application_background_image')}</th>
              <th className="text-right pr-5">
                <Gear />
              </th>
            </tr>
          </thead>
          <tbody>
            {apps &&
              apps.map((app) => (
                <tr key={app.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/apps/${app.id}`}>
                      {app.name}
                    </Link>
                  </td>
                  <td>{app.version}</td>
                  <td>{app.product_code}</td>
                  <td className="text-center">
                    {app.background_image && (
                      <a
                        rel="noopener noreferrer"
                        href={app.background_image}
                        target="_blank"
                      >
                        <img
                          className="img-thumbnail"
                          style={{ maxWidth: 50 }}
                          alt={t('application_background_image')}
                          src={app.background_image}
                        />
                      </a>
                    )}
                  </td>
                  <td className="text-right">
                    <Button
                      onClick={() => editModalActions.open(app)}
                      icon={<Pencil />}
                      variant={'outline-primary'}
                      className="mr-2"
                      size="sm"
                    >
                      {t('edit_app')}
                    </Button>
                    <Button
                      onClick={() => rmModalActions.open(app)}
                      icon={<Trash />}
                      variant={'outline-danger'}
                      size="sm"
                      disabled={app.cloud_licensing_application}
                    >
                      {t('delete_app')}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Paginator
          numPages={pagination.numPages}
          currentPage={+debQueryParams.page || 1}
          goToPage={(page) => setQueryParams({ page })}
        />
      </div>
      <ModalConfirmDelete
        onDelete={() => {
          deleteApp(rmModal.value)
          rmModalActions.close()
        }}
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        onExited={rmModalActions.onClosed}
        title={
          rmModal.value
            ? t('delete_app_title', { name: rmModal.value.name })
            : null
        }
        text={
          rmModal.value
            ? t('delete_app_text', { name: rmModal.value.name })
            : null
        }
      />
      <AppModal
        onSave={(app) =>
          createApp
            .onSuccess((newApp) => {
              history.push(`/apps/${newApp.id}`)
            })
            .asPromise(app)
        }
        isOpen={addModal.isOpen}
        toggle={addModalActions.toggle}
        onExited={addModalActions.onClosed}
      />
      <AppModal
        app={editModal.value}
        onSave={(app) =>
          updateApp.onSuccess(editModalActions.close).asPromise(app)
        }
        isOpen={editModal.isOpen}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
      />
    </Layout>
  )
}
