import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm } from '../../components/Form'
import { Form, Modal } from 'react-bootstrap'
import Button from '../../components/Button'
import { ArrowLeft, Key, Pencil, PlusCircleDotted } from 'react-bootstrap-icons'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  name: '',
  authorizations: [],
}

const allPermissions = [
  'catalog/product',
  'catalog/configuration',
  'main/user',
  'main/password',
  'main/loginproxy',
  'license/signv2c',
  'license/download_v2c',
  "license/create_key",
  "license/redeem_key",
  "digital-identity/manage",
  "license/resign_legacy_licenses"
]

export default function SecretModal({
  secret,
  onSave,
  isOpen,
  toggle,
  onExited,
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          t('form_required', {
            field: t('secret_name'),
          })
        ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={secret ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Key className="mr-2" />
                  {secret
                    ? t('edit_secret_title', { name: secret.name })
                    : t('generate_secret')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('secret_name')}
                  name="name"
                  component={InputField}
                />
                <p>
                  <b>{t('permissions')}</b>
                </p>
                {allPermissions.map((permName) => {
                  const isSet = values.authorizations.includes(permName)
                  const toggle = (shouldSet) => {
                    const nextValue = shouldSet
                      ? values.authorizations.concat([permName])
                      : values.authorizations.filter((n) => n !== permName)
                    console.log(nextValue)
                    setFieldValue('authorizations', nextValue)
                  }
                  return (
                    <Form.Group key={permName}>
                      <Form.Check
                        label={t(`permission_${permName}`)}
                        value={undefined}
                        checked={isSet}
                        onChange={(e) => toggle(e.target.checked)}
                      />
                    </Form.Group>
                  )
                })}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={
                    secret ? (
                      <Pencil size={18} />
                    ) : (
                      <PlusCircleDotted size={18} />
                    )
                  }
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {secret ? t('update') : t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
