import { Field, FormikProvider, useFormik } from 'formik'
import { useCallback, useMemo, useRef } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { ArrowLeft, CheckCircle, FiletypeYml, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { InputField, TextAreaField, transformErrorForForm } from '../Form'

const INITIAL_VALUES = {
  lang: '',
  content_yaml: '',
}

export function ChangelogModal({ onSave, isOpen, toggle, onClosed, changelog = INITIAL_VALUES, action = 'edit' }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        lang: Yup.string().required(
          t('form_required', {
            field: t('changelog_lang'),
          })
        ),
        content_yaml: Yup.string().required(
          t('form_required', {
            field: t('changelog_content'),
          })
        ),
      }),
    [t]
  )
  const formik = useFormik({
    validationSchema: schema,
    initialValues: changelog,
    onSubmit: (values, actions) =>
      onSave(values).catch((err) => {
        actions.setErrors(transformErrorForForm(err))
      }),
  })
  const fileInputRef = useRef()

  const handleFileUpload = useCallback(
    (e) => {
      const target = e.target
      const file = e?.target?.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          formik.setFieldValue('content_yaml', reader.result)
          target.value = ''
        })
        reader.readAsText(file)
      }
    },
    [formik]
  )

  const { handleSubmit, isSubmitting } = formik

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed} size="lg">
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <FiletypeYml className="mr-2" />
                {t(`${action}_changelog`)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Field required label={t('changelog_lang')} name="lang" component={InputField} />
              <Field
                required
                label={t('changelog_content')}
                name="content_yaml"
                component={TextAreaField}
                style={{ fontFamily: 'monospace' }}
                rows={10}
                hint={
                  <u className="pointer" onClick={() => fileInputRef.current.click()}>
                    Carica un file
                  </u>
                }
              />
              {formik.errors.non_field_errors && (
                <Alert variant='danger'>{formik.errors.non_field_errors}</Alert>
              )}

              <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} ref={fileInputRef} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" disabled={isSubmitting} type="button">
                {t('undo')}
              </Button>
              {action === 'create' && (
                <Button icon={<PlusCircleDotted size={18} />} variant="success" disabled={isSubmitting} type="submit">
                  {t('create')}
                </Button>
              )}
              {action === 'edit' && (
                <Button icon={<CheckCircle size={18} />} variant="success" disabled={isSubmitting} type="submit">
                  {t('save')}
                </Button>
              )}
            </Modal.Footer>
          </>
        </form>
      </FormikProvider>
    </Modal>
  )
}
