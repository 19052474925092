import { format } from 'date-fns'
import it from 'date-fns/locale/it'
import { Field, getIn } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import AppTypeahead from '../AppTypeahead'
import Button from '../Button'
import dayjs from 'dayjs'

registerLocale('it', it)

export const InputField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = '',
  required = false,
  hint = '',
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <input
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        {...props}
        {...field}
        value={props.type === 'date' ? dayjs(field.value).format('YYYY-MM-DD') : field.value ?? ''}
      />
      {hint && <small>{hint}</small>}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export const TextAreaField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = '',
  required = false,
  hint = '',
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <textarea
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        {...props}
        {...field}
        value={field.value ?? ''}
      />
      {hint && <small>{hint}</small>}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export const SelectField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = '',
  required = false,
  options = [],
  placeholder = '',
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <select
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        {...props}
        {...field}
        value={field.value ?? ''}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((opt) => (
          <option value={opt.value}>{opt.label}</option>
        ))}
      </select>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

function ImagePreview({ value }) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    // Got a file
    if (value instanceof File) {
      const objUrl = URL.createObjectURL(value)
      setUrl(objUrl)
      return () => {
        URL.revokeObjectURL(objUrl)
      }
    }
    // Got a string?
    try {
      new URL(value)
      // Good url!
      setUrl(value)
    } catch (_) {
      // Bad url
      setUrl(null)
    }
  }, [value])

  if (url === null) {
    return null
  }

  return (
    <div className="mt-2">
      <img
        style={{ maxWidth: 150 }}
        alt="Upload preview"
        src={url}
        className="img-thumbnail"
      />
    </div>
  )
}

export const FileField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  withRemoveFile = false,
  withImagePreview = false,
  containerClassName = '',
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const inputRef = useRef()

  return (
    <div className={`form-group ${containerClassName}`}>
      {label && <label>{label}</label>}
      <div className="d-flex">
        <input
          ref={inputRef}
          className={`form-control ${error && touch ? 'is-invalid' : ''}`}
          {...props}
          {...field}
          style={
            withRemoveFile ? { borderRight: 0, ...props.style } : props.style
          }
          value={undefined}
          type="file"
          onChange={(e) => setFieldValue(field.name, e.target.files[0])}
        />
        {withRemoveFile && (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              inputRef.current.value = ''
              setFieldValue(field.name, '')
            }}
          >
            <Trash />
          </button>
        )}
      </div>
      {withImagePreview && <ImagePreview value={field.value} />}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export const CheckboxField = ({
  field,
  label,
  form: { touched, errors },
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <Form.Group>
      <Form.Check
        label={label}
        {...props}
        {...field}
        value={undefined}
        checked={field.value}
        feedback={error}
        isInvalid={error && touch}
      />
    </Form.Group>
  )
}

export function AppTypeaheadField({
  field,
  label,
  form,
  required = false,
  ...props
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <AppTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => form.setFieldValue(field.name, value)}
        value={field.value}
        id={`app-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}

export const DateField = ({
  label,
  field,
  form,
  style,
  required = false,
  ...props
}) => {
  let selected = null
  if (field.value) {
    const date = new Date(field.value)
    selected = isNaN(date.getTime()) ? null : date
  }

  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  const className = `form-control ${touch && error ? 'is-invalid' : ''}`

  return (
    <div className="form-group datepicker-full-width">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <div style={style}>
        <DatePicker
          autoComplete="off"
          className={className}
          showYearDropdown
          locale={'it'}
          showMonthDropdown
          scrollableYearDropdown
          // dropdownMode="select"
          yearDropdownItemNumber={5}
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          dateFormat="dd-MM-yyyy"
          // dateFormat='dd/MM/yyyy'
          onChange={(date, e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!date || date.toDateString() === 'Invalid Date') {
              form.setFieldValue(field.name, '')
            } else {
              form.setFieldValue(field.name, format(date, 'yyyy-MM-dd'))
            }
          }}
          {...props}
        />
        {error && touch && (
          <div className="invalid-feedback d-block">{error}</div>
        )}
      </div>
    </div>
  )
}

export const MetaField = ({ form, name, push, remove }) => {
  const metaList = getIn(form.values, name)
  const { t } = useTranslation()

  return (
    <div>
      {metaList.map((_, i) => (
        <div className="d-flex justify-content-between mt-2" key={i}>
          <div className="mr-2 pt-2">
            <Button
              onClick={() => remove(i)}
              variant="outline-danger"
              size="sm"
              icon={<Trash />}
            />
          </div>
          <Field
            containerClassName="mb-0 w-100 mx-2"
            placeholder={t('meta_key')}
            name={`${name}[${i}][0]`}
            component={InputField}
          />
          <Field
            containerClassName="mb-0 w-100"
            placeholder={t('meta_value')}
            name={`${name}[${i}][1]`}
            component={InputField}
          />
        </div>
      ))}
      <div className="text-right mt-2">
        <Button
          onClick={() => push(['', ''])}
          variant="outline-success"
          size="sm"
          icon={<PlusCircleDotted />}
        />
      </div>
    </div>
  )
}

export function YesNoField({ form, field, positive, negative, label }) {
  const { t } = useTranslation()
  return (
    <>
      {label && <label>{label}</label>}
      <div className="d-flex flex-row justify-content-start align-items-center mb-3">
        <div className="d-flex flex-row justify-content-start align-items-center flex-grow-1 flex-basis-0 pl-4">
          <input
            className="mr-2"
            type="radio"
            checked={field.value === true}
            name={field.name}
            onClick={(e) => form.setFieldValue(field.name, true)}
          />
          <span>{positive ?? t('yes')}</span>
        </div>
        <div className="d-flex flex-row justify-content-start align-items-center flex-grow-1 flex-basis-0 pl-4">
          <input
            className="mr-2"
            type="radio"
            checked={field.value === false}
            name={field.name}
            onClick={(e) => form.setFieldValue(field.name, false)}
          />
          <span>{negative ?? t('no')}</span>
        </div>
      </div>
    </>
  )
}
