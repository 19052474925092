import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import apiUvicorn from '../../apiUvicorn'

export function Logout() {
  const history = useHistory()
  const { token } = useAuthUser()
  const { logout } = useAuthActions()

  useEffect(() => {
    apiUvicorn
      .post('/sso-logout', { token })
      .toPromise()
      .then(() => {
        history.replace('/')
        logout()
      })
  }, [history, logout, token])

  return <div />
}
