import { Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import { AuthRoute, GuestRoute, MaybeAuthRoute } from 'use-eazy-auth/routes'
import Auth from './Auth'
import PageBigSpinner from './components/PageBigSpinner'
import PageNotFound from './components/PageNotFound'
import AppDetail from './pages/Apps/AppDetail'
import AppsList from './pages/Apps/AppsList'
import { AssistanceContract } from './pages/AssistanceContract'
import { ConfigurationDetail as CatalogConfigurationDetail } from './pages/Catalog_ConfigurationDetail'
import { ConfigurationList as CatalogConfigurationsList } from './pages/Catalog_ConfigurationList'
import { ConfigurationOptionList as CatalogConfigurationOptionList } from './pages/Catalog_ConfigurationOptionsList'
import { OptionList as CatalogOptionList } from './pages/Catalog_OptionsList'
import { ProductDetail as CatalogProductDetail } from './pages/Catalog_ProductDetail'
import { ProductLevel as CatalogProductLevel } from './pages/Catalog_ProductLevel'
import { ProductsList as CatalogProductList } from './pages/Catalog_ProductList'
import HwKeyDetail from './pages/HwKeyDetail'
import HwKeys from './pages/HwKeys/HwKeys'
import { LicenseConfiguration } from './pages/LicenseConfiguration'
import LicenseDetailUvi from './pages/LicenseDetailUvi'
import { LicenseHistory } from './pages/LicenseHistory'
import LicensePoolDetail from './pages/LicensePool/LicensePoolDetail'
import Licenses from './pages/Licenses'
import Login from './pages/Login'
import { Logout } from './pages/Logout'
import OrgDetail from './pages/Orgs/OrgDetail'
import OrgsList from './pages/Orgs/OrgsList'
import PasswordRecover from './pages/PasswordRecover'
import PasswordReset from './pages/PasswordReset'
import ProductDetail from './pages/Products/ProductDetail'
import ProductsList from './pages/Products/ProductsList'
import Profile from './pages/Profile'
import UserDetail from './pages/Users/UserDetail'
import UsersList from './pages/Users/UsersList'
import { VersionDetail } from './pages/Releases_VersionDetail'
import { ReleaseDetail } from './pages/Releases_ReleaseDetail'

function isAdmin(user) {
  if (!user.is_admin) {
    return '/orgs'
  }
}

function App() {
  return (
    <Suspense fallback={<PageBigSpinner />}>
      <Auth>
        <Router>
          <Switch>
            <GuestRoute path="/login">
              <Login />
            </GuestRoute>
            <GuestRoute path="/account/recover">
              <PasswordRecover />
            </GuestRoute>
            <GuestRoute path="/account/reset/:token">
              <PasswordReset />
            </GuestRoute>
            {/* CATALOG */}
            <AuthRoute path="/catalog" exact>
              <CatalogProductList />
            </AuthRoute>
            <AuthRoute path="/catalog/options" exact>
              <CatalogOptionList />
            </AuthRoute>
            <AuthRoute path="/catalog/:id" exact>
              <CatalogProductDetail />
            </AuthRoute>
            <AuthRoute path="/catalog/:id/level/:level" exact>
              <CatalogProductLevel />
            </AuthRoute>
            <AuthRoute path="/configurations" exact>
              <CatalogConfigurationsList />
            </AuthRoute>
            <AuthRoute path="/configurations/options" exact>
              <CatalogConfigurationOptionList />
            </AuthRoute>
            <AuthRoute path="/configurations/:id" exact>
              <CatalogConfigurationDetail />
            </AuthRoute>
            <AuthRoute path="/catalog/:id/versions/:version" exact>
              <VersionDetail />
            </AuthRoute>
            <AuthRoute path="/catalog/:id/versions/:version/releases/:release" exact>
              <ReleaseDetail />
            </AuthRoute>
            {/* END CATALOG */}
            <AuthRoute path="/profile">
              <Profile />
            </AuthRoute>
            <AuthRoute path="/orgs" exact>
              <OrgsList />
            </AuthRoute>
            <AuthRoute path="/products" exact>
              <ProductsList />
            </AuthRoute>
            <AuthRoute path="/licenses" exact>
              <Licenses />
            </AuthRoute>
            <AuthRoute path="/hw-keys" exact>
              <HwKeys />
            </AuthRoute>
            <AuthRoute path="/hw-keys/need-dlic" exact>
              <HwKeys needDlic={true} />
            </AuthRoute>
            <AuthRoute path="/hw-keys/:id" exact>
              <HwKeyDetail />
            </AuthRoute>
            <AuthRoute path="/products/:id" exact>
              <ProductDetail />
            </AuthRoute>
            <AuthRoute path="/pools/:id/:tab(licenses|logs)" exact>
              <LicensePoolDetail />
            </AuthRoute>
            <Redirect exact from="/pools/:id" to="/pools/:id/licenses" />
            <AuthRoute
              path="/orgs/:id/:tab(dlic-licenses|licenses-cloud|pools|admins|assistance-contract|assistance-contract-legacy|hw-keys|licenses|danger-zone)"
              exact
            >
              <OrgDetail />
            </AuthRoute>
            <AuthRoute path="/licenses/:id" exact>
              <LicenseDetailUvi />
            </AuthRoute>
            <AuthRoute path="/licenseconfig/:id" exact>
              <LicenseConfiguration />
            </AuthRoute>

            <AuthRoute path="/licenseconfig/:id/history" exact>
              <LicenseHistory />
            </AuthRoute>
            <AuthRoute path="/assistance-contract/:id" exact>
              <AssistanceContract />
            </AuthRoute>
            <Redirect exact from="/orgs/:id" to="/orgs/:id/dlic-licenses" />
            <AuthRoute redirectTest={isAdmin} path="/users" exact>
              <UsersList />
            </AuthRoute>
            <AuthRoute redirectTest={isAdmin} path="/users/:id" exact>
              <UserDetail />
            </AuthRoute>
            <AuthRoute redirectTest={isAdmin} path="/apps" exact>
              <AppsList />
            </AuthRoute>
            <AuthRoute redirectTest={isAdmin} path="/apps/:id" exact>
              <AppDetail />
            </AuthRoute>
            <MaybeAuthRoute path="/logout" exact>
              <Logout />
            </MaybeAuthRoute>
            <Redirect exact from="/" to="/apps" />
            <MaybeAuthRoute>
              <PageNotFound />
            </MaybeAuthRoute>
          </Switch>
        </Router>
      </Auth>
    </Suspense>
  )
}

export default App
