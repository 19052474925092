import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useOrganizationMerge } from '../../hooks/useOrganizations'
import { MergeOrgModal } from './MergeOrgModal'

export function DangerZone({ org }) {
  const history = useHistory()
  const { t } = useTranslation()
  const [mergeModalState, mergeModalActions] = useModalTrigger()

  const [{ pending: isMerging }, { run: mergeInto }] = useOrganizationMerge()

  return (
    <div className="mt-2">
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3 mb-5">{t('danger_zone')}</h1>
            <h3>{t('merge_orgs_title')}</h3>
            <p>{t('merge_orgs_message')}</p>
            <Button
              variant="outline-danger"
              onClick={() => {
                mergeModalActions.open('dummy')
              }}
              disabled={isMerging}
            >
              {isMerging ? t('merging_org') : t('merge_org')}
            </Button>
          </Col>
        </Row>
      </Container>
      {mergeModalState.value && (
        <MergeOrgModal
          isOpen={mergeModalState.isOpen}
          onCancel={mergeModalActions.close}
          onSubmit={(value) => {
            mergeModalActions.close()
            mergeInto
              .onSuccess((result) => {
                history.replace(`/orgs/${result.id}`)
              })
              .run(org.id, value)
          }}
          onClosed={mergeModalActions.onClosed}
        />
      )}
    </div>
  )
}
