import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import * as dayjs from 'dayjs'

export default function AssistanceContractsListOrg({ loading, contracts }) {
  const { t } = useTranslation()
  return (
    <Table loading={loading} responsive hover>
      <thead>
        <tr>
          <th>{t('product')}</th>
          <th>{t('activation_date')}</th>
          <th>{t('expiration_date')}</th>
          <th>{t('amount')}</th>
          <th>{t('notes')}</th>
          <th>{t('status')}</th>
        </tr>
      </thead>
      <tbody>
        {contracts &&
          contracts.map((contract) => (
            <tr key={contract.id}>
              <td>{contract.product}</td>
              <td>{dayjs(contract.activationDate).format('DD/MM/YYYY')}</td>
              <td>{dayjs(contract.expirationDate).format('DD/MM/YYYY')}</td>
              <td>{contract.amount} &euro;</td>
              <td width={'20%'}>{parse(contract.notes)}</td>
              <td>{contract.status}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
