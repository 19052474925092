import { Spinner } from 'react-bootstrap'
import Layout from '../Layout/Layout'

export default function PageSpinner() {
  return (
    <Layout>
      <div className="d-flex justify-content-center py-5">
        <Spinner animation="border" variant="primary" />
      </div>
    </Layout>
  )
}