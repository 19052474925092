import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ConfigurationsListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/catalog/configuration/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) =>
        api2.auth(token).post('/catalog/configuration', data),
      updater: (state) => state,
    }),
  },
  computed: {
    configurations: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfigurations(params) {
  return useRunRj(ConfigurationsListRj, [params], false)
}

const ConfigurationRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/catalog/configuration/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/configuration/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).delete(`/catalog/configuration/${id}`),
      updater: (state) => null,
    }),
  },
  computed: {
    configuration: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfiguration(id) {
  return useRunRj(ConfigurationRj, [id], true)
}

function useConfigurationsControlled() {
  return useRj(ConfigurationsListRj)
}


useConfigurations.controlled = useConfigurationsControlled