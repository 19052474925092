import { Modal } from 'react-bootstrap'
import { ArrowLeft } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

export default function ModalConfirm({
  isOpen,
  title,
  text,
  toggle,
  onExited,
  confirmBtn,
}) {
  const { t } = useTranslation()
  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          icon={<ArrowLeft />}
          onClick={toggle}
          variant="secondary"
          type="button"
        >
          {t('undo')}
        </Button>
        {confirmBtn}
      </Modal.Footer>
    </Modal>
  )
}
