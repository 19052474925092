import * as Yup from 'yup'
import { Field, FieldArray, Formik } from 'formik'
import {
  AppTypeaheadField,
  DateField,
  InputField,
  MetaField,
  transformErrorForForm,
} from '../Form'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { ArrowLeft, PlusCircleDotted, Pencil, Box } from 'react-bootstrap-icons'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  size: '',
  label: '',
  application: null,
  valid_to: null,
  valid_from: null,
  meta: [],
}

export default function LicensePoolModal({
  onSave,
  licensePool,
  isOpen,
  toggle,
  onExited,
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        size: Yup.number().required(
          t('form_required', {
            field: t('license_pool_size'),
          })
        ),
        application: Yup.mixed().required(
          t('form_required', {
            field: t('application'),
          })
        ),
        label: Yup.string(),
        valid_to: Yup.date()
          .required()
          .typeError(
            t('form_required', {
              field: t('license_pool_valid_to'),
            })
          ),
        valid_from: Yup.date()
          .required()
          .typeError(
            t('form_required', {
              field: t('license_pool_valid_from'),
            })
          ),
        meta: Yup.array().of(
          Yup.array(Yup.string().required(t('fill_meta_error')))
        ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={licensePool ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Box className="mr-2" />
                  {licensePool
                    ? t('edit_license_pool_title', {
                        label: licensePool.label,
                      })
                    : t('create_license_pool')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  label={t('application')}
                  required
                  disabled={!!licensePool}
                  name={licensePool ? 'application_data' : 'application'}
                  component={AppTypeaheadField}
                  placeholder={t('search_for_application')}
                />
                <Field
                  label={t('application_label')}
                  name="label"
                  component={InputField}
                />
                <Field
                  required
                  label={t('license_pool_size')}
                  name="size"
                  component={InputField}
                  type="number"
                />
                <Field
                  required
                  label={t('license_pool_valid_from')}
                  name="valid_from"
                  component={DateField}
                />
                <Field
                  required
                  label={t('license_pool_valid_to')}
                  name="valid_to"
                  component={DateField}
                />
                <div className="mb-2">{t('license_pool_meta')}</div>
                <FieldArray name="meta" component={MetaField} />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={
                    licensePool ? (
                      <Pencil size={18} />
                    ) : (
                      <PlusCircleDotted size={18} />
                    )
                  }
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {licensePool ? t('update') : t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
