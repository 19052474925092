import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  InputField,
  CheckboxField,
  transformErrorForForm,
} from '../../components/Form'
import { Modal } from 'react-bootstrap'
import Button from '../../components/Button'
import {
  ArrowLeft,
  Pencil,
  PersonFill,
  PlusCircleDotted,
} from 'react-bootstrap-icons'
import { useAuthUser } from 'use-eazy-auth'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const INITIAL_VALUES = {
  full_name: '',
  email: '',
  is_admin: false,
}

export default function UserModal({ user, onSave, isOpen, toggle, onExited }) {
  const { t } = useTranslation()
  const { user: loggedUser } = useAuthUser()

  const schema = useMemo(
    () =>
      Yup.object().shape({
        full_name: Yup.string().required(
          t('form_required', {
            field: t('user_name'),
          })
        ),
        email: Yup.string()
          .email(
            t('form_email', {
              field: t('user_email'),
            })
          )
          .required(
            t('form_required', {
              field: t('user_email'),
            })
          ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Formik
        validationSchema={schema}
        initialValues={user ?? INITIAL_VALUES}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <PersonFill className="mr-2" />
                  {user
                    ? t('edit_user_title', { email: user.email })
                    : t('create_user')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('user_name')}
                  name="full_name"
                  component={InputField}
                />
                <Field
                  required
                  label={t('user_email')}
                  type="email"
                  name="email"
                  component={InputField}
                />
                <Field
                  disabled={user?.id === loggedUser.id}
                  label={t('user_is_admin')}
                  name="is_admin"
                  type="switch"
                  id="is_admin_switch"
                  component={CheckboxField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                <Button
                  icon={
                    user ? <Pencil size={18} /> : <PlusCircleDotted size={18} />
                  }
                  variant="success"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {user ? t('update') : t('create')}
                </Button>
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
