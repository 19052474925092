import { rj, useRj, useRunRj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import { api2 } from '../api'

const OptionsListRj = rj(rjDebounce(), {
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/catalog/configurationoption/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) =>
        api2.auth(token).post('/catalog/configurationoption', data),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/configurationoption/${id}`, data),
      updater: (state, result) => ({
        ...state,
        data: state.data.map((item) => (item.id !== result.id ? item : result)),
      }),
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2
          .auth(token)
          .mapResponse(() => id)
          .delete(`/catalog/configurationoption/${id}`),
      updater: (state, result) => ({
        ...state,
        data: state.data.filter((item) => item.id !== result),
      }),
    }),
  },
  computed: {
    options: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfigurationsOptions(params) {
  return useRunRj(OptionsListRj, [params], false)
}

function useOptionsControlled() {
  return useRj(OptionsListRj)
}

useConfigurationsOptions.controlled = useOptionsControlled

const OptionRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/catalog/configurationoption/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/configurationoption/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).delete(`/catalog/configurationoption/${id}`),
      updater: (state) => null,
    }),
  },
  computed: {
    option: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfigurationsOption(id) {
  return useRunRj(OptionRj, [id], true)
}
