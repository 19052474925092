import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LANGUAGE_CODES } from './consts'

i18n
  .use(LanguageDetector)
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',

    supportedLngs: LANGUAGE_CODES,

    debug: process.env.NODE_ENV !== 'production',

    react: {
      // NOTE: Malific i18next internal
      // if you want the language to ALWAYS suspense when
      // set 'languageChanged languageChanging'
      // othrwise comment to have the default 'languageChanged'
      bindI18n: 'languageChanged languageChanging',
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
