import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  CheckCircle,
  PlusCircleDotted,
  Sliders,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { CheckboxField, InputField, TextAreaField, transformErrorForForm } from '../Form'
import { OptionTypeaheadField } from '../OptionTypeahead'

const INITIAL_VALUES = {
  product_level: undefined,
  option: undefined,
  bit: null,
  notes: '',
  enabled_by_default: false
}

export function ProductLevelOptionModal({
  onSave,
  isOpen,
  toggle,
  onClosed,
  option = INITIAL_VALUES,
  action = 'edit',
  legacyMode = false,
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        product_level: Yup.number().required(
          t('form_required', {
            field: t('product_level'),
          })
        ),
        option: Yup.number().required(
          t('form_required', {
            field: t('option'),
          })
        ),
        bit: Yup.number().notRequired().nullable(),
        notes: Yup.string().notRequired(),
      }),
    [t]
  )

  const optionsFilter = useMemo(
    () => ({
      legacy_mode: legacyMode,
    }),
    [legacyMode]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={option}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
            actions.setSubmitting(false)
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Sliders className="mr-2" />
                  {t('edit_product_level_option')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('product_level')}
                  name="product_level_name"
                  component={InputField}
                  disabled
                />
                <Field
                  required
                  label={t('option')}
                  name="option"
                  component={OptionTypeaheadField}
                  defaultFilter={optionsFilter}
                />
                {legacyMode && (
                  <Field
                    required
                    label={t('bit')}
                    name="bit"
                    type="number"
                    step={1}
                    component={InputField}
                  />
                )}
                <Field
                  label={t('notes')}
                  name="notes"
                  component={TextAreaField}
                  rows={4}
                />
                <Field
                  label={t('enabled_by_default')}
                  name="enabled_by_default"
                  component={CheckboxField}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button
                    icon={<PlusCircleDotted size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button
                    icon={<CheckCircle size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
