import magikApi from 'magik-api'

// Configure your API Backend
const api = magikApi()
  // Django trailing slash
  .trailingSlash(true)
  .baseUrl('/api/v1')
  .authHeaders((token) => ({
    Authorization: `Token ${token}`,
  }))

export const api2 = magikApi()
  // Django trailing slash
  .trailingSlash(true)
  .baseUrl('/api/v2')
  .authHeaders((token) => ({
    Authorization: `Token ${token}`,
  }))

export default api
