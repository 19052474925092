import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ProductLevelListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/catalog/productlevel/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) =>
        api2.auth(token).post('/catalog/productlevel', data),
      updater: (state, result) => ({
        ...state,
        data: state.data.concat([result]),
      }),
    }),
    duplicate: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).post(`/catalog/productlevel/${id}/duplicate`),
      updater: (state, result) => ({
        ...state,
        data: state.data.concat([result]),
      }),
    }),
  },
  computed: {
    levels: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProductLevels(params) {
  return useRunRj(ProductLevelListRj, [params], false)
}

function useProductLevelsControlled() {
  return useRj(ProductLevelListRj)
}

useProductLevels.controlled = useProductLevelsControlled

const ProductLevelRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/catalog/productlevel/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/productlevel/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).delete(`/catalog/productlevel/${id}`),
      updater: (state) => state,
    }),
  },
  computed: {
    level: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProductLevel(id) {
  return useRunRj(ProductLevelRj, [id], true)
}
