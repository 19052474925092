import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const VersionReleasesListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/releases/release/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post('/releases/release', data),
      updater: (state) => state,
    }),
  },
  computed: {
    releases: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useVersionReleases(params) {
  return useRunRj(VersionReleasesListRj, [params], false)
}

function useVersionReleasesControlled() {
  return useRj(VersionReleasesListRj)
}

useVersionReleases.controlled = useVersionReleasesControlled

const VersionReleaseRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/releases/release/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => api2.auth(token).patch(`/releases/release/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).delete(`/releases/release/${id}`),
      updater: (state) => state,
    }),
  },
  computed: {
    release: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useVersionRelease(id) {
  return useRunRj(VersionReleaseRj, [id], true)
}

function useVersionReleaseControlled() {
  return useRj(VersionReleaseRj)
}

useVersionRelease.controlled = useVersionReleaseControlled
