import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ProductListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/catalog/product/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) =>
        api2.auth(token).post('/catalog/product', data),
      updater: (state) => state,
    }),
  },
  computed: {
    products: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProducts(params) {
  return useRunRj(ProductListRj, [params], false)
}

function useProductsControlled() {
  return useRj(ProductListRj)
}

useProducts.controlled = useProductsControlled

const ProductRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/catalog/product/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/product/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).delete(`/catalog/product/${id}`),
      updater: (state) => null,
    }),
  },
  computed: {
    product: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProduct(id) {
  return useRunRj(ProductRj, [id], true)
}

function useProductControlled() {
  return useRj(ProductRj)
}

useProduct.controlled = useProductControlled
