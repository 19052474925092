import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  CheckCircle,
  PlusCircleDotted,
  Sliders,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import {
  InputField,
  SelectField,
  TextAreaField,
  transformErrorForForm,
} from '../Form'

const INITIAL_VALUES = {
  code: '',
  name: '',
  description: '',
}

export function ProductLevelAttributeModal({
  onSave,
  isOpen,
  toggle,
  onClosed,
  attribute = INITIAL_VALUES,
  action = 'edit',
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required(
          t('form_required', {
            field: t('code'),
          })
        ),
        name: Yup.string().required(
          t('form_required', {
            field: t('name'),
          })
        ),
        description: Yup.string().notRequired(),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={attribute}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
            actions.setSubmitting(false)
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Sliders className="mr-2" />
                  {t('create_available_attribute')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('product_level')}
                  name="product_level_name"
                  component={InputField}
                  disabled
                />
                <Field
                  required
                  label={t('code')}
                  name="code"
                  component={InputField}
                />
                <Field
                  required
                  label={t('name')}
                  name="name"
                  component={InputField}
                />
                <Field
                  required
                  label={t('data_type')}
                  name="data_type"
                  component={SelectField}
                  placeholder="Seleziona..."
                  options={[
                    { label: 'Stringa', value: 'string' },
                    { label: 'Numerico', value: 'number' },
                    { label: 'Vero/falso', value: 'bool' },
                  ]}
                />
                <Field
                  label={t('description')}
                  name="description"
                  component={TextAreaField}
                  rows={4}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button
                    icon={<PlusCircleDotted size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button
                    icon={<CheckCircle size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
