import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { ProductLevelTypeaheadField } from '../../components/ProductLevelTypeahead'
import { ProductTypeaheadField } from '../../components/ProductTypeahead'

/**
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: any) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 * }} props
 * @returns
 */
export function CreateLicenseModal({ isOpen, onSubmit, onCancel, onClosed }) {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{ product: null, service_level: null }}
      onSubmit={(values, actions) => onSubmit(values)}
    >
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('manage_license')}</Modal.Header>
          <Modal.Body>
            <Form>
              <Field
                name="product"
                component={ProductTypeaheadField}
                label={t('product')}
              />
              {!!formik.values.product && (
                <Field
                  name="service_level"
                  component={ProductLevelTypeaheadField}
                  productId={formik.values.product}
                  label={t('product_level')}
                />
              )}
              {!formik.values.product && (
                <Field
                  name="service_level_x"
                  component={ProductLevelTypeaheadField}
                  productId={formik.values.product}
                  label={t('product_level')}
                  disabled
                />
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
