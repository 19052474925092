import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useState } from 'react'
import { Bullseye, PatchCheck, Pencil, PersonFill, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { deps, useRunRj } from 'react-rocketjump'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import LicensesList from '../../components/LicensesList'
import ModalConfirm from '../../components/ModalConfirm'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { LicensesListState } from '../../state/license'
import { UserDetailState } from '../../state/users'
import UserModal from './UserModal'

export default function UserDetail() {
  const { t } = useTranslation()
  const { user: loggedUser } = useAuthUser()
  const history = useHistory()
  const { id } = useParams()
  const [
    { user, error },
    { deleteUser, updateUser, clearUserTokens },
  ] = useRunRj(UserDetailState, [id])

  const [editModal, editModalActions] = useModalTrigger()
  const [rmModal, rmModalActions] = useModalTrigger()
  const [clsTokensModal, clsTokensModalActions] = useModalTrigger()

  const [licensesRawFilters, setLicensesRawFilters] = useState({
    page: 1,
    application: null,
    valid: '',
  })
  const licensesFilters = useMemoCompare(
    user
      ? {
          ...licensesRawFilters,
          application: licensesRawFilters.application?.id ?? '',
          user_id: user.id,
        }
      : deps.maybe()
  )
  const [{ licenses, loading, pagination }] = useRunRj(
    LicensesListState,
    [licensesFilters],
    false
  )

  if (error) {
    return <PageError error={error} />
  }

  if (user === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <PersonFill size={31} className="mr-2" /> {user.email}
          </h2>
          <div>
            <Button
              disabled={loggedUser.id === user.id}
              onClick={() => clsTokensModalActions.open()}
              className="mr-2"
              size="sm"
              variant="outline-primary"
              icon={<Bullseye />}
            >
              {t('clear_tokens')}
            </Button>
            <Button
              onClick={() => editModalActions.open()}
              className="mr-2"
              size="sm"
              variant="outline-primary"
              icon={<Pencil />}
            >
              {t('edit_user')}
            </Button>
            <Button
              disabled={loggedUser.id === user.id}
              onClick={() => rmModalActions.open()}
              size="sm"
              variant="outline-danger"
              icon={<Trash />}
            >
              {t('delete_user')}
            </Button>
          </div>
        </div>
        <Detail className="p-3">
          <Detail.Row label={t('user_email')} value={user.email} />
          <Detail.Row label={t('user_name')} value={user.full_name} />
          <Detail.Row label={t('user_is_admin')} value={user.is_admin} />
        </Detail>

        <div className="border-bottom mt-2 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <PatchCheck className="mr-2" /> {t('licenses_cloud')}
          </h3>
        </div>

        <LicensesList
          showOrg
          loading={loading}
          licenses={licenses}
          pagination={pagination}
          currentPage={licensesRawFilters.page}
          goToPage={(page) =>
            setLicensesRawFilters((filters) => ({ ...filters, page }))
          }
          filters={licensesRawFilters}
          onFiltersChange={(filters) =>
            setLicensesRawFilters((prev) => ({
              ...prev,
              ...filters,
              page: 1,
            }))
          }
        />
      </div>
      <ModalConfirmDelete
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        title={t('delete_user_title', { email: user.email })}
        text={t('delete_user_text', { email: user.email })}
        onExited={rmModalActions.onClosed}
        onDelete={() => {
          deleteUser.onSuccess(() => history.push('/users')).run(user)
        }}
      />
      <ModalConfirm
        isOpen={clsTokensModal.isOpen}
        toggle={clsTokensModalActions.toggle}
        title={t('clear_tokens_title', { email: user.email })}
        text={t('clear_tokens_text', { email: user.email })}
        onExited={clsTokensModalActions.onClosed}
        confirmBtn={
          <Button
            onClick={() =>
              clearUserTokens.onSuccess(clsTokensModalActions.close).run(user)
            }
            variant="outline-primary"
          >
            {t('clear')}
          </Button>
        }
      />
      <UserModal
        onSave={(user) =>
          updateUser.onSuccess(editModalActions.close).asPromise(user)
        }
        user={user}
        isOpen={editModal.isOpen}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
      />
    </Layout>
  )
}
