import { rj, useRunRj } from 'react-rocketjump'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { api2 } from '../api'

const LicenseListRj = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    effectCaller: 'configured',
    effect: (token) => (params) => {
      return api2.auth(token).get('/license/license/', params)
    },
    mutations: {
      create: rj.mutation.single({
        effect: (token) => (data) => api2.auth(token).post('/license/license/', data),
        updater: (state) => state,
      }),
      remove: rj.mutation.single({
        effect: (token) => (id) => api2.auth(token).delete(`/license/license/${id}/`),
        updater: (state) => state,
      }),
    },
    computed: {
      licenses: 'getList',
      loading: 'isPending',
      error: 'getError',
      pagination: 'getPagination',
    },
  }
)

export function useLicenses(params) {
  return useRunRj(LicenseListRj, [params], false)
}

const LicenseRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/license/license/${id}/details`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (licenseId, data) => api2.auth(token).patch(`/license/license/${licenseId}`, data),
      updater: (state, result) => {
        return { ...state, data: { ...state.data, ...result } }
      },
    }),
    revoke: rj.mutation.single({
      effect: (token) => (licenseId) => api2.auth(token).delete(`/license/license/${licenseId}`),
      updater: (state) => state,
    }),
    grantOption: rj.mutation.single({
      effect: (token) => (licenseId, data) =>
        api2.auth(token).post(`/license/license-option/`, { ...data, license: licenseId }),
      updater: (state, result) => {
        return {
          ...state,
          data: { ...state.data, options: [...state.data.options, result] },
        }
      },
    }),
    editOption: rj.mutation.single({
      effect: (token) => (optionId, data) => api2.auth(token).patch(`/license/license-option/${optionId}`, data),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            options: state.data.options.map((opt) => (opt.id !== result.id ? opt : result)),
          },
        }
      },
    }),
    revokeOption: rj.mutation.single({
      effect: (token) => (optionId) =>
        api2
          .auth(token)
          .mapResponse(() => optionId)
          .delete(`/license/license-option/${optionId}`),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            options: state.data.options.filter((opt) => opt.id !== result),
          },
        }
      },
    }),
    grantConfiguration: rj.mutation.single({
      effect: (token) => (licenseId, data) =>
        api2.auth(token).post(`/license/license-configuration/`, {
          ...data,
          license: licenseId,
        }),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            configurations: [...state.data.configurations, { ...result, options: [] }],
          },
        }
      },
    }),
    revokeConfiguration: rj.mutation.single({
      effect: (token) => (confId) =>
        api2
          .auth(token)
          .mapResponse(() => confId)
          .delete(`/license/license-configuration/${confId}`),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            configurations: state.data.configurations.filter((conf) => conf.id !== result),
          },
        }
      },
    }),
    grantConfigurationOption: rj.mutation.single({
      effect: (token) => (confId, data) =>
        api2.auth(token).post(`/license/license-configuration-option/`, {
          ...data,
          configuration_license: confId,
        }),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            configurations: state.data.configurations.map((conf) => {
              if (conf.id !== result.configuration_license) {
                return conf
              } else {
                return { ...conf, options: [...conf.options, result] }
              }
            }),
          },
        }
      },
    }),
    editConfigurationOption: rj.mutation.single({
      effect: (token) => (confOptId, data) =>
        api2.auth(token).patch(`/license/license-configuration-option/${confOptId}`, data),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            configurations: state.data.configurations.map((conf) => {
              if (conf.id !== result.configuration_license) {
                return conf
              } else {
                return {
                  ...conf,
                  options: conf.options.map((opt) => (opt.id !== result.id ? opt : result)),
                }
              }
            }),
          },
        }
      },
    }),
    revokeConfigurationOption: rj.mutation.single({
      effect: (token) => (confOptId) =>
        api2
          .auth(token)
          .mapResponse(() => confOptId)
          .delete(`/license/license-configuration-option/${confOptId}`),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            configurations: state.data.configurations.map((conf) => {
              const newOptions = conf.options.filter((opt) => opt.id !== result)
              if (newOptions.length < conf.options.length) {
                return { ...conf, options: newOptions }
              } else {
                return conf
              }
            }),
          },
        }
      },
    }),
    assignToKey: rj.mutation.single({
      effect: (token) => (licenseId, data) =>
        api2.auth(token).post(`/license/license-assignation/`, {
          ...data,
          license: licenseId,
        }),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            assignations: [...state.data.assignations, result],
          },
        }
      },
    }),
    editAssignation: rj.mutation.single({
      effect: (token) => (assignationId, data) =>
        api2.auth(token).patch(`/license/license-assignation/${assignationId}/`, data),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            assignations: state.data.assignations.map((assign) => (assign.id !== result.id ? assign : result)),
          },
        }
      },
    }),
    removeFromKey: rj.mutation.single({
      effect: (token) => (assignationId) =>
        api2
          .auth(token)
          .mapResponse(() => assignationId)
          .delete(`/license/license-assignation/${assignationId}/`),
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            assignations: state.data.assignations.filter((assign) => assign.id !== result),
          },
        }
      },
    }),
  },
  computed: {
    license: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useLicense(id) {
  return useRunRj(LicenseRj, [id], true)
}

const LicenseOemHistoryRj = rj({
  effectCaller: 'configured',
  effect: (token) => (licenseId) => {
    return api2.auth(token).get(`/license/license/${licenseId}/oem_history/`)
  },
})

export function useLicenseOemHistory(licenseId) {
  return useRunRj(LicenseOemHistoryRj, [licenseId], false)
}

const LicenseHistoryRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/license/license/${id}/history`)
  },
  computed: {
    history: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useLicenseHistory(id) {
  return useRunRj(LicenseHistoryRj, [id], true)
}
