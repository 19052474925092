import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const AppsListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    }),
    rjDebounce()
  )
  .mutations({
    deleteApp: {
      effect: (token) => (app) =>
        api
          .auth(token)
          .mapResponse(() => app)
          .delete(`/admin/application/${app.id}`),
      optimisticUpdater: 'deleteItem',
      optimisticResult: (app) => app,
    },
    createApp: {
      effect: (token) => (app) => {
        const formData = new FormData()
        Object.keys(app).forEach(key => {
          if (key === 'background_image') {
            if (app[key] instanceof File) {
              formData.append(key, app[key])
            }
          } else {
            formData.append(key, app[key] ?? '')
          }
        })
        return api.auth(token).post(`/admin/application`, formData)
      },
      updater: (s) => s,
    },
    updateApp: {
      effect: (token) => (app) => {
        const formData = new FormData()
        Object.keys(app).forEach(key => {
          if (key === 'background_image') {
            if (app[key] instanceof File) {
              formData.append(key, app[key])
            } else if (app[key] === null || app[key] === '') {
              formData.append(key, '')
            }
          } else {
            formData.append(key, app[key] ?? '')
          }
        })
        return api.auth(token).patch(`/admin/application/${app.id}`, formData)
      },
      updater: 'updateItem',
    },
  })
  .computed({
    apps: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'AppsList',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      api.auth(token).get('/admin/application', params),
  })

export const AppDetailState = rj()
  .mutations({
    generateSecret: {
      effect: (token) => (secret) =>
        api.auth(token).post('/admin/application-secret', secret),
      updater: (state, secret) => ({
        ...state,
        data: {
          ...state.data,
          secrets: state.data.secrets.concat(secret),
        },
      }),
    },
    deleteSecret: {
      effect: (token) => (secret) =>
        api
          .auth(token)
          .mapResponse(() => secret)
          .delete(`/admin/application-secret/${secret.id}`),
      optimisticUpdater: (state, secret) => ({
        ...state,
        data: {
          ...state.data,
          secrets: state.data.secrets.filter((s) => s.id !== secret.id),
        },
      }),
      optimisticResult: (secret) => secret,
    },
    updateSecret: {
      effect: (token) => (secret) =>
        api.auth(token).put(`/admin/application-secret/${secret.id}`, secret),
      updater: (state, secret) => ({
        ...state,
        data: {
          ...state.data,
          secrets: state.data.secrets.map((s) =>
            s.id === secret.id ? secret : s
          ),
        },
      }),
    },
    deleteApp: {
      effect: (token) => (app) =>
        api.auth(token).delete(`/admin/application/${app.id}`),
      updater: (s) => s,
    },
    updateApp: {
      effect: (token) => (app) => {
        const formData = new FormData()
        Object.keys(app).forEach(key => {
          if (key === 'background_image') {
            if (app[key] instanceof File) {
              formData.append(key, app[key])
            } else if (app[key] === null || app[key] === '') {
              formData.append(key, '')
            }
          } else {
            formData.append(key, app[key] ?? '')
          }
        })
        return api.auth(token).patch(`/admin/application/${app.id}`, formData)
      },
      updater: 'updateData',
    },
  })
  .computed({
    app: 'getData',
    error: 'getError',
  })
  .effect({
    name: 'AppDetail',
    effectCaller: 'configured',
    effect: (token) => (id) => api.auth(token).get(`/admin/application/${id}`),
  })
