import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { ConfigurationTypeahead } from '../../components/ConfigurationTypeahead'

/**
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: number) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 * }} props
 * @returns
 */
export function PickConfigurationModal({ isOpen, onSubmit, onCancel, onClosed }) {
  const { t } = useTranslation()
  const [configurationId, setConfigurationId] = useState(null)
  const [isSaving, setSaving] = useState(false)

  return (
    <Modal show={isOpen} onExited={onClosed}>
      <Modal.Header>{t('add_configuration')}</Modal.Header>
      <Modal.Body>
        <ConfigurationTypeahead
          value={configurationId}
          onChange={(val) => setConfigurationId(val.id)}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          icon={<ArrowLeft />}
          onClick={onCancel}
          variant="secondary"
          disabled={isSaving}
          type="button"
        >
          {t('undo')}
        </Button>
        <Button
          icon={<Check />}
          variant="success"
          disabled={isSaving}
          onClick={() => {
            if (configurationId) {
              setSaving(true)
              const result = onSubmit(configurationId)
              if (result.finally) {
                result.finally(() => setSaving(false))
              }
            }
          }}
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
