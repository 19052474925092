import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useTranslation } from 'react-i18next'
import { useRunRj } from 'react-rocketjump'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { ProductsListState } from '../../state/products'

export default function ProductsList() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [
    { products, loading, error },
  ] = useRunRj(ProductsListState, [debQueryParams], false)

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
              })
            }
          />
        </div>
        <Table loading={loading} responsive hover>
          <thead>
            <tr>
              <th>{t('id')}</th>
              <th>{t('product')}</th>
              <th>{t('website_product')}</th>
            </tr>
          </thead>
          <tbody>
            {products &&
              products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/products/${product.product}`}>
                      {product.id}
                    </Link>
                  </td>
                  <td>{product.product}</td>
                  <td>
                    {product.websiteProductId}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  )
}
