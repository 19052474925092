import { getIn } from 'formik'
import keyBy from 'lodash/keyBy'
import { useEffect, useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useConfigurationsOptions } from '../../hooks/useConfigurationsOptions'

export function ConfigurationOptionTypeahead({
  value,
  name = '',
  id = 'configuration-option-typeahead',
  className,
  onChange,
  ...props
}) {
  const [{ options, loading }, { run }] = useConfigurationsOptions.controlled()
  const valueObj = value
    ? options?.find((item) => item.id === value)
    : undefined
  const selected = valueObj ? [valueObj] : []
  const allOptionsIndex = useRef([])

  useEffect(
    () =>
      run
        .onSuccess((result) => {
          allOptionsIndex.current = keyBy(result, 'id')
        })
        .run(),
    [run]
  )

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run.withMeta({ debounced: true }).run({ search })
      }}
      isLoading={loading}
      options={options ?? []}
      labelKey={(opt) => `${opt.name} (${opt.code})`}
      minLength={0}
      onSearch={(search) => run({ search })}
      id={id}
      {...props}
    />
  )
}

export function ConfigurationOptionTypeaheadField({
  field,
  label,
  form,
  required = false,
  ...props
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <ConfigurationOptionTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => form.setFieldValue(field.name, value?.id ?? '')}
        value={field.value}
        id={`option-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}
