import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ChangelogsRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/releases/changelog/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post('/releases/changelog/', data),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => api2.auth(token).patch(`/releases/changelog/${id}`, data),
      updater: (state, result) => {
        return { ...state, data: state?.data?.map((item) => (item.id === result.id ? result : item)) }
      },
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).delete(`/releases/changelog/${id}`),
      updater: (state) => state,
    }),
  },
  computed: {
    changelogs: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useChangelogs(params) {
  return useRunRj(ChangelogsRj, [params], false)
}

function useChangelogsControlled() {
  return useRj(ChangelogsRj)
}

useChangelogs.controlled = useChangelogsControlled
