import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm } from '../../components/Form'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { useRj } from 'react-rocketjump'
import { ResetState } from '../../state/password'
import ddxLogo from '../../assets/ddx_logo.png'
import { useMemo } from 'react'

export default function PasswordReset() {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string().required(
          t('form_required', {
            field: 'Password',
          })
        ),
        password_repeat: Yup.string()
          .required(
            t('form_required', {
              field: 'repeat_password',
            })
          )
          .oneOf([Yup.ref('password')], t('passowrd_match_error')),
      }),
    [t]
  )
  const { token } = useParams()
  const [{ data: resetData }, { run: reset }] = useRj(ResetState)

  return (
    <Formik
      initialValues={{
        password: '',
        password_repeat: '',
      }}
      validationSchema={schema}
      onSubmit={({ password }, actions) =>
        reset.asPromise({ password, token }).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit} className="row mt-5">
          <div className="col-md-4 offset-md-4">
            <div className="card">
              <div className="card-header">
                <h1 className="land">
                  <img src={ddxLogo} alt="ddx logo" height={25} />
                  <span className="ml-2">XAuth</span>
                </h1>
              </div>
              <div className="mt-2 card-body">
                {resetData && (
                  <div className="alert alert-success">
                    {t('password_resetted')}
                    <br />
                    {t('password_resetted_go_to')}{' '}
                    {resetData.redirect_to ? (
                      <a className="link-inherit" href={resetData.redirect_to}>
                        <b>login</b>
                      </a>
                    ) : (
                      <Link to="/login" className="link-inherit">
                        <b>login</b>
                      </Link>
                    )}
                    .
                  </div>
                )}
                {errors.token && (
                  <div className="alert alert-danger">
                    {t('password_reset_invalid_link')}
                    <br />
                    {t('reset_password_request_link')}{' '}
                    <Link className="link-inherit" to="/account/recover">
                      <b>{t('reset_password_request_link_here')}</b>
                    </Link>
                    .
                  </div>
                )}
                {!errors.token && !resetData && (
                  <>
                    <p>{t('reset_password')}</p>
                    <Field
                      required
                      label="Password"
                      name="password"
                      type="password"
                      component={InputField}
                    />
                    <Field
                      required
                      label={t('repeat_password')}
                      name="password_repeat"
                      type="password"
                      component={InputField}
                    />
                    <button className="btn btn-primary" disabled={isSubmitting}>
                      {t('reset_password_btn')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
