import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  Cart,
  CheckCircle,
  PlusCircleDotted,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { InputField, TextAreaField, transformErrorForForm } from '../Form'

const INITIAL_VALUES = {
  name: '',
  machine_model: '',
  description: '',
}

export function ConfigurationModal({
  onSave,
  isOpen,
  toggle,
  onClosed,
  configuration = INITIAL_VALUES,
  action = 'edit',
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        machine_model: Yup.string().required(
          t('form_required', {
            field: t('machine_model'),
          })
        ),
        name: Yup.string().required(
          t('form_required', {
            field: t('name'),
          })
        ),
        description: Yup.string().notRequired(),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={configuration}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Cart className="mr-2" />
                  {t('create_product')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('name')}
                  name="name"
                  component={InputField}
                />
                <Field
                  required
                  label={t('machine_model')}
                  name="machine_model"
                  component={InputField}
                />
                <Field
                  label={t('description')}
                  name="description"
                  component={TextAreaField}
                  rows={4}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button
                    icon={<PlusCircleDotted size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button
                    icon={<CheckCircle size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
