import { Alert } from 'react-bootstrap'
import Layout from '../Layout'
import { LifePreserver } from 'react-bootstrap-icons'
import { useAuthState } from 'use-eazy-auth'
import { useTranslation } from 'react-i18next'

function NotFoundContent() {
  const { t } = useTranslation()
  return (
    <div className="container-fluid pt-4">
      <Alert variant="danger">
        <Alert.Heading className="text-center">
          <LifePreserver size={40} className="mr-3" />
          {t('page_not_found_title')}
        </Alert.Heading>
        <p className="text-center mt-4">{t('page_not_found_text')}</p>
      </Alert>
    </div>
  )
}

export default function PageNotFound() {
  const { authenticated } = useAuthState()
  if (authenticated) {
    return (
      <Layout>
        <NotFoundContent />
      </Layout>
    )
  }
  return <NotFoundContent />
}
