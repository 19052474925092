import { Link, useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Key } from 'react-bootstrap-icons'
import PageSpinner from '../../components/PageSpinner'
import * as dayjs from 'dayjs'
import Layout from '../../components/Layout'
import Detail from '../../components/Detail'
import { useLicenseUviDetail } from '../../state/license-uvicorn'
import { Button, Spinner } from 'react-bootstrap'
import { BitmaskModal } from '../../components/BitmaskModal'
import { useState } from 'react'
import { ModalConvertLicense } from '../../components/ModalConvertLicense/ModalConvertLicense'
import { ModalRenewLicense } from '../../components/ModalRenewLicense/ModalRenewLicense'

export default function LicenseDetailUvi() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [{ license }, { convertIntoLicense, renewLicense, run }] = useLicenseUviDetail(id)
  const [optionsModal, optionsModalActions] = useModalTrigger()
  const [modalConvert, optionsModalConvert] = useModalTrigger()
  const [modalRenew, optionsModalRenew] = useModalTrigger()
  const [loadingConvert, setLoadingConvert] = useState(false)
  const [errorConvert, setErrorConvert] = useState(null)
  const [errorRenew, setErrorRenew] = useState(null)
  const [loadingRenew, setLoadingRenew] = useState(false)


  const history = useHistory()

  if (license === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <Key className="mr-2" size={31} /> {license.id} -{' '}
            {license.businessName}
          </h2>
          <div className='text-right'>
            {loadingConvert && <Spinner animation="border" variant="primary" size="sm" className="mr-3" />}
            <Button
              size="md"
              variant="outline-primary"
              onClick={() => {
                optionsModalRenew.open(license)
              }}
              className="mr-2"
            >
              {t('renew_license')}
            </Button>
            <Button
              size="md"
              variant="outline-primary"
              onClick={() => {
                optionsModalConvert.open(license)
              }}

              className="mr-2"
            >

              {t('convert_in_the_new_format')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={license.id} />
            <Detail.Row
              label={t('key_number')}
              value={
                <Link
                  className="font-weight-bold"
                  to={`/hw-keys/${license.keyNumber}`}
                >
                  {license.keyNumber}
                </Link>
              }
            />
            <Detail.Row
              label={t('customer')}
              value={
                <Link
                  className="font-weight-bold"
                  to={`/orgs/${license.customerId}`}
                >
                  {license.businessName}
                </Link>
              }
            />
            <Detail.Row
              label={t('activation_date')}
              value={dayjs(license.activationDate).format('DD/MM/YYYY')}
            />
            <Detail.Row
              label={t('expiration_date')}
              value={dayjs(license.expirationDate).format('DD/MM/YYYY')}
            />
            {license?.jobs_license?.length > 0 && <Detail.Row 
              label={t('renovations')}
              value={
                <>
                  {license?.jobs_license.map(job =>
                    <div>{dayjs(job?.date_renewed).format('DD/MM/YYYY')} - <small>
                      {t('expiration')} (
                        {dayjs(job?.expiration_date).format('DD/MM/YYYY')}
                      )
                      </small></div>
                  )}
                </>
              }
            />}

            <Detail.Row label={t('key_code')} value={
              license.keyCode ? license.keyCode : 
              <span className='text-danger'>{
                license?.jobs_license?.length > 0 ? 
                  license.jobs_license.map(job =>
                    <span key={job.id}>{job.error}</span>
                  )
                : t('no_key_code')
              }</span>
            } />
            <Detail.Row
              label={t('product_code')}
              value={license.productCodeId}
            />
            <Detail.Row
              label={t('product')}
              value={
                <Link
                  className="font-weight-bold"
                  to={`/products/${license.productId}`}
                >
                  {license.productId}
                </Link>
              }
            />
            <Detail.Row label={t('product_code_name')} value={license.name} />
            <Detail.Row label={t('level')} value={license.level} />
            <Detail.Row label={t('version')} value={license.version} />
            <Detail.Row label={t('options')} value={
              <>
                <span>
                  {license.options}
                </span>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => optionsModalActions.open(license.options_bitmask)}
                  className="ml-2"
                >
                  {t('show_options')}
                </Button>
              </>
            } />
            <Detail.Row
              label={t('notes')}
              value={license.notes ? parse(license.notes) : ''}
            />
            <Detail.Row
              label={t('applications_number')}
              value={license.applicationsNumber}
            />
            <Detail.Row
              label={t('machine_number')}
              value={
                <Link
                  className="font-weight-bold"
                  to={`/machines-models/${license.machineNumber}`}
                >
                  {license.machineNumber}
                </Link>
              }
            />
          </Detail>
        </div>
      </div>
      <ModalRenewLicense
        isOpen={modalRenew.isOpen}
        toggle={() => {
          optionsModalRenew.close()
        }}
        error={errorRenew}
        loading={loadingRenew}
        onClosed={optionsModalRenew.onClosed}
        license={modalRenew.value}
        onSubmit={(values) => {
          setLoadingRenew(true)
          renewLicense.onSuccess((newLicense) => {
            setLoadingRenew(false)
            run(id)
            optionsModalRenew.close()
          }).onFailure(
            (err) => {
              setErrorRenew(err)
            })
            .asPromise(id, {
              expiration_date: values.expiration_date,
            })
        }
        }
      />

      <ModalConvertLicense
        isOpen={modalConvert.isOpen}
        toggle={() => {
          optionsModalConvert.close()
          setErrorConvert(null)
        }}
        error={errorConvert}
        loading={loadingConvert}
        onClosed={optionsModalConvert.onClosed}
        license={modalConvert.value}
        onSubmit={(values) => {
          setLoadingConvert(true)
          convertIntoLicense.onSuccess((newLicense) => {
            history.push(`/licenseconfig/${newLicense.license}`)
          }).onFailure(
            (err) => {
              setErrorConvert(err)
            })
            .asPromise(id, {
              level: values.level,
              product: license.id_product
            })
        }
        }
      />
      <BitmaskModal
        isOpen={optionsModal.isOpen}
        toggle={optionsModalActions.close}
        onClosed={optionsModalActions.onClosed}
        options={optionsModal.value}
      />
    </Layout>
  )
}
