import { Children, cloneElement } from 'react'
import { Check, X } from 'react-bootstrap-icons'

function DetailRow({
  value,
  label,
  className = '',
  rowTitle = false,
  ...props
}) {
  if (rowTitle) {
    return (
      <div className={`${className} row py-2`} {...props}>
        <div className='col-md-12 text-center'>
          <h5 className='mb-0'>{value}</h5>
        </div>
      </div>
    )
  }
  return (
    <div className={`${className} row`} {...props}>
      <div className="col-md-4 text-right p-2 bg-light border border-white">
        <b>{label}</b>
      </div>
      <div className="col-md-8 p-2">
        {typeof value === 'boolean' ? (
          value ? (
            <Check size={25} className="text-success" />
          ) : (
            <X size={25} className="text-danger" />
          )
        ) : (
          value
        )}
      </div>
    </div>
  )
}

Detail.Row = DetailRow

export default function Detail({ children, ...props }) {
  return (
    <div {...props}>
      {Children.map(children, (child, i) => {
        if (!child) {
          return child
        }
        if (i > 0) {
          return cloneElement(child, {
            className: `${child.props.className ?? ''}`,
          })
        } else {
          return cloneElement(child)
        }
      })}
    </div>
  )
}
