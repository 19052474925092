import { rj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ConfigurationAvailableOptionsListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2
      .auth(token)
      .get('/catalog/configurationavailableoption/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) =>
        api2.auth(token).post('/catalog/configurationavailableoption', data),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2.auth(token).patch(`/catalog/configurationavailableoption/${id}`, data),
      updater: (state, result) => ({
        ...state,
        data: state.data.map((item) => (item.id !== result.id ? item : result)),
      }),
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2
          .auth(token)
          .mapResponse(() => id)
          .delete(`/catalog/configurationavailableoption/${id}`),
      updater: (state, result) => ({
        ...state,
        data: state.data.filter((item) => item.id !== result),
      }),
    }),
  },
  computed: {
    options: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfigurationAvailableOptions(params) {
  return useRunRj(ConfigurationAvailableOptionsListRj, [params], false)
}

const ConfigurationAvailableOptionRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/catalog/configurationavailableoption/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) =>
        api2
          .auth(token)
          .patch(`/catalog/configurationavailableoption/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) =>
        api2.auth(token).delete(`/catalog/configurationavailableoption/${id}`),
      updater: (state) => null,
    }),
  },
  computed: {
    option: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useConfigurationAvailableOption(id) {
  return useRunRj(ConfigurationAvailableOptionRj, [id], true)
}
