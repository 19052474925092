import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ReleaseFilesRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/releases/release-file/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post('/releases/release-file/', data),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => api2.auth(token).patch(`/releases/release-file/${id}`, data),
      updater: (state, result) => {
        return { ...state, data: state?.data?.map((item) => (item.id === result.id ? result : item)) }
      },
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).delete(`/releases/release-file/${id}`),
      updater: (state) => state,
    }),
    bulkUpload: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post(`/releases/release-file/bulk-upload/`, data),
      updater: (state) => state,
    }),
  },
  computed: {
    files: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useReleaseFiles(params) {
  return useRunRj(ReleaseFilesRj, [params], false)
}

function useReleaseFilesControlled() {
  return useRj(ReleaseFilesRj)
}

useReleaseFiles.controlled = useReleaseFilesControlled
