import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, CheckCircle, Command, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { CheckboxField, InputField, transformErrorForForm } from '../Form'

const INITIAL_VALUES = {
  label: '',
  release_nr: '',
  visible: false,
}

export function VersionReleaseModal({ onSave, isOpen, toggle, onClosed, release = INITIAL_VALUES, action = 'edit' }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string().required(
          t('form_required', {
            field: t('product_release_label'),
          })
        ),
        release_nr: Yup.string()
          .required(
            t('form_required', {
              field: t('product_release_nr'),
            })
          ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={release}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Command className="mr-2" />
                  {t(`${action}_product_release`)}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field required label={t('product_release_label')} name="label" component={InputField} />
                <Field required label={t('product_release_nr')} name="release_nr" component={InputField} />
                <Field label={t('product_release_visible')} name="visible" component={CheckboxField} />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" disabled={isSubmitting} type="button">
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button icon={<PlusCircleDotted size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button icon={<CheckCircle size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
