import { Table as BsTable, Spinner } from 'react-bootstrap'

export default function Table({ loading = false, ...props }) {
  return (
    <div className="position-relative">
      {loading && (
        <div style={{ position: 'absolute', right: 0, top: 5 }}>
          <Spinner size={'sm'} variant="primary" animation="border" />
        </div>
      )}
      <BsTable {...props} />
    </div>
  )
}
