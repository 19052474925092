import useRouterQueryParam from 'magik-react-hooks/useRouterQueryParam'
import { useEffect } from 'react'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import apiUvicorn from '../../apiUvicorn'

export default function Login() {
  const [token] = useRouterQueryParam('token')
  const { loginLoading, loginError } = useAuthState()
  const { login } = useAuthActions()

  useEffect(() => {
    if (!loginLoading && !loginError) {
      if (token) {
        login({ token })
      } else {

        apiUvicorn
          .get('/sso-start', { redirect_url: `${window.location.origin}${window.location.pathname}`})
          .toPromise()
          .then((result) => {
            window.location.href = result.url
          })
      }
    }
  }, [login, loginError, loginLoading, token])

  return null
}
