import magikApi from 'magik-api'
import { API_URL_UVICORN } from './consts'

// Configure your API Backend
const apiUvicorn = magikApi()
  // Django trailing slash
  //.trailingSlash(true)
  .baseUrl(API_URL_UVICORN)
  .authHeaders((token) => ({
    Authorization: `Token ${token}`,
  }))

export default apiUvicorn
