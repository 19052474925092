import { getIn } from 'formik'
import keyBy from 'lodash/keyBy'
import { useEffect, useRef, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useHardwareKeysOwned } from '../hooks/useOwnedKeys'

export function OwnedHwKeyTypeahead({
  value,
  name = '',
  id = 'owned-hw-key-typeahead',
  className,
  onChange,
  filters = {},
  ...props
}) {
  const [{ keys, loading }, { run }] = useHardwareKeysOwned.controlled()
  const valueObj = value ? keys?.find((item) => item.id === value) : undefined
  const selected = valueObj ? [valueObj] : []
  const allOptionsIndex = useRef([])

  const [stableFilters] = useState(filters)

  useEffect(
    () =>
      run
        .onSuccess((result) => {
          allOptionsIndex.current = keyBy(result, 'id')
        })
        .run(stableFilters),
    [run, stableFilters]
  )

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run
          .withMeta({ debounced: true })
          .run({ ...stableFilters, hardware_key__number__icontains: search })
      }}
      isLoading={loading}
      options={keys ?? []}
      labelKey={(opt) => `${opt.key_number}`}
      minLength={0}
      onSearch={(search) =>
        run({ ...stableFilters, hardware_key__number__icontains: search })
      }
      id={id}
      {...props}
    />
  )
}

export function OwnedHwKeyTypeaheadField({
  field,
  label,
  form,
  required = false,
  ...props
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <OwnedHwKeyTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => form.setFieldValue(field.name, value?.id ?? '')}
        value={field.value}
        id={`owned-hw-key-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}
