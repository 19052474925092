import { Card, Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { Key, Clipboard, Trash, Pencil } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'

export default function SecretCard({ secret, onDelete, onEdit }) {
  const { t } = useTranslation()
  function copyToClip() {
    if (typeof navigator.clipboard?.writeText === 'function') {
      try {
        navigator.clipboard.writeText(secret.secret)
      } catch (err) {}
    }
  }

  return (
    <Card className="mb-1">
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Key size={20} className="mr-2" />
          <h5 className="m-0">{secret.name}</h5>
        </div>
        <div>
          <Button
            onClick={() => onEdit(secret)}
            size="sm"
            className="mr-3"
            variant="outline-primary"
            icon={<Pencil />}
          />
          <Button
            onClick={() => onDelete(secret)}
            size="sm"
            variant="outline-danger"
            icon={<Trash />}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div>
          <Row>
            <Col>
              <InputGroup style={{ maxWidth: 500 }}>
                <FormControl
                  title={secret.secret}
                  readOnly
                  value={secret.secret}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={copyToClip}>
                    <Clipboard />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col>
              <p className="font-weight-bold">{t('permissions')}</p>
              <ul>
                {secret.authorizations.map((auth) => (
                  <li key={auth}>{t(`permission_${auth}`)}</li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}
