import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Alert, Table } from 'react-bootstrap'
import { Alt, Bag, Toggles } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { deps } from 'react-rocketjump'
import Button from '../../components/Button'
import { useProductLevelOptions } from '../../hooks/useProductLevelOptions'
import { ManageOptionModal } from './ManageOptionModal'

export function Options({ license, grantOption, editOption, revokeOption }) {
  const { t } = useTranslation()

  const productLevelFilter = useMemo(
    () => ({
      product_level: license.service_level,
    }),
    [license.service_level]
  )

  const [{ options, loading: loadingOptions, error }] = useProductLevelOptions(
    deps.maybe(productLevelFilter)
  )

  const [manageModalState, manageModalActions] = useModalTrigger()

  if (error) {
    return <Alert color="danger">Impossibile mostrare le opzioni</Alert>
  }

  return (
    <>
      <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
        <h3 className="m-0 d-flex align-items-center">
          <Alt className="mr-2" /> {t('dlic_license_options')}
        </h3>
      </div>
      <div className="mt-4 px-3">
        <Table
          loading={loadingOptions}
          responsive
          hover
          className="table-layout-fixed"
        >
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('option')}</th>
              <th style={{ width: '20%' }}>{t('status')}</th>
              <th style={{ width: '45%' }}>{t('dlic_license_expire_date')}</th>
              <th style={{ width: '15%' }}></th>
            </tr>
          </thead>
          <tbody>
            {options &&
              sortBy(options, 'option_name').map((availOption) => {
                const activation = license.options.find(
                  (licenseOption) => licenseOption.option === availOption.option
                )
                let license_type = ''
                if (activation) {
                  if (activation.license_type === 'perpetual') {
                    license_type = t('dlic_license_perpetual')
                  }
                  if (activation.license_type === 'expire') {
                    license_type = dayjs(activation.expire_date).format(
                      'DD/MM/YYYY'
                    )
                  }
                }
                return (
                  <tr key={availOption.id}>
                    <td>{availOption.option_name}</td>
                    <td>{!!activation ? t('active') : t('not_active')}</td>
                    <td>{license_type}</td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        className="mr-2"
                        icon={<Toggles />}
                        onClick={() => {
                          if (activation) {
                            revokeOption(activation.id)
                          } else {
                            grantOption(license.id, {
                              option: availOption.option,
                              attributes: {},
                              license_type: 'perpetual',
                              expire_date: null,
                            })
                          }
                        }}
                      />
                      <Button
                        size="sm"
                        variant="outline-primary"
                        icon={<Bag />}
                        onClick={() => {
                          manageModalActions.open({
                            optionName: availOption.option_name,
                            isActive: !!activation,
                            licenseType:
                              activation?.license_type ?? 'perpetual',
                            expireDate: activation?.expire_date ?? '',
                            activationId: activation?.id,
                            optionId: availOption.option,
                          })
                        }}
                      >
                        {t('manage')}
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {manageModalState.value && (
          <ManageOptionModal
            isOpen={manageModalState.isOpen}
            onCancel={manageModalActions.close}
            onClosed={manageModalActions.onClosed}
            data={manageModalState.value}
            onSubmit={(values) => {
              const prevValues = manageModalState.value
              const currValues = values
              let result = Promise.resolve()
              if (prevValues.isActive && !currValues.isActive) {
                result = revokeOption.asPromise(prevValues.activationId)
              }
              if (!prevValues.isActive && currValues.isActive) {
                result = grantOption.asPromise(license.id, {
                  option: prevValues.optionId,
                  attributes: {},
                  license_type: currValues.licenseType,
                  expire_date: currValues.expireDate || null,
                })
              }
              if (prevValues.isActive && currValues.isActive) {
                result = editOption.asPromise(prevValues.activationId, {
                  license_type: currValues.licenseType,
                  expire_date: currValues.expireDate || null,
                })
              }
              return result.then((arg) => {
                manageModalActions.close()
                return arg
              })
            }}
          />
        )}
      </div>
    </>
  )
}
