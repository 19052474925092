import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { ConfigurationModal } from '../../components/ConfigurationModal'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { useConfigurations } from '../../hooks/useConfigurations'

export function ConfigurationList() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [{ configurations, loading, error }, { run: loadContent, create }] =
    useConfigurations(debQueryParams)

  const [createModalState, createModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
              })
            }
          />
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createModalActions.open('dummy')}
            icon={<PlusCircleDotted size={15} />}
          >
            {t('create_configuration')}
          </Button>
        </div>
        <Table
          loading={loading}
          responsive
          hover
          className="table-layout-fixed"
        >
          <thead>
            <tr>
              <th style={{ width: '30%' }}>{t('configuration')}</th>
              <th style={{ width: '70%' }}>{t('machine_model')}</th>
            </tr>
          </thead>
          <tbody>
            {configurations &&
              configurations.map((conf) => (
                <tr key={conf.id}>
                  <td>
                    <Link
                      className="font-weight-bold"
                      to={`/configurations/${conf.id}`}
                    >
                      {conf.name}
                    </Link>
                  </td>
                  <td>{conf.machine_model}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {createModalState.value && (
        <ConfigurationModal
          isOpen={createModalState.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClosed}
          onSave={(data) => {
            return create
              .onSuccess(() => {
                loadContent(debQueryParams)
                createModalActions.close()
              })
              .asPromise(data)
          }}
          action="create"
        />
      )}
    </Layout>
  )
}
