import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useTranslation } from 'react-i18next'
import { useRunRj } from 'react-rocketjump'
import Layout from '../../components/Layout'
import LicensesListOrgUvi from '../../components/LicensesListOrgUvi'
import PageError from '../../components/PageError'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import { LicensesUvicornState } from '../../state/license-uvicorn'

export default function Licenses() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const { t } = useTranslation()

  const [{ licensesUvi, loading, error, pagination }] = useRunRj(
    LicensesUvicornState,
    [debQueryParams],
    false
  )

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 row">
          <div className="col-md-3">
            <Search
              value={queryParams.search ?? ''}
              onChangeValue={(search) =>
                setDebQueryParams({
                  search,
                  page: 1,
                  expired: queryParams.expired ?? '',
                })
              }
              onClear={() =>
                setQueryParams({
                  search: '',
                  page: 1,
                  expired: '',
                })
              }
            />
          </div>
          <div className="col-md-3">
            <select
              value={queryParams.expired ?? ''}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDebQueryParams({
                    search: queryParams.search ?? '',
                    page: 1,
                    expired: e.target.value,
                  })
                } else {
                  setDebQueryParams({
                    search: queryParams.search ?? '',
                    page: 1,
                    expired: '',
                  })
                }
              }}
              name="expired"
              className="form-control"
            >
              <option value={''}>{t('filter_licenses')}</option>
              <option value={0}>{t('actives')}</option>
              <option value={1}>{t('licenses_expired')}</option>
            </select>
          </div>
        </div>
        <LicensesListOrgUvi
          showCustomer
          licenses={licensesUvi || []}
          loading={loading}
        />
        <div style={{ width: '100vw', overflow: 'scroll' }}>
          <Paginator
            numPages={pagination.numPages}
            currentPage={+debQueryParams.page || 1}
            goToPage={(page) => setQueryParams({ page })}
          />
        </div>
      </div>
    </Layout>
  )
}
