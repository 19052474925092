import Layout from '../../components/Layout'
import { Card } from 'react-bootstrap'
import { ArrowLeft, PersonFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useAuthUser } from 'use-eazy-auth'
import { useRj } from 'react-rocketjump'
import { ChangePasswordState } from '../../state/password'
import ChangePasswordForm from './ChangePasswordForm'
import { Link, Route } from 'react-router-dom'
import Button from '../../components/Button'

function ProfileInfo() {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  return (
    <div>
      <div>{user.full_name}</div>
      <div>{user.email}</div>
      <div className="mt-2">
        <Button
          as={Link}
          variant="outline-primary"
          to="/profile/change-password"
        >
          {t('change_password')}
        </Button>
      </div>
    </div>
  )
}

export default function Profile() {
  const { t } = useTranslation()
  const [, { run: changePassword }] = useRj(ChangePasswordState)

  return (
    <Layout>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <Card>
              <Card.Header as={'h5'} className="d-flex align-items-center">
                <PersonFill className="mr-2" />
                {t('profile')}
              </Card.Header>
              <Card.Body>
                <Route path="/profile" exact>
                  <ProfileInfo />
                </Route>
                <Route path="/profile/change-password" exact>
                  <ChangePasswordForm
                    backBtn={
                      <Button
                        icon={<ArrowLeft />}
                        className="mr-3"
                        as={Link}
                        variant="secondary"
                        to="/profile"
                      >
                        {t('back')}
                      </Button>
                    }
                    onSave={(pass) => changePassword.asPromise(pass)}
                  />
                </Route>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  )
}
