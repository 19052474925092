import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { XCircleFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

export default function Search({
  value,
  onChangeValue,
  onClear,
  className = '',
  placeholder,
}) {
  const { t } = useTranslation()
  return (
    <InputGroup className={`${className} search-container`}>
      <FormControl
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        placeholder={placeholder ?? t('search')}
        aria-label="search"
        aria-describedby="clear-search"
      />
      <InputGroup.Append title="Clear search">
        <Button variant="outline-secondary" onClick={() => onClear()}>
          <XCircleFill size={15} style={{ marginBottom: 2 }} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}
