import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// import parse from 'html-react-parser'
import * as dayjs from 'dayjs'
import { Link } from 'react-router-dom'

export default function LicensesListOrgUvi({ loading, licenses, showCustomer = false }) {
  const { t } = useTranslation()
  return (
    <Table loading={loading} responsive hover>
      <thead>
        <tr>
          <th>{t('license_id')}</th>
          {showCustomer && <th>{t('customer')}</th>}
          <th>{t('key_number')}</th>
          <th>{t('activation_date')}</th>
          <th>{t('expiration_date')}</th>
          <th>{t('product')}</th>
          {/* <th>{t('product_code_name')}</th> */}
          <th>{t('expired')}</th>
          {/* <th>{t('notes')}</th> */}
        </tr>
      </thead>
      <tbody>
        {licenses &&
          licenses.map((license) => {
            let status = t('active')
            if (license.expired) {
              status = t('expired')
            }
            if(!license.keyCode){
              status = t('no_key_code')
            }
            return (
              (
                <tr key={license.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/licenses/${license.id}`}>
                      {license.id}
                    </Link>
                  </td>
                  {showCustomer && (
                    <td width={'20%'}>
                      <Link to={`/licenses/${license.customerId}`}>{license.businessName}</Link>
                    </td>
                  )}
                  <td>
                    <Link to={`/hw-keys/${license.keyNumber}`}>{license.keyNumber}</Link>
                  </td>
                  <td>{dayjs(license.activationDate).format('DD/MM/YYYY')}</td>
                  <td>{dayjs(license.expirationDate).format('DD/MM/YYYY')}</td>
                  <td>
                    <Link to={`/products/${license.productId}`}>{license.productId}</Link>
                  </td>
                  {/* <td>{license.name}</td> */}
                  <td>{status}</td>
                  {/* <td width={'20%'}>{license.notes ? parse(license.notes) : ''}</td> */}
                </tr>
              )
            )
          })}
      </tbody>
    </Table>
  )
}
