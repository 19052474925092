import { getIn } from 'formik'
import { identity, mapValues } from 'lodash'
import keyBy from 'lodash/keyBy'
import { useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { deps, useRj, useRunRj } from 'react-rocketjump'
import { UserDetailState, UsersListState } from '../state/users'

export function UserTypeahead({
  value,
  name = '',
  id = 'org-typeahead',
  className,
  onChange,
  ...props
}) {
  const [{ users, loading }, { run }] = useRj(UsersListState)
  const [{ user }] = useRunRj(UserDetailState, [deps.maybe(value)])

  let options = users?.map((usr) => usr.id) ?? []
  const captions = mapValues(keyBy(users, 'id'), (u) =>
    [u.full_name, u.email].filter(identity).join(' - ')
  )

  if (user && !options.includes(user.id)) {
    options = [user.id].concat(options)
    captions[user.id] = [user.full_name, user.email]
      .filter(identity)
      .join(' - ')
  }

  const selected = value && user ? [value] : []

  useEffect(() => run(), [run])

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run.withMeta({ debounced: true }).run({ search })
      }}
      isLoading={loading}
      options={options ?? []}
      labelKey={(opt) => {
        return captions[opt]
      }}
      minLength={0}
      onSearch={(search) => run({ search })}
      id={id}
      {...props}
    />
  )
}

export function UserTypeaheadField({
  field,
  label,
  form,
  required = false,
  ...props
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <UserTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => form.setFieldValue(field.name, value ?? '')}
        value={field.value}
        id={`option-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}
