import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'
import Layout from '../Layout'
import { ConeStriped } from 'react-bootstrap-icons'
import PageNotFound from '../PageNotFound'

export default function PageError({ error }) {
  const { t } = useTranslation()
  const status = error.status
  if (status === 404) {
    return <PageNotFound />
  }
  return (
    <Layout>
      <div className="container-fluid pt-4">
        <Alert variant="danger">
          <Alert.Heading className="text-center">
            <ConeStriped size={40} />
            {t('server_error_title')}
          </Alert.Heading>
          <p className="text-center mt-4">
            {t('server_error_text', { status })}
          </p>
        </Alert>
      </div>
    </Layout>
  )
}
