import { range } from 'lodash'

export default function Paginator({ numPages, currentPage, goToPage, className = 'd-flex justify-contente-center' }) {
  let pageIntervalStart = 0
  let pageIntervalEnd = numPages - 1
  if (pageIntervalEnd - pageIntervalStart > 10) {
    pageIntervalEnd = Math.min(currentPage + 4, numPages - 1)
    pageIntervalStart = Math.max(0, currentPage - 5)
  }
  return (
    <nav aria-label="Paginator" className={className}>
      <ul className="pagination">
        <li className={`page-item pointer ${currentPage === null || currentPage === 1 ? 'disabled' : ''}`}>
          <span className="page-link" aria-label="Previous" onClick={() => goToPage(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </span>
        </li>
        {range(pageIntervalStart, pageIntervalEnd + 1).map((page) => (
          <li key={page} className={`page-item  pointer ${currentPage === page + 1 ? 'active' : ''} `}>
            <span onClick={() => goToPage(page + 1)} className="page-link">
              {page + 1}
            </span>
          </li>
        ))}
        <li className={`page-item pointer ${currentPage === null || !(currentPage < numPages) ? 'disabled' : ''}`}>
          <span className="page-link" aria-label="Next" onClick={() => goToPage(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </span>
        </li>
      </ul>
    </nav>
  )
}
