import { useRunRj } from 'react-rocketjump'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { Key, Puzzle } from 'react-bootstrap-icons'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import Layout from '../../components/Layout'
import Detail from '../../components/Detail'
import { ProductDetailState, ProductsCodesState } from '../../state/products'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'

export default function ProductDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [{ product, error }] = useRunRj(ProductDetailState, [id])

  const filterProduct = useMemo(() => {
    return { productId: id }
  }, [id])

  const [{ productsCodes, loading: loadingCodes }] = useRunRj(
    ProductsCodesState,
    [filterProduct],
    false
  )

  if (error) {
    return <PageError error={error} />
  }

  if (product === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <Puzzle className="mr-2" size={31} /> {product.product}
          </h2>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={product.id} />
            <Detail.Row label={t('product')} value={product.product} />
            <Detail.Row
              label={t('website_product')}
              value={product.websiteProductId}
            />
          </Detail>
        </div>
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Key className="mr-2" /> {t('products_code')}
          </h3>
        </div>
        <div className="mt-4 px-3">
          <Table loading={loadingCodes} responsive hover>
            <thead>
              <tr>
                <th>{t('code')}</th>
                <th>{t('name')}</th>
                <th>{t('options')}</th>
              </tr>
            </thead>
            <tbody>
              {productsCodes &&
                productsCodes.map((code) => (
                  <tr key={code.code}>
                    <td>{code.code}</td>
                    <td>{code.name}</td>
                    <td>
                      {code.options
                        ? parse(
                            code.options.replace(/(?:\r\n|\r|\n)/g, '<br />')
                          )
                        : ''}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  )
}
