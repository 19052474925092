import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useTranslation } from 'react-i18next'
import { useRunRj } from 'react-rocketjump'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { OrgsState } from '../../state/orgs-uvicorn'

export default function OrgsList() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const [
    { orgs, loading, error, pagination },
    // { deleteOrg, createOrg, updateOrg },
  ] = useRunRj(OrgsState, [debQueryParams], false)

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
                page: 1,
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
                page: 1,
              })
            }
          />
        </div>
        <Table loading={loading} responsive>
          <thead>
            <tr>
              <th>{t('organization_name')}</th>
              <th className="text-right pr-5">{/* <Gear /> */}</th>
            </tr>
          </thead>
          <tbody>
            {orgs &&
              orgs.map((org) => (
                <tr key={org.id}>
                  <td>
                    <Link className="font-weight-bold" to={`/orgs/${org.id}`}>
                      {org.businessName}
                    </Link>
                  </td>
                  <td className="text-right">
                    {/* {user.is_admin && (
                      <Button
                        disabled
                        onClick={() => editModalActions.open(org)}
                        icon={<Pencil />}
                        className="mr-2"
                        variant={'outline-primary'}
                        size="sm"
                      >
                        {t('edit_organization')}
                      </Button>
                    )}
                    {user.is_admin && (
                      <Button
                        onClick={() => rmModalActions.open(org)}
                        icon={<Trash />}
                        disabled
                        variant={'outline-danger'}
                        size="sm"
                      >
                        {t('delete_organization')}
                      </Button>
                    )} */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div style={{ width: '100vw', overflow: 'scroll' }}>
          <Paginator
            numPages={pagination.numPages}
            currentPage={+debQueryParams.page || 1}
            goToPage={(page) => setQueryParams({ page })}
          />
        </div>
      </div>
      {/* <ModalConfirmDelete
        onDelete={(app) => {
          deleteOrg(rmModal.value)
          rmModalActions.close()
        }}
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        onExited={rmModalActions.onClosed}
        title={
          rmModal.value
            ? t('delete_organization_title', { name: rmModal.value.name })
            : null
        }
        text={
          rmModal.value
            ? t('delete_organization_text', { name: rmModal.value.name })
            : null
        }
      />
      <OrgModal
        onSave={(org) =>
          createOrg
            .onSuccess((newOrg) => {
              history.push(`/orgs/${newOrg.id}`)
            })
            .asPromise({
              ...org,
              admins: [],
            })
        }
        isOpen={addModal.isOpen}
        toggle={addModalActions.toggle}
        onExited={addModalActions.onClosed}
      />
      <OrgModal
        onSave={(org) =>
          updateOrg.onSuccess(editModalActions.close).asPromise(org)
        }
        org={editModal.value}
        isOpen={editModal.isOpen}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
      /> */}
    </Layout>
  )
}
