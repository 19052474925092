import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import {
  DateField,
  InputField,
  SelectField,
  YesNoField,
} from '../../components/Form'

/**
 *
 * @typedef {Object} ManagableOption
 * @property {string} optionName
 * @property {string|undefined} configurationName
 * @property {bool} isActive
 * @property {"perpetual"|"expire"} licenseType
 * @property {string | null} expireDate
 *
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: ManagableOption) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 *    data: ManagableOption
 * }} props
 * @returns
 */
export function ManageOptionModal({
  isOpen,
  onSubmit,
  onCancel,
  onClosed,
  data,
}) {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={data}
      onSubmit={(values, actions) => onSubmit(values)}
    >
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('manage_option')}</Modal.Header>
          <Modal.Body>
            <Form>
              {data.configurationName && (
                <Field
                  name="configurationName"
                  disabled
                  component={InputField}
                  label={t('configuration')}
                />
              )}
              <Field
                name="optionName"
                disabled
                component={InputField}
                label={t('option')}
              />
              <Field
                name="isActive"
                disabled
                component={YesNoField}
                positive={t('active')}
                negative={t('not_active')}
                label={t('status')}
              />
              {formik.values.isActive && (
                <Field
                  name="licenseType"
                  component={SelectField}
                  options={[
                    { value: 'perpetual', label: t('dlic_license_perpetual') },
                    { value: 'expire', label: t('dlic_license_expiring') },
                  ]}
                  label={t('dlic_license_type')}
                />
              )}
              {formik.values.isActive &&
                formik.values.licenseType === 'expire' && (
                  <Field
                    name="expireDate"
                    component={DateField}
                    label={t('dlic_license_expire_date')}
                  />
                )}
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
