import { omit } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import {
  Alt,
  Cpu,
  Pencil,
  PlusCircleDotted,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import { ConfigurationModal } from '../../components/ConfigurationModal'
import { ConfigurationOptionModal } from '../../components/ConfigurationOptionModal'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { useConfigurationAvailableOptions } from '../../hooks/useConfigurationAvailableOptions'
import { useConfiguration } from '../../hooks/useConfigurations'

export function ConfigurationDetail() {
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const optionsFilter = useMemo(
    () => ({
      configuration: id,
    }),
    [id]
  )

  const [{ configuration, error }, { update, destroy }] = useConfiguration(id)

  const [
    { options, loading: optionsLoading, error: optionsError },
    { create: createOption, destroy: removeOption, update: updateOption },
  ] = useConfigurationAvailableOptions(optionsFilter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [optDelModalState, optDelModalActions] = useModalTrigger()
  const [createOptionModalState, createOptionModalActions] = useModalTrigger()

  if (error || optionsError) {
    return <PageError error={error || optionsError} />
  }

  if (configuration === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <h2 className="m-0 ml-3 d-flex align-items-center">
              <Cpu size={31} className="mr-2" />
              {configuration.name}
            </h2>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(configuration)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_configuration')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => delModalActions.open(configuration)}
              icon={<Trash size={15} />}
            >
              {t('delete_configuration')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={configuration.id} />
            <Detail.Row
              label={t('machine_model')}
              value={configuration.machine_model}
            />
            <Detail.Row
              label={t('description')}
              value={configuration.description}
            />
          </Detail>
        </div>

        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Alt className="mr-2" /> {t('available_options')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createOptionModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_available_option')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table
            loading={optionsLoading}
            hover
            responsive
            className="table-layout-fixed"
          >
            <thead>
              <tr>
                <th style={{ width: '15%' }}>{t('name')}</th>
                <th style={{ width: '18%' }}>{t('code')}</th>
                <th style={{ width: '27%' }}>{t('notes')}</th>
                <th style={{ width: '20%' }}></th>
              </tr>
            </thead>
            <tbody>
              {options &&
                options.map((opt) => (
                  <tr key={opt.id}>
                    <td>{opt.option_name}</td>
                    <td style={{ lineBreak: 'anywhere' }}>
                      {opt.option_code}
                      <br />
                      bit {opt.bit}
                    </td>
                    <td>{opt.notes}</td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => createOptionModalActions.open(opt)}
                        icon={<Pencil size={15} />}
                        className="mr-2"
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => optDelModalActions.open(opt)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <ModalConfirmDelete
        onDelete={() => {
          destroy(configuration.id)
          delModalActions.close()
          history.replace(`/configurations`)
        }}
        isOpen={delModalState.isOpen}
        toggle={delModalActions.toggle}
        onExited={delModalActions.onClosed}
        title={t('delete_configuration_title', {
          name: configuration.name,
        })}
        text={t('delete_configuration_text', {
          name: configuration.name,
        })}
      />
      {optDelModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            removeOption(optDelModalState.value.id)
            optDelModalActions.close()
          }}
          isOpen={optDelModalState.isOpen}
          toggle={optDelModalActions.toggle}
          onExited={optDelModalActions.onClosed}
          title={t('delete_configuration_option_title', {
            name: optDelModalState.value.option_name,
            conf: configuration.name,
          })}
          text={t('delete_configuration_option_text', {
            name: optDelModalState.value.option_name,
            conf: configuration.name,
          })}
        />
      )}
      {editModalState.value && (
        <ConfigurationModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          configuration={configuration}
          onClosed={editModalActions.onClosed}
          onSave={(result) => {
            update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(configuration.id, result)
          }}
        />
      )}

      {createOptionModalState.value && (
        <ConfigurationOptionModal
          isOpen={createOptionModalState.isOpen}
          toggle={createOptionModalActions.toggle}
          onClosed={createOptionModalActions.onClosed}
          option={{
            ...createOptionModalState.value,
            configuration: configuration.id,
            configuration_name: configuration.name,
          }}
          onSave={(result) => {
            const fn = result.id ? updateOption.curry(result.id) : createOption
            return fn
              .onSuccess(() => {
                createOptionModalActions.close()
              })
              .asPromise(omit(result, 'configuration_name'))
          }}
        />
      )}
    </Layout>
  )
}
