import { rj } from 'react-rocketjump'
import apiUvicorn from '../apiUvicorn'

export const ProductsListState = rj()
  .computed({
    products: 'getData',
    loading: 'isLoading',
  })
  .effect({
    name: 'ProductsList',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      apiUvicorn.auth(token).get(`/product`, params),
  })

export const ProductDetailState = rj()
  .computed({
    product: 'getData',
    error: 'getError',
  })
  .effect({
    name: 'UserDetail',
    effectCaller: 'configured',
    effect: (token) => (name) => apiUvicorn.auth(token).get(`/product/${name}`),
  })

export const ProductsCodesState = rj()
  .computed({
    productsCodes: 'getData',
    loading: 'isLoading',
  })
  .effect({
    name: 'ProductsCodesState',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      apiUvicorn.auth(token).get(`/product_code`, params),
  })

export const InstalledProductsState = rj()
  .computed({
    products: 'getData',
    loading: 'isLoading',
  })
  .effect({
    name: 'InstalledProductsState',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      apiUvicorn.auth(token).get(`/installed_product`, params),
  })
