import dayjs from 'dayjs'
import { pick } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo, useState } from 'react'
import { Cart, Cart4, ChatRightText, Eye, EyeSlash, Pencil, Toggles, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { deps, useRunRj } from 'react-rocketjump'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import { useAssistanceContract } from '../../hooks/useAssistanceContract'
import { useProducts } from '../../hooks/useProducts'
import { InstalledProductsState } from '../../state/products'
import { EditAssistanceContractModal } from './EditAssistanceContractModal'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

export function AssistanceContract() {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  const [{ contract }, { update, remove }] = useAssistanceContract(id)

  const filterLicensedTo = useMemo(() => {
    if (contract) {
      return {
        licensed_to: contract.customer,
      }
    } else {
      return null
    }
  }, [contract])

  const [{ products }] = useProducts(deps.maybe(filterLicensedTo))

  const filterCustomer = useMemo(() => {
    if (contract) {
      return {
        customerId: contract.customer,
      }
    } else {
      return null
    }
  }, [contract])

  const [{ products: productsLegacy }] = useRunRj(InstalledProductsState, [deps.maybe(filterCustomer)], false)

  const [productsDisplay, setProductsDisplay] = useState('included')
  const [legacyProductsDisplay, setLegacyProductsDisplay] = useState('included')

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <ChatRightText className="mr-2" size={31} /> {t('assistance_contract')}
          </h2>
          <div>
            <Button
              icon={<Pencil />}
              onClick={() => editModalActions.open(contract)}
              variant="outline-primary"
              type="button"
            >
              {t('edit_assistance_contract')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              className="ml-3"
              onClick={() => delModalActions.open(contract)}
              icon={<Trash size={15} />}
            >
              {t('delete')}
            </Button>
          </div>
        </div>

        {contract && (
          <div className="mt-3">
            <Detail className="col-md-8">
              <Detail.Row label={t('id')} value={contract.id} />
              <Detail.Row
                label={t('customer')}
                value={<Link to={`/orgs/${contract.customer}/assistance-contract`}>{contract.customer_name}</Link>}
              />
              <Detail.Row label={t('from_date')} value={dayjs(contract.from_date).format('DD/MM/YYYY')} />
              <Detail.Row label={t('to_date')} value={dayjs(contract.to_date).format('DD/MM/YYYY')} />
              <Detail.Row label={t('status')} value={t('assistance_contract_status_' + contract.status)} />
              {contract.status === 'suspended' && (
                <Detail.Row label={t('suspension_reason')} value={contract.inactive_reason} />
              )}
              <Detail.Row label={t('notes')} value={contract.notes} />
            </Detail>

            {products && (
              <div className="mt-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h3 className="m-0 d-flex align-items-center mb-3">
                    <Cart className="mr-2" size={31} /> {t('assistance_contract_included_products')}
                  </h3>
                  {productsDisplay === 'all' && (
                    <Button
                      icon={<EyeSlash />}
                      onClick={() => setProductsDisplay('included')}
                      variant="outline-primary"
                      type="button"
                    >
                      {t('show_included')}
                    </Button>
                  )}
                  {productsDisplay === 'included' && (
                    <Button
                      icon={<Eye />}
                      onClick={() => setProductsDisplay('all')}
                      variant="outline-primary"
                      type="button"
                    >
                      {t('show_all')}
                    </Button>
                  )}
                </div>
                <Detail className="col-md-8">
                  {products
                    .filter((product) => productsDisplay === 'all' || contract.products.includes(product.id))
                    .map((product) => {
                      const isActive = contract.products.includes(product.id)
                      return (
                        <Detail.Row
                          key={product.id}
                          label={product.name}
                          value={
                            <div className="d-flex flex-row justify-content-start align-items-center">
                              <div style={{ width: 100 }}>{isActive ? t('included') : ''}</div>
                              <Button
                                size="sm"
                                variant="outline-primary"
                                className="mr-2"
                                icon={<Toggles />}
                                onClick={() => {
                                  if (isActive) {
                                    update(contract.id, {
                                      products: contract.products.filter((productId) => productId !== product.id),
                                    })
                                  } else {
                                    update(contract.id, {
                                      products: [...contract.products, product.id],
                                    })
                                  }
                                }}
                              />
                            </div>
                          }
                        />
                      )
                    })}
                </Detail>
              </div>
            )}

            {productsLegacy && (
              <div className="mt-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h3 className="m-0 d-flex align-items-center mb-3">
                    <Cart4 className="mr-2" size={31} /> {t('assistance_contract_included_legacy_products')}
                  </h3>
                  {legacyProductsDisplay === 'all' && (
                    <Button
                      icon={<EyeSlash />}
                      onClick={() => setLegacyProductsDisplay('included')}
                      variant="outline-primary"
                      type="button"
                    >
                      {t('show_included')}
                    </Button>
                  )}
                  {legacyProductsDisplay === 'included' && (
                    <Button
                      icon={<Eye />}
                      onClick={() => setLegacyProductsDisplay('all')}
                      variant="outline-primary"
                      type="button"
                    >
                      {t('show_all')}
                    </Button>
                  )}
                </div>
                <Detail className="col-md-8">
                  {productsLegacy
                    .filter(
                      (product) =>
                        legacyProductsDisplay === 'all' || contract.legacy_products.includes(product.productId)
                    )
                    .map((product) => {
                      const isActive = contract.legacy_products.includes(product.productId)
                      return (
                        <Detail.Row
                          key={product.id}
                          label={product.productId}
                          value={
                            <div className="d-flex flex-row justify-content-start align-items-center">
                              <div style={{ width: 100 }}>{isActive ? t('included') : ''}</div>
                              <Button
                                size="sm"
                                variant="outline-primary"
                                className="mr-2"
                                icon={<Toggles />}
                                onClick={() => {
                                  if (isActive) {
                                    update(contract.id, {
                                      legacy_products: contract.legacy_products.filter(
                                        (productId) => productId !== product.productId
                                      ),
                                    })
                                  } else {
                                    update(contract.id, {
                                      legacy_products: [...contract.legacy_products, product.productId],
                                    })
                                  }
                                }}
                              />
                            </div>
                          }
                        />
                      )
                    })}
                </Detail>
              </div>
            )}
          </div>
        )}

        {editModalState.value && (
          <EditAssistanceContractModal
            contract={editModalState.value}
            isOpen={editModalState.isOpen}
            onClosed={editModalActions.onClosed}
            onCancel={editModalActions.close}
            onSubmit={(values) => {
              return update
                .onSuccess(() => {
                  editModalActions.close()
                })
                .asPromise(contract.id, pick(values, 'from_date', 'to_date', 'is_active', 'inactive_reason', 'notes'))
            }}
          />
        )}
        {delModalState.value && (
          <ModalConfirmDelete
            onDelete={() => {
              remove
                .onSuccess(() => {
                  delModalActions.close()
                  history.goBack()
                })
                .run(delModalState.value.id)
            }}
            isOpen={delModalState.isOpen}
            toggle={delModalActions.toggle}
            onExited={delModalActions.onClosed}
            title={t('delete_assistance_contract_title', {
              id: delModalState.value.id,
            })}
            text={t('delete_assistance_contract_text', {
              id: delModalState.value.id,
            })}
          />
        )}
      </div>
    </Layout>
  )
}
