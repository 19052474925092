import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import {
  Cart,
  Check,
  ChevronRight,
  Command,
  Git,
  Paperclip,
  Pencil,
  PlusCircleDotted,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { ProductVersionModal } from '../../components/ProductVersionModal'
import { ReleaseFileUploadModal } from '../../components/ReleaseFileUploadModal'
import { VersionReleaseModal } from '../../components/VersionReleaseModal'
import { useProductFiles } from '../../hooks/useProductFiles'
import { useProductVersion } from '../../hooks/useProductVersion'
import { useVersionReleases } from '../../hooks/useVersionReleases'

export function VersionDetail() {
  const { version: id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const releasesFilter = useMemo(
    () => ({
      version: id,
    }),
    [id]
  )

  const attachmentsFilter = useMemo(
    () => ({
      valid_versions: id,
    }),
    [id]
  )

  const [{ version, error }, { update, destroy, copyProductFiles }] = useProductVersion(id)
  const [{ releases, error: releasesError }, { run: reloadReleases, create: createRelease }] =
    useVersionReleases(releasesFilter)
  const [{ files, error: filesError }, { run: reloadFiles, bulkUpload, update: updateFile }] =
    useProductFiles(attachmentsFilter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [createReleaseModalState, createReleaseModalActions] = useModalTrigger()
  const [createFileModalState, createFileModalActions] = useModalTrigger()
  const [deleteFileModalState, deleteFileModalActions] = useModalTrigger()

  if (error || releasesError || filesError) {
    return <PageError error={error || releasesError || filesError} />
  }

  if (version === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <h2 className="m-0">
              <Link className="d-flex aling-items-center" to={`/catalog/${version.product}`}>
                <Cart className="mr-2" size={31} />
                {version.product_name}
              </Link>
            </h2>
            <ChevronRight className="ml-3" size={20} />
            <h2 className="m-0 ml-3 d-flex align-items-center">
              <Git size={31} className="mr-2" />
              {version.label}
            </h2>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(version)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_product_version')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => delModalActions.open(version)}
              icon={<Trash size={15} />}
            >
              {t('delete_product_version')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={version.id} />
            <Detail.Row label={t('product_version_label')} value={version.label} />
            <Detail.Row label={t('product_version_nr')} value={version.version_nr} />
          </Detail>
        </div>
        {/* RELEASES */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Command className="mr-2" /> {t('version_releases')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createReleaseModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_version_release')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '20%' }}>{t('product_release_label')}</th>
                <th style={{ width: '20%' }}>{t('product_release_nr')}</th>
                <th style={{ width: '10%' }}>{t('product_release_visible')}</th>
                <th style={{ width: '50%' }}></th>
              </tr>
            </thead>
            <tbody>
              {releases &&
                releases.map((release) => (
                  <tr key={release.id}>
                    <td>
                      <Link
                        className="font-weight-bold"
                        to={`/catalog/${version.product}/versions/${version.id}/releases/${release.id}`}
                      >
                        {release.label}
                      </Link>
                    </td>
                    <td>{release.release_nr}</td>
                    <td>{release.visible ? <Check /> : null}</td>
                    <td className="text-right"></td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {/* END RELEASES */}

        {/* FILES */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Paperclip className="mr-2" /> {t('product_files')}
          </h3>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() =>
                copyProductFiles
                  .onSuccess(() => {
                    reloadFiles(attachmentsFilter)
                  })
                  .run(version.id)
              }
              icon={<Paperclip size={15} />}
              className="mr-2"
            >
              {t('copy_product_files')}
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => createFileModalActions.open({})}
              icon={<PlusCircleDotted size={15} />}
              className="mr-2"
            >
              {t('create_product_file')}
            </Button>
          </div>
        </div>
        <div className="mt-4 px-3">
          <Table hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '50%' }}>{t('product_file_name')}</th>
                <th style={{ width: '50%' }}></th>
              </tr>
            </thead>
            <tbody>
              {files &&
                files.map((file) => (
                  <tr key={file.id}>
                    <td>
                      <a href={file.file} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => deleteFileModalActions.open(file)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {/* END FILES */}
      </div>
      <ModalConfirmDelete
        onDelete={() => {
          const product = version.product
          destroy
            .onSuccess(() => {
              delModalActions.close()
              history.replace(`/catalog/${product}`)
            })
            .run(version.id)
        }}
        isOpen={delModalState.isOpen}
        toggle={delModalActions.toggle}
        onExited={delModalActions.onClosed}
        title={t('delete_product_version_title', {
          name: version.label,
        })}
        text={t('delete_product_version_text', {
          name: version.label,
        })}
      />
      {editModalState.value && (
        <ProductVersionModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          version={version}
          onClosed={editModalActions.onClosed}
          onSave={(result) => {
            update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(version.id, result)
          }}
        />
      )}
      {createReleaseModalState.value && (
        <VersionReleaseModal
          isOpen={createReleaseModalState.isOpen}
          toggle={createReleaseModalActions.toggle}
          onClosed={createReleaseModalActions.onClosed}
          onSave={(result) => {
            return createRelease
              .onSuccess(() => {
                createReleaseModalActions.close()
                reloadReleases(releasesFilter)
              })
              .asPromise({ ...result, version: version.id })
          }}
        />
      )}
      {createFileModalState.value && (
        <ReleaseFileUploadModal
          isOpen={createFileModalState.isOpen}
          toggle={createFileModalActions.toggle}
          onClosed={createFileModalActions.onClosed}
          onSave={(result) => {
            const fd = new FormData()
            for (const [i, file] of result.entries()) {
              fd.append(`files[${i}]product`, version.product)
              fd.append(`files[${i}]file`, file.file)
              fd.append(`files[${i}]name`, file.name)
              fd.append(`files[${i}]valid_versions`, version.id)
            }
            return bulkUpload
              .onSuccess(() => {
                createFileModalActions.close()
                reloadFiles(attachmentsFilter)
              })
              .asPromise(fd)
          }}
        />
      )}
      {deleteFileModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            updateFile
              .onSuccess(() => {
                deleteFileModalActions.close()
                reloadFiles(attachmentsFilter)
              })
              .run(deleteFileModalState.value.id, {
                valid_versions: deleteFileModalState.value.valid_versions.filter((x) => x !== version.id),
              })
          }}
          isOpen={deleteFileModalState.isOpen}
          toggle={deleteFileModalActions.toggle}
          onExited={deleteFileModalActions.onClosed}
          title={t('delete_product_file_title', {
            name: deleteFileModalState.value.name,
          })}
          text={t('delete_product_file_text', {
            name: deleteFileModalState.value.name,
          })}
        />
      )}
    </Layout>
  )
}
