import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ProductFilesRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/releases/product-file/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post('/releases/product-file/', data),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => api2.auth(token).patch(`/releases/product-file/${id}`, data),
      updater: (state, result) => {
        return { ...state, data: state?.data?.map((item) => (item.id === result.id ? result : item)) }
      },
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).delete(`/releases/product-file/${id}`),
      updater: (state) => state,
    }),
    bulkUpload: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post(`/releases/product-file/bulk-upload/`, data),
      updater: (state) => state,
    }),
  },
  computed: {
    files: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProductFiles(params) {
  return useRunRj(ProductFilesRj, [params], false)
}

function useProductFilesControlled() {
  return useRj(ProductFilesRj)
}

useProductFiles.controlled = useProductFilesControlled
