import { Modal } from 'react-bootstrap'
import { ArrowLeft, Save2, Save2Fill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { Field, Formik } from 'formik'
import { ProductLevelTypeaheadField } from '../ProductLevelTypeahead'
import * as Yup from 'yup'

export function ModalConvertLicense({ isOpen, toggle, onClosed, license, onSubmit, loading, error }) {

    const Schema = Yup.object().shape({
        level: Yup.string().required('Required'),
    })

    console.log(error, 'error')

    const { t } = useTranslation()
    return (
        <Modal show={isOpen} onHide={toggle} onExited={onClosed} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('convert_license')}{' '}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    level: '',
                }}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
                validationSchema={Schema}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <>
                            <Modal.Body>
                                {error && <div className="alert alert-danger">{error?.response?.error}</div>}

                                <div className="form-group">
                                    <label htmlFor="level">{t('level')}</label>
                                    <Field
                                        id="level"
                                        name="level"
                                        component={ProductLevelTypeaheadField}
                                        productId={license.id_product}
                                    />
                                </div>


                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-between">
                                <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" type="button">
                                    {t('close')}
                                </Button>
                                <Button
                                    icon={loading ? <Save2Fill /> : <Save2 />}
                                    type="submit"
                                    disabled={loading}
                                    variant="primary"
                                    onClick={handleSubmit}
                                >

                                    {t('convert_in_the_new_format')}
                                </Button>
                            </Modal.Footer>
                        </>
                    </form>
                )}
            </Formik>


        </Modal>
    )
}
