import { Modal } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

export default function ModalAlert({ isOpen, title, text, toggle, onExited }) {
  const { t } = useTranslation()
  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={toggle} icon={<Check size={18} />} variant="info" type="submit">
          {t('got_it')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
