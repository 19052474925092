import { useRunRj } from 'react-rocketjump'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Key, Pencil, Puzzle, Trash } from 'react-bootstrap-icons'
import { AppDetailState } from '../../state/apps'
import Button from '../../components/Button'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import SecretModal from './SecretModal'
import SecretCard from './SecretCard'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import AppModal from './AppModal'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import Layout from '../../components/Layout'
import Detail from '../../components/Detail'

export default function AppDetail() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const [{ app, error }, { generateSecret, deleteSecret, updateSecret, deleteApp, updateApp }] = useRunRj(
    AppDetailState,
    [id]
  )

  const [addSecretModal, addSecretModalActions] = useModalTrigger()
  const [rmSecretModal, rmSecretModalActions] = useModalTrigger()
  const [editSecretModal, editSecretModalActions] = useModalTrigger()
  const [rmAppModal, rmAppModalActions] = useModalTrigger()
  const [editAppModal, editAppModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  if (app === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <Puzzle className="mr-2" size={31} /> {app.name}
          </h2>
          <div>
            <Button
              onClick={() => editAppModalActions.open()}
              className="mr-2"
              size="sm"
              variant="outline-primary"
              icon={<Pencil />}
            >
              {t('edit_app')}
            </Button>
            <Button
              onClick={() => rmAppModalActions.open()}
              size="sm"
              variant="outline-danger"
              icon={<Trash />}
              disabled={app.cloud_licensing_application}
            >
              {t('delete_app')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('application_id')} value={app.id} />
            <Detail.Row label={t('application_name')} value={app.name} />
            <Detail.Row label={t('application_version')} value={app.version} />
            <Detail.Row label={t('application_product_code')} value={app.product_code} />
            <Detail.Row
              label={t('application_home_url')}
              value={
                app.home_url ? (
                  <a rel="noopener noreferrer" href={app.home_url}>
                    {app.home_url}
                  </a>
                ) : null
              }
            />
          </Detail>
          <div className="col-md-4 text-center">
            {app.background_image && (
              <a rel="noopener noreferrer" href={app.background_image} target="_blank">
                <img
                  className="img-thumbnail"
                  style={{ maxWidth: 300 }}
                  src={app.background_image}
                  alt={t('application_background_image')}
                />
              </a>
            )}
          </div>
        </div>
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Key className="mr-2" /> {t('secrets')}
          </h3>
          <div>
            <Button size="sm" onClick={() => addSecretModalActions.open()} variant="outline-primary" icon={<Key />}>
              {t('generate_secret')}
            </Button>
          </div>
        </div>
        <div className="mt-4 px-3">
          {app.secrets.map((secret) => (
            <SecretCard
              onEdit={editSecretModalActions.open}
              onDelete={rmSecretModalActions.open}
              key={secret.id}
              secret={secret}
            />
          ))}
        </div>
      </div>
      <SecretModal
        onSave={(secret) =>
          generateSecret.onSuccess(addSecretModalActions.close).asPromise({
            ...secret,
            application: app.id,
          })
        }
        isOpen={addSecretModal.isOpen}
        toggle={addSecretModalActions.toggle}
        onExited={addSecretModalActions.onClosed}
      />
      <SecretModal
        onSave={(secret) => updateSecret.onSuccess(editSecretModalActions.close).asPromise(secret)}
        secret={editSecretModal.value}
        isOpen={editSecretModal.isOpen}
        toggle={editSecretModalActions.toggle}
        onExited={editSecretModalActions.onClosed}
      />
      <ModalConfirmDelete
        isOpen={rmSecretModal.isOpen}
        toggle={rmSecretModalActions.toggle}
        title={t('remove_secret_title', { name: rmSecretModal.value?.name })}
        text={t('remove_secret_text', { name: rmSecretModal.value?.name })}
        onExited={rmSecretModalActions.onClosed}
        onDelete={() => {
          deleteSecret(rmSecretModal.value)
          rmSecretModalActions.close()
        }}
      />
      <ModalConfirmDelete
        isOpen={rmAppModal.isOpen}
        toggle={rmAppModalActions.toggle}
        title={t('delete_app_title', { name: app.name })}
        text={t('delete_app_text', { name: app.name })}
        onExited={rmAppModalActions.onClosed}
        onDelete={() => {
          deleteApp.onSuccess(() => history.push('/apps')).run(app)
        }}
      />
      <AppModal
        onSave={(app) => updateApp.onSuccess(editAppModalActions.close).asPromise(app)}
        app={app}
        isOpen={editAppModal.isOpen}
        toggle={editAppModalActions.toggle}
        onExited={editAppModalActions.onClosed}
      />
    </Layout>
  )
}
