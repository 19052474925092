import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import apiUvicorn from '../apiUvicorn'
import { PAGE_SIZE } from '../consts'

export const OrgsState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .computed({
    orgs: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
  })
  .effect({
    name: 'OrgsState',
    effectCaller: 'configured',
    effect: (token) => (params) =>
      apiUvicorn.auth(token).get(`/customer`, params),
  })

export const OrgDetailUvicornState = rj()
  .computed({
    org: 'getData',
    error: 'getError',
  })
  .effect({
    name: 'OrgDetailUvicornState',
    effectCaller: 'configured',
    effect: (token) => (id) => apiUvicorn.auth(token).get(`/customer/${id}`),
  })
