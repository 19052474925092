import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import {
  Cart,
  Check2,
  ChevronRight,
  Command,
  FiletypeExe,
  FiletypeYml,
  Git,
  Pencil,
  PlusCircleDotted,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { VersionReleaseModal } from '../../components/VersionReleaseModal'
import { useReleaseFiles } from '../../hooks/useReleaseFiles'
import { useVersionRelease } from '../../hooks/useVersionReleases'
import { ReleaseFileUploadModal } from '../../components/ReleaseFileUploadModal'
import { useChangelogs } from '../../hooks/useChangelogs'
import { ChangelogModal } from '../../components/ChangelogModal'
import dayjs from 'dayjs'

export function ReleaseDetail() {
  const { id: productId, version: versionId, release: id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const filesFilter = useMemo(
    () => ({
      release: id,
    }),
    [id]
  )

  const [{ release, error }, { update, destroy }] = useVersionRelease(id)
  const [{ files, error: filesError }, { run: reloadFiles, bulkUpload, destroy: destroyFile }] =
    useReleaseFiles(filesFilter)
  const [
    { changelogs, error: changelogsError },
    { run: reloadChangelogs, create: createChangelog, update: updateChangelog, destroy: destroyChangelog },
  ] = useChangelogs(filesFilter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [createFileModalState, createFileModalActions] = useModalTrigger()
  const [deleteFileModalState, deleteFileModalActions] = useModalTrigger()
  const [changelogModalState, changelogModalActions] = useModalTrigger()
  const [deleteChangelogModalState, deleteChangelogModalActions] = useModalTrigger()

  if (error || filesError || changelogsError) {
    return <PageError error={error || filesError || changelogsError} />
  }

  if (release === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <h2 className="m-0">
              <Link className="d-flex aling-items-center" to={`/catalog/${productId}`}>
                <Cart className="mr-2" size={31} />
                {release.product_name}
              </Link>
            </h2>
            <ChevronRight className="ml-3" size={20} />
            <Link className="d-flex aling-items-center" to={`/catalog/${productId}/versions/${release.version}`}>
              <h2 className="m-0 ml-3 d-flex align-items-center">
                <Git size={31} className="mr-2" />
                {release.version_label}
              </h2>
            </Link>
            <ChevronRight className="ml-3" size={20} />
            <h2 className="m-0 ml-3 d-flex align-items-center">
              <Command size={31} className="mr-2" />
              {release.label}
            </h2>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(release)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_product_release')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => delModalActions.open(release)}
              icon={<Trash size={15} />}
            >
              {t('delete_product_release')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={release.id} />
            <Detail.Row label={t('product_release_label')} value={release.label} />
            <Detail.Row label={t('product_release_nr')} value={release.release_nr} />
            <Detail.Row label={t('product_release_visible')} value={release.visible ? <Check2 /> : (release.release_date ? t("release_yanked") : null)} />
            <Detail.Row label={t('product_release_date')} value={release.visible ? dayjs(release.release_date).format("DD/MM/YYYY") : null} />
          </Detail>
        </div>

        {/* RELEASE FILES */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <FiletypeExe className="mr-2" /> {t('release_files')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createFileModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_release_file')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '70%' }}>{t('release_file_name')}</th>
                <th style={{ width: '30%' }}></th>
              </tr>
            </thead>
            <tbody>
              {files &&
                files.map((file) => (
                  <tr key={file.id}>
                    <td>
                      <a href={file.file} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => deleteFileModalActions.open(file)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete_release_file')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {/* END RELEASE FILES */}

        {/* CHANGELOGS */}
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <FiletypeYml className="mr-2" /> {t('changelogs')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => changelogModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_changelog')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '50%' }}>{t('changelog_lang')}</th>
                <th style={{ width: '50%' }}></th>
              </tr>
            </thead>
            <tbody>
              {changelogs &&
                changelogs.map((chg) => (
                  <tr key={chg.id}>
                    <td>
                      <a href={chg.file} target="_blank" rel="noopener noreferrer">
                        {chg.lang}
                      </a>
                    </td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => changelogModalActions.open(chg)}
                        icon={<Trash size={15} />}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => deleteChangelogModalActions.open(chg)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {/* END CHANGELOGS */}
      </div>
      <ModalConfirmDelete
        onDelete={() => {
          destroy
            .onSuccess(() => {
              delModalActions.close()
              history.replace(`/catalog/${id}/versions/${versionId}`)
            })
            .run(release.id)
        }}
        isOpen={delModalState.isOpen}
        toggle={delModalActions.toggle}
        onExited={delModalActions.onClosed}
        title={t('delete_version_release_title', {
          name: release.label,
        })}
        text={t('delete_version_release_text', {
          name: release.label,
        })}
      />
      {deleteFileModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            destroyFile
              .onSuccess(() => {
                deleteFileModalActions.close()
                reloadFiles(filesFilter)
              })
              .run(deleteFileModalState.value.id)
          }}
          isOpen={deleteFileModalState.isOpen}
          toggle={deleteFileModalActions.toggle}
          onExited={deleteFileModalActions.onClosed}
          title={t('delete_release_file_title', {
            name: deleteFileModalState.value.name,
          })}
          text={t('delete_release_file_text', {
            name: deleteFileModalState.value.name,
          })}
        />
      )}
      {deleteChangelogModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            destroyChangelog
              .onSuccess(() => {
                deleteChangelogModalActions.close()
                reloadChangelogs(filesFilter)
              })
              .run(deleteChangelogModalState.value.id)
          }}
          isOpen={deleteChangelogModalState.isOpen}
          toggle={deleteChangelogModalActions.toggle}
          onExited={deleteChangelogModalActions.onClosed}
          title={t('delete_changelog_title', {
            lang: deleteChangelogModalState.value.lang,
          })}
          text={t('delete_changelog_text', {
            lang: deleteChangelogModalState.value.lang,
          })}
        />
      )}
      {editModalState.value && (
        <VersionReleaseModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          release={release}
          onClosed={editModalActions.onClosed}
          onSave={(result) => {
            return update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(release.id, result)
          }}
        />
      )}
      {createFileModalState.value && (
        <ReleaseFileUploadModal
          isOpen={createFileModalState.isOpen}
          toggle={createFileModalActions.toggle}
          onClosed={createFileModalActions.onClosed}
          onSave={(result) => {
            const fd = new FormData()
            for (const [i, file] of result.entries()) {
              fd.append(`files[${i}]release`, release.id)
              fd.append(`files[${i}]file`, file.file)
              fd.append(`files[${i}]name`, file.name)
            }
            return bulkUpload
              .onSuccess(() => {
                createFileModalActions.close()
                reloadFiles(filesFilter)
              })
              .asPromise(fd)
          }}
        />
      )}
      {changelogModalState.value && (
        <ChangelogModal
          isOpen={changelogModalState.isOpen}
          toggle={changelogModalActions.toggle}
          onClosed={changelogModalActions.onClosed}
          changelog={changelogModalState.value}
          onSave={(result) => {
            const { id, ...data } = result
            data.release = release.id
            if (id) {
              return updateChangelog
                .onSuccess(() => {
                  changelogModalActions.close()
                  reloadChangelogs()
                })
                .asPromise(id, data)
            } else {
              return createChangelog
                .onSuccess(() => {
                  changelogModalActions.close()
                  reloadChangelogs()
                })
                .asPromise(data)
            }
          }}
        />
      )}
    </Layout>
  )
}
