import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ArrowLeft,
  CheckCircle,
  PlusCircleDotted,
  Sliders
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { ConfigurationOptionTypeaheadField } from '../ConfigurationOptionTypeahead'
import { InputField, TextAreaField, transformErrorForForm } from '../Form'

const INITIAL_VALUES = {
  configuration: undefined,
  option: undefined,
  bit: '',
  notes: '',
}

export function ConfigurationOptionModal({
  onSave,
  isOpen,
  toggle,
  onClosed,
  option = INITIAL_VALUES,
  action = 'edit',
}) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        configuration: Yup.number().required(
          t('form_required', {
            field: t('configuration'),
          })
        ),
        option: Yup.number().required(
          t('form_required', {
            field: t('option'),
          })
        ),
        bit: Yup.number().notRequired(),
        notes: Yup.string().notRequired(),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={option}
        onSubmit={(values, actions) =>
          onSave(values).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
            actions.setSubmitting(false)
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Sliders className="mr-2" />
                  {t('edit_configuration_option')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field
                  required
                  label={t('configuration')}
                  name="configuration_name"
                  component={InputField}
                  disabled
                />
                <Field
                  required
                  label={t('option')}
                  name="option"
                  component={ConfigurationOptionTypeaheadField}
                />
                <Field
                  required
                  label={t('bit')}
                  name="bit"
                  type="number"
                  step={1}
                  component={InputField}
                />
                <Field
                  label={t('notes')}
                  name="notes"
                  component={TextAreaField}
                  rows={4}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  icon={<ArrowLeft />}
                  onClick={toggle}
                  variant="secondary"
                  disabled={isSubmitting}
                  type="button"
                >
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button
                    icon={<PlusCircleDotted size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button
                    icon={<CheckCircle size={18} />}
                    variant="success"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
