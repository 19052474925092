import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const ProductVersionListRj = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/releases/version/', params)
  },
  mutations: {
    create: rj.mutation.single({
      effect: (token) => (data) => api2.auth(token).post('/releases/version', data),
      updater: (state) => state,
    }),
  },
  computed: {
    versions: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProductVersions(params) {
  return useRunRj(ProductVersionListRj, [params], false)
}

function useProductVersionsControlled() {
  return useRj(ProductVersionListRj)
}

useProductVersions.controlled = useProductVersionsControlled

const ProductVersionRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/releases/version/${id}`)
  },
  mutations: {
    copyProductFiles: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).post(`/releases/version/${id}/copy-product-files/`),
      updater: (state) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => api2.auth(token).patch(`/releases/version/${id}`, data),
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => api2.auth(token).delete(`/releases/version/${id}`),
      updater: (state) => state,
    }),
  },
  computed: {
    version: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useProductVersion(id) {
  return useRunRj(ProductVersionRj, [id], true)
}

function useProductVersionControlled() {
  return useRj(ProductVersionRj)
}

useProductVersion.controlled = useProductVersionControlled
