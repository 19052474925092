import { useCallback } from 'react'
import { rj, useRj } from 'react-rocketjump'
import { useAuthUser } from 'use-eazy-auth'
import api from '../api'

export const UpdateUserLangState = rj({
  name: 'UpdateUserLang',
  effectCaller: 'configured',
  takeEffect: 'concatLatest',
  effect: (t) => (language) =>
    api
      .mapResponse(() => null)
      .auth(t)
      .patch(`/admin/me`, {
        language,
      }),
})

export function useUpdateUserLang() {
  const { updateUser } = useAuthUser()
  const [, { run }] = useRj(UpdateUserLangState)
  return useCallback(
    (language) => {
      run.onSuccess(updateUser).run(language)
    },
    [run, updateUser]
  )
}
