import React, { useCallback } from 'react'
import { BoxSeam } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { useLicense } from '../../hooks/useLicenses'
import { Attributes } from './Attributes'
import { Configurations } from './Configurations'
import { Info } from './Info'
import { Keys } from './Keys'
import { Options } from './Options'

export function LicenseConfiguration() {
  const { id } = useParams()
  const { t } = useTranslation()

  const [
    { license, error: licenseError },
    {
      grantOption,
      editOption,
      revokeOption,
      update,
      revoke,
      grantConfigurationOption,
      editConfigurationOption,
      revokeConfigurationOption,
      grantConfiguration,
      revokeConfiguration,
      assignToKey,
      editAssignation,
      removeFromKey,
    },
  ] = useLicense(id)

  const updateAttribute = useCallback(
    (code, value) => {
      return update.asPromise(license?.id, {
        attributes: { ...license?.attributes, [code]: value },
      })
    },
    [license?.attributes, license?.id, update]
  )

  const error = licenseError

  if (error) {
    return <PageError error={error} />
  }

  if (license === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex align-items-center">
            <BoxSeam className="mr-2" size={31} /> {t('license')}
          </h2>
        </div>

        <Info license={license} editLicense={update} revokeLicense={revoke} />

        <Keys
          license={license}
          assignToKey={assignToKey}
          editAssignation={editAssignation}
          removeFromKey={removeFromKey}
        />

        <Options
          license={license}
          grantOption={grantOption}
          editOption={editOption}
          revokeOption={revokeOption}
        />

        <Attributes license={license} updateAttribute={updateAttribute} />

        {!license?.product_is_external && (
          <Configurations
            license={license}
            grantOption={grantConfigurationOption}
            editOption={editConfigurationOption}
            revokeOption={revokeConfigurationOption}
            grant={grantConfiguration}
            revoke={revokeConfiguration}
          />
        )}
      </div>
    </Layout>
  )
}
