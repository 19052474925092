import { rj, useRunRj } from 'react-rocketjump'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { api2 } from '../api'

const AssistanceContractListRj = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    effectCaller: 'configured',
    effect: (token) => (params) => {
      return api2.auth(token).get('/license/assistance-contract/', params)
    },
    mutations: {
      create: rj.mutation.single({
        effect: (token) => (data) => api2.auth(token).post('/license/assistance-contract/', data),
        updater: (state) => state,
      }),
      remove: rj.mutation.single({
        effect: (token) => (id) => api2.auth(token).delete(`/license/assistance-contract/${id}/`),
        updater: (state) => state,
      }),
    },
    computed: {
      contracts: 'getList',
      loading: 'isPending',
      error: 'getError',
      pagination: 'getPagination',
    },
  }
)

export function useAssistanceContracts(params) {
  return useRunRj(AssistanceContractListRj, [params], false)
}

const AssistanceContractRj = rj({
  effectCaller: 'configured',
  effect: (token) => (id) => {
    return api2.auth(token).get(`/license/assistance-contract/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (licenseId, data) => api2.auth(token).patch(`/license/assistance-contract/${licenseId}`, data),
      updater: (state, result) => {
        return { ...state, data: { ...state.data, ...result } }
      },
    }),
    remove: rj.mutation.single({
      effect: (token) => (licenseId) => api2.auth(token).delete(`/license/assistance-contract/${licenseId}`),
      updater: (state) => state,
    }),
  },
  computed: {
    contract: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

export function useAssistanceContract(id) {
  return useRunRj(AssistanceContractRj, [id], true)
}
