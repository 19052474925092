import { rj, useRj, useRunRj } from 'react-rocketjump'
import { api2 } from '../api'

const OwnedHardwareKeys = rj({
  effectCaller: 'configured',
  effect: (token) => (params) => {
    return api2.auth(token).get('/license/hw-key-owner/', params)
  },
  computed: {
    keys: 'getData',
    loading: 'isPending',
    error: 'getError',
  },
})

function useHardwareKeysOwned_controlled() {
  return useRj(OwnedHardwareKeys)
}

export function useHardwareKeysOwned(filters) {
  return useRunRj(OwnedHardwareKeys, [filters], false)
}

useHardwareKeysOwned.controlled = useHardwareKeysOwned_controlled
