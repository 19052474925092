import dayjs from 'dayjs'
import { map, sum } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { BoxArrowUpRight, ExclamationTriangle, Eye, Pencil } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import { EditInfoModal } from './EditInfoModal'
import { OemHistoryModal } from './OemHistoryModal'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

export function Info({ license, editLicense, revokeLicense }) {
  const { t } = useTranslation()
  const history = useHistory()

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [oemHistoryModalState, oemHistoryModalActions] = useModalTrigger()

  let license_type = t('dlic_license_perpetual')
  if (license.license_type === 'expire') {
    license_type = t('dlic_license_expiring') + ' (' + dayjs(license.expire_date).format('DD/MM/YYYY') + ')'
  }

  return (
    <div className="mt-3 px-3">
      <div className="text-right mb-3">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => history.push(`/licenseconfig/${license.id}/history`)}
          icon={<Pencil size={15} />}
          className="mr-2"
        >
          {t('browse_license_history')}
        </Button>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => editModalActions.open(license)}
          icon={<Pencil size={15} />}
          className="mr-2"
        >
          {t('edit_license_info')}
        </Button>
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => delModalActions.open(license)}
          icon={<Pencil size={15} />}
          className="mr-2"
        >
          {t('delete')}
        </Button>
      </div>
      <Detail className="col-md-8">
        <Detail.Row label={t('id')} value={license.id} />
        <Detail.Row
          label={t('customer')}
          value={<Link to={`/orgs/${license.customer}/dlic-licenses`}>{license.customer_name}</Link>}
        />
        <Detail.Row label={t('product')} value={license.product_name} />
        <Detail.Row label={t('product_level')} value={license.product_level_name} />
        <Detail.Row
          label={t('dlic_license_version_min')}
          value={license.min_version}
          style={{ display: license.product_is_external ? 'none' : undefined }}
        />
        <Detail.Row
          label={t('dlic_license_version_max')}
          value={license.max_version ?? <ExclamationTriangle className="ml-1" />}
          style={{ display: license.product_is_external ? 'none' : undefined }}
        />
        <Detail.Row
          label={t('dlic_license_quantity')}
          value={
            license.quantity +
            ' (' +
            t('in_use', {
              amount: sum(map(license.assignations, 'concurrency')),
            }) +
            ')'
          }
        />
        <Detail.Row label={t('dlic_license_type')} value={license_type} />
        <Detail.Row
          label={t('dlic_license_oem')}
          value={
            <div>
              <span>{license.oem_name ?? <i>{t('no_oem')}</i>}</span>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => oemHistoryModalActions.open(license.id)}
                icon={<Eye size={15} />}
                className="ml-2"
              >
                {t('history')}
              </Button>
            </div>
          }
        />
        <Detail.Row
          label={t('dlic_license_activation_date')}
          value={license.activation_date ? dayjs(license.activation_date).format('DD/MM/YYYY') : null}
        />
        <Detail.Row label={t('dlic_license_notes')} value={license.notes} />
        <Detail.Row
          label={t('dlic_license_convert_from')}
          value={
            license.convert_from ? <Link to={`/licenses/${license.convert_from}`}>{license.convert_from}</Link> : ''
          }
        />
      </Detail>
      <div className="mt-3" />
      <Detail className="col-md-8">
        <Detail.Row
          label={t('licence_update_contract_expiration_date')}
          value={
            license.can_update_until !== null ? (
              dayjs(license.can_update_until).format('DD/MM/YYYY')
            ) : (
              <ExclamationTriangle className="ml-1" />
            )
          }
        />
        <Detail.Row
          label={t('licence_assistance_contract_expiration_date')}
          value={
            license.assistance_contract_expiration !== null && (
              <div className="d-flex flex-row align-items-center">
                <span>{dayjs(license.assistance_contract_expiration).format('DD/MM/YYYY')}</span>
                <Link to={`/assistance-contract/${license.assistance_contract_id}`}>
                  <BoxArrowUpRight className="ml-1" />
                </Link>
              </div>
            )
          }
        />
      </Detail>
      {editModalState.value && (
        <EditInfoModal
          license={editModalState.value}
          isOpen={editModalState.isOpen}
          onCancel={editModalActions.close}
          onSubmit={(values) => {
            return editLicense.onSuccess(() => editModalActions.close()).asPromise(license.id, values)
          }}
        />
      )}
      {oemHistoryModalState.value && (
        <OemHistoryModal
          licenseId={oemHistoryModalState.value}
          isOpen={oemHistoryModalState.isOpen}
          onClose={oemHistoryModalActions.close}
          onClosed={oemHistoryModalActions.onClosed}
        />
      )}
      {delModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            revokeLicense
              .onSuccess(() => {
                delModalActions.close()
                history.goBack()
              })
              .run(delModalState.value.id)
          }}
          isOpen={delModalState.isOpen}
          toggle={delModalActions.toggle}
          onExited={delModalActions.onClosed}
          title={t('delete_dlic_license_title', {
            id: delModalState.value.id,
          })}
          text={t('delete_dlic_license_text', {
            id: delModalState.value.id,
          })}
        />
      )}
    </div>
  )
}
