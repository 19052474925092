import { rj, useRj, useRunRj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import api from '../api'
import apiUvicorn from '../apiUvicorn'
import { PAGE_SIZE } from '../consts'

const OrgsState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    }),
    rjDebounce(200)
  )
  .computed({
    orgs: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
  })
  .effect({
    name: 'OrgsState',
    effectCaller: 'configured',
    effect: (token) => (params) => apiUvicorn.auth(token).get(`/customer`, params),
  })

const OrgDetailUvicornState = rj()
  .computed({
    org: 'getData',
    error: 'getError',
  })
  .effect({
    name: 'OrgDetailUvicornState',
    effectCaller: 'configured',
    effect: (token) => (id) => apiUvicorn.auth(token).get(`/customer/${id}`),
  })

export function useOrganizations(filters) {
  return useRunRj(OrgsState, [filters], true)
}

function useOrganizationsControlled() {
  return useRj(OrgsState)
}

useOrganizations.controlled = useOrganizationsControlled

export function useOrganization(id) {
  return useRunRj(OrgDetailUvicornState, [id], true)
}

function useOrganizationControlled() {
  return useRj(OrgDetailUvicornState)
}

useOrganization.controlled = useOrganizationControlled

const MergeOrganizationsRj = rj({
  effectCaller: 'configured',
  effect: (token) => (orgId, mergeIntoId) => {
    return api.auth(token).post(`/admin/organization/${orgId}/merge-into/`, { merge_into: mergeIntoId })
  },
})

export function useOrganizationMerge() {
  return useRj(MergeOrganizationsRj)
}
