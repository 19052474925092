import { useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useRj } from 'react-rocketjump'
import { AppsListState } from '../../state/apps'
import 'react-bootstrap-typeahead/css/Typeahead.css'

export default function AppTypeahead({
  value,
  name = '',
  id = 'app-typeahead',
  className,
  onChange,
  ...props
}) {
  const [{ apps, loading }, { run }] = useRj(AppsListState)
  const selected = value ? [value] : []

  useEffect(() => run(), [run])

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run.withMeta({ debounced: true }).run({ search })
      }}
      isLoading={loading}
      options={apps ?? []}
      labelKey={(app) => `${app.name} ${app.version}`}
      minLength={0}
      onSearch={(search) => run({ search })}
      id={id}
      {...props}
    />
  )
}
