import dayjs from 'dayjs'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { useLicenseOemHistory } from '../../hooks/useLicenses'

export function OemHistoryModal({ isOpen, onClose, onClosed, licenseId }) {
  const { t } = useTranslation()
  const [{ data }] = useLicenseOemHistory(licenseId)

  return (
    <Modal show={isOpen} onExited={onClosed}>
      <Modal.Header>{t('oem_history')}</Modal.Header>
      <Modal.Body>
        {!data && <p>{t('loading_historical_data')}</p>}
        {data && (
          <>
            {data.map((log) => (
              <div key={log.id}>
                {dayjs(log.created_at).format('DD/MM/YYYY')}{' '}
                {log.organization_name ?? <i>{t('no_oem')}</i>}
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button icon={<Check />} variant="primary" onClick={onClose}>
          {t('ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
