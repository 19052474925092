import { rj } from 'react-rocketjump'
import apiUvicorn from '../apiUvicorn'

export const AssistanceContractState = rj()
  .computed({
    contracts: 'getData',
    loading: 'isLoading',
  })
  .effect({
    name: 'AssistanceContractState',
    effectCaller: 'configured',
    effect: (token) => (params) => apiUvicorn.auth(token).get(`/assistance_contract`, params),
  })

