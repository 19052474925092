import itFlag from './assets/it.svg'
import enFLag from './assets/en.svg'
import deFlag from './assets/de.svg'
import frFlag from './assets/fr.svg'
import esFlag from './assets/es.svg'
import plFlag from './assets/pl.svg'
import zhFlag from './assets/zh.svg'

export const PAGE_SIZE = 100

export const LANGUAGES = {
  it: {
    label: 'italiano',
  },
  en: {
    label: 'english',
  },
  de: {
    label: 'Deutsch',
  },
  fr: {
    label: 'français',
  },
  es: {
    label: 'español',
  },
  pl: {
    label: 'polski',
  },
  // NOTE: zh-hans has troubles with supported langs....
  zh: {
    label: '简体中文',
  }
}

export const ICONS_FLAG = {
  it: itFlag,
  en: enFLag,
  de: deFlag,
  fr: frFlag,
  es: esFlag,
  pl: plFlag,
  zh: zhFlag,
}

export const LANGUAGE_CODES = Object.keys(LANGUAGES)

export const API_URL_UVICORN = "/uvi"