import { rj } from 'react-rocketjump'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const UsersListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    deleteUser: {
      effect: (t) => (user) =>
        api
          .auth(t)
          .mapResponse(() => user)
          .delete(`/admin/user/${user.id}`),
      updater: 'deleteItem',
    },
    createUser: {
      effect: (t) => (user) => api.auth(t).post('/admin/user/', user),
      updater: (s) => s,
    },
    updateUser: {
      effect: (t) => (user) => api.auth(t).put(`/admin/user/${user.id}`, user),
      updater: 'updateItem',
    },
    resendInvitation: {
      effect: (t) => (user) => api.auth(t).post(`/admin/user/${user.id}/resend-activation-email/`),
      updater: (state) => state,
    },
  })
  .computed({
    pagination: 'getPagination',
    users: 'getList',
    error: 'getError',
    loading: 'isLoading',
  })
  .effect({
    name: 'UsersList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/admin/user', params),
  })

export const UserDetailState = rj()
  .mutations({
    deleteUser: {
      effect: (t) => (user) =>
        api
          .auth(t)
          .mapResponse(() => user)
          .delete(`/admin/user/${user.id}`),
      updater: (s) => s,
    },
    clearUserTokens: {
      effect: (t) => (user) =>
        api.auth(t).post(`/admin/clear-tokens/`, {
          user: user.id,
        }),
      updater: (s) => s,
    },
    updateUser: {
      effect: (t) => (user) => api.auth(t).put(`/admin/user/${user.id}`, user),
      updater: 'updateData',
    },
    resendInvitation: {
      effect: (t) => (user) => api.auth(t).post(`/admin/user/${user.id}/resend-activation-email/`),
      updater: (state) => state,
    },
  })
  .computed({
    user: 'getData',
    error: 'getError',
  })
  .effect({
    name: 'UserDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/admin/user/${id}`),
  })
