import { Nav, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useUpdateUserLang } from '../../state/lang'
import { LANGUAGES, LANGUAGE_CODES, ICONS_FLAG } from '../../consts'

export default function NavLangSwitcher() {
  const { i18n } = useTranslation()
  const updateUserLang = useUpdateUserLang()

  function handleLangChange(code) {
    i18n.changeLanguage(code)
    updateUserLang(code)
  }

  const selectedLang = LANGUAGES[i18n.language]

  return (
    <Nav>
      <NavDropdown
        alignRight
        title={
          selectedLang ? (
            <span>
              <img
                className="mr-2"
                height={12}
                alt={`flag ${i18n.language}`}
                src={ICONS_FLAG[i18n.language]}
              />
              {selectedLang.label}
            </span>
          ) : null
        }
        id="collasible-nav-dropdown"
      >
        {LANGUAGE_CODES.map((langCode) => (
          <NavDropdown.Item
            onClick={() => handleLangChange(langCode)}
            active={langCode === i18n.language}
            key={langCode}
          >
            <img
              className="mr-2"
              height={10}
              alt={`flag ${langCode}`}
              src={ICONS_FLAG[langCode]}
            />
            {LANGUAGES[langCode].label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  )
}
