import useQueryParam from 'magik-react-hooks/useRouterQueryParam'
import { useState } from 'react'
import { useRj } from 'react-rocketjump'
import { useTranslation } from 'react-i18next'
import { RecoverState } from '../../state/password'
import ddxLogo from '../../assets/ddx_logo.png'

export default function PasswordRecover() {
  const { t } = useTranslation()
  const [{ data, pending, error }, { run: recover }] = useRj(RecoverState)
  const [email, setEmail] = useState('')
  const [redirectUrl] = useQueryParam('redirect_url')

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (email !== '') {
          recover({ email, redirect_url: redirectUrl })
        }
      }}
      className="row mt-5"
    >
      <div className="col-md-4 offset-md-4">
        <div className="card">
          <div className="card-header">
            <h1 className="land">
              <img src={ddxLogo} alt="ddx logo" height={25} />
              <span className="ml-2">XAuth</span>
            </h1>
          </div>
          {data && (
            <div className="card-body">
              <div className="alert alert-success">
                {t('recover_success')}
              </div>
            </div>
          )}
          {!data && (
            <div className="mt-2 card-body">
              <p>{t('password_recover')}</p>
              <div className="form-group">
                <input
                  name="email"
                  placeholder={t('user_email')}
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pending}
              >
                {t('recover')}
              </button>
              {error && (
                <div className="alert alert-danger mt-3">
                  {t('recover_error')}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
