import { Field, Formik } from 'formik'
import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ArrowLeft, Check } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { DateField } from '../../components/Form'

/**
 * @param {{
 *    isOpen: bool,
 *    onSubmit: (arg: any) => Promise,
 *    onCancel: () => void,
 *    onClosed: () => void,
 * }} props
 * @returns
 */
export function CreateAssistanceContractModal({ isOpen, onSubmit, onCancel, onClosed }) {
  const { t } = useTranslation()

  return (
    <Formik initialValues={{ from_date: '', to_date: '' }} onSubmit={(values, actions) => onSubmit(values)}>
      {(formik) => (
        <Modal show={isOpen} onExited={onClosed}>
          <Modal.Header>{t('create_assistance_contract')}</Modal.Header>
          <Modal.Body>
            <Form>
              <Field name="from_date" component={DateField} label={t('from_date')} required />
              <Field name="to_date" component={DateField} label={t('to_date')} required />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              icon={<ArrowLeft />}
              onClick={onCancel}
              variant="secondary"
              disabled={formik.isSubmitting}
              type="button"
            >
              {t('undo')}
            </Button>
            <Button
              icon={<Check />}
              variant="success"
              disabled={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}
