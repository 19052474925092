import { cloneElement } from 'react'
import { Button as BsButton } from 'react-bootstrap'

export default function Button({
  icon = null,
  iconPostion = 'left', // left | right
  className = '',
  children,
  style,
  ...props
}) {
  const btnClassName = icon
    ? `${className} d-inline-flex align-items-center`
    : className

  return (
    <BsButton
      {...props}
      className={btnClassName}
      style={{ ...style, height: style?.height ?? 28.375 }}
    >
      {iconPostion === 'left' &&
        icon &&
        cloneElement(icon, { className: children ? 'mr-1' : '' })}
      {children}
      {iconPostion === 'right' &&
        icon &&
        cloneElement(icon, { className: children ? 'ml-1' : '' })}
    </BsButton>
  )
}
