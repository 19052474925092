import * as dayjs from 'dayjs'
import parse from 'html-react-parser'
import { Table } from 'react-bootstrap'
import { ShieldPlus } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function KeysHwListOrg({ loading, keys, showCustomer, showOEM, onDlicCompute }) {
  const { t } = useTranslation()

  let notesWidth = 55
  if (showCustomer) {
    notesWidth -= 20
  }
  if (showOEM) {
    notesWidth -= 20
  }

  return (
    <Table loading={loading} responsive hover>
      <thead>
        <tr>
          <th className="ddw-w-10">{t('number')}</th>
          {showCustomer && <th className="ddx-w-20">{t('customer')}</th>}
          {showOEM && <th className="ddx-w-20">{t('oem')}</th>}
          <th className="ddx-w-5 text-center">
            <span title={t('needs_dlic_generation')}>{t('dlic')}</span>
          </th>
          <th className="ddx-w-10">{t('activation_date')}</th>
          <th className="ddx-w-10">{t('serial_number')}</th>
          <th className={`ddx-w-${notesWidth}`}>{t('notes')}</th>
          <th className="ddx-w-10">{t('type')}</th>
        </tr>
      </thead>
      <tbody>
        {keys &&
          keys.map((key) => (
            <tr key={key.number}>
              <td>
                <Link className="font-weight-bold" to={`/hw-keys/${key.number}`}>
                  {key.number}
                </Link>
              </td>
              {showCustomer && (
                <td>
                  <Link to={`/orgs/${key.customer}`}>{key.customer_name} </Link>
                </td>
              )}
              {showOEM && (
                <td>
                  <Link to={`/orgs/${key.oem}`}>{key.oem_name} </Link>
                </td>
              )}
              <td className="text-center">
                {key.needs_dlic ? (
                  <span onClick={() => onDlicCompute(key.number)} className="pointer">
                    <ShieldPlus size={18} />
                  </span>
                ) : null}
              </td>
              <td>{dayjs(key.activation_date).format('DD/MM/YYYY')}</td>
              <td>{key.serial_number}</td>
              <td width={'20%'}>{key.notes ? parse(key.notes) : ''}</td>
              <td>{key.type}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
