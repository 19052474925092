import { Form } from 'react-bootstrap'
import { Box, Check, X } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { parseISO, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import AppTypeahead from '../AppTypeahead'
import Table from '../Table'
import Paginator from '../Paginator'

export default function LicensesList({
  loading,
  licenses,
  pagination,
  currentPage,
  goToPage,
  filters,
  onFiltersChange,
  showOrg = false,
  showUser = false,
}) {
  const { t } = useTranslation()
  return (
    <div className="mt-3">
      <div className="mb-2 d-flex justify-content-start">
        <Form.Group controlId="licenseValidFilter">
          <Form.Label>{t('show_license')}</Form.Label>
          <Form.Control
            as="select"
            custom
            value={filters.valid}
            onChange={(e) => {
              onFiltersChange({ valid: e.target.value })
            }}
          >
            <option value="">{t('show_license_all')}</option>
            <option value="true">{t('show_license_valid')}</option>
            <option value="false">{t('show_license_invalid')}</option>
          </Form.Control>
        </Form.Group>
        <div
          className="form-group ml-5"
          style={{
            // TODO: Workaround for bad loading state on typeahed
            // maybe try 2 find better approach...
            width: 200,
          }}
        >
          <label>{t('application')}</label>
          <AppTypeahead
            placeholder={t('search_for_application')}
            value={filters.application}
            onChange={(application) => {
              onFiltersChange({ application })
            }}
          />
        </div>
      </div>
      <Table responsive loading={loading}>
        <thead>
          <tr>
            <th></th>
            {showOrg && <th>{t('organization')}</th>}
            <th>{t('application')}</th>
            <th>{t('application_version')}</th>
            {showUser && <th>{t('user_email')}</th>}
            {showUser && <th>{t('user_name')}</th>}
            <th>{t('license_enabled')}</th>
            <th>{t('license_valid_from')}</th>
            <th>{t('license_valid_to')}</th>
          </tr>
        </thead>
        <tbody>
          {licenses &&
            licenses.map((license) => (
              <tr key={license.id}>
                <td>
                  <Link className="d-flex align-items-center" to={`/pools/${license.pool}`}>
                    <Box className="mr-2" /> {license.pool_label === '' ? t('default_pool_label') : license.pool_label}
                    {license.enabled && license.overlapping_count > 1 && (
                      <div title={t('overlapping_license')} className="license-warning-circle" />
                    )}
                  </Link>
                </td>
                {showOrg && (
                  <td>
                    {license.organization && (
                      <Link to={`/orgs/${license.organization.id}`}>{license.organization.name}</Link>
                    )}
                  </td>
                )}
                <td>
                  <Link to={`/apps/${license.application.id}`}>{license.application.name}</Link>
                </td>
                <td>{license.application.version}</td>
                {showUser && (
                  <td>
                    <Link to={`/users/${license.user_data.id}`}>{license.user_data.email}</Link>
                  </td>
                )}
                {showUser && <td>{license.user_data.full_name}</td>}
                <td>
                  {license.enabled ? (
                    <Check size={25} className="text-success" />
                  ) : (
                    <X size={25} className="text-danger" />
                  )}
                </td>
                <td>{format(parseISO(license.valid_from), 'dd-MM-yyyy')}</td>
                <td>{format(parseISO(license.valid_to), 'dd-MM-yyyy')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Paginator numPages={pagination.numPages} currentPage={currentPage} goToPage={goToPage} />
    </div>
  )
}
