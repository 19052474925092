import { memo } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { PersonFill } from 'react-bootstrap-icons'
import { Link, NavLink } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import { useTranslation } from 'react-i18next'
import ddxLogo from '../../assets/ddx_logo.png'
import NavLangSwitcher from './NavLanSwitcher'
import { useHistory } from 'react-router-dom'

function TopBar() {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  const history = useHistory()

  return (
    <Navbar bg="light" expand="lg" variant="light">
      <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
        <img alt="ddx logo" src={ddxLogo} height={25} />
        <span className="ml-2">XAuth</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {user.is_admin && (
            <>
              <Nav.Link as={NavLink} to="/apps">
                {t('applications')}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/users">
                {t('users')}
              </Nav.Link>
              <NavDropdown alignRight title="Catalogo" id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/catalog">
                  {t('products')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/catalog/options">
                  <span className="pl-2">{t('options')}</span>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/configurations">
                  {t('configurations')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/configurations/options">
                  <span className="pl-2">{t('options')}</span>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/products">
                  {t('products')} (legacy)
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/licenses">
                {t('licenses')}
              </Nav.Link>

              <NavDropdown alignRight title={t('hw_keys')} id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/hw-keys">
                  {t('all_hw_keys')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hw-keys/need-dlic">
                  {t('hw_keys_in_need_of_dlic')}
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
          <Nav.Link as={NavLink} to="/orgs">
            {t('organizations')}
          </Nav.Link>
        </Nav>
        <NavLangSwitcher />
        <Nav>
          <NavDropdown
            alignRight
            title={
              <span>
                <PersonFill className="mr-1" />
                {user.email}
              </span>
            }
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item as={Link} to="/profile">
              {t('profile')}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => {
                history.push('/logout')
              }}
            >
              {t('logout')}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default memo(TopBar)
