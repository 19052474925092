import { Modal } from 'react-bootstrap'
import { ArrowLeft } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import Table from '../Table'

export function BitmaskModal({ isOpen, toggle, onClosed, options }) {
  const { t } = useTranslation()
  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('options')}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
        <Table responsive striped size="sm">
          <thead>
            <tr>
              <th>{t('bit')}</th>
              <th>{t('name')}</th>
            </tr>
          </thead>
          <tbody>
            {options?.map((option) => (
              <tr key={option.bit}>
                <td>{option.bit}</td>
                <td>{option.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" type="button">
          {t('close')}
        </Button>
      </Modal.Footer>

    </Modal>
  )
}
